import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';

// declare const Softdocs: any;
import { AutoclassifierSdkService } from '../../services/autoclassifier-sdk/autoclassifier-sdk.service';

export interface FileUploadStatus {
  file: File;
  status: 'uploading' | 'completed' | 'failed';
  index: number;
}

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {
  @Output() uploadedDocumentIdsEvent = new EventEmitter<string[]>();
  @Output() uploadStatusEvent = new EventEmitter<FileUploadStatus[]>();
  @Input() tenantId: string = '';
  @Input() showNoFilesError = false;

  files: FileUploadStatus[] = [];
  uploadedDocumentIds: string[] = [];
  isProcessing: boolean = false;

  constructor(
    private autoclassifierSdkService: AutoclassifierSdkService,
    private spinner: NgxSpinnerService
  ) {}

  /**
   * Handles file selection from the dropzone by reading the file content and processing the document
   * @param event - The dropzone change event containing the selected files
   */
  onSelect(event: NgxDropzoneChangeEvent): void {
    if (event.addedFiles.length > 0) {
      this.uploadedDocumentIds = [];
      event.addedFiles.forEach((file, index) =>
        this.files.push({
          file: file,
          status: 'uploading',
          index: index + this.files.length
        })
      );
      this.uploadStatusEvent.emit(this.files);
      this.processFiles();
    }
  }

  /**
   * Processes all files by uploading them to the document management system
   */
  private processFiles(): void {
    let processedFilesCount = 0;

    this.files.forEach((fileStatus, index) => {
      if (fileStatus.status === 'completed' || fileStatus.status === 'failed') {
        processedFilesCount++;
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const content = reader.result as ArrayBuffer;
        const blob = new Blob([content], { type: fileStatus.file.type });

        this.spinner.show('uploadSpinner-' + fileStatus.index);

        this.processDocument(
          blob,
          fileStatus.file.name,
          fileStatus.file.type,
          this.tenantId,
          () => {
            this.spinner.hide('uploadSpinner-' + fileStatus.index);
            processedFilesCount++;

            if (processedFilesCount === this.files.length) {
              if (this.uploadedDocumentIds.length > 0) {
                const navigate = () => {
                  this.uploadedDocumentIdsEvent.emit(this.uploadedDocumentIds);
                };

                const hasErrors = this.uploadedDocumentIds.length !== this.files.length;
                if (hasErrors) {
                  setTimeout(navigate, 2000); // Wait 2 seconds before navigating
                } else {
                  navigate();
                }
              } else {
                this.isProcessing = false;
              }
            }
          },
          index
        );
      };
      reader.onerror = error => {
        console.error('FileReader error:', error);
        fileStatus.status = 'failed';
        this.uploadStatusEvent.emit(this.files);
      };
      reader.readAsArrayBuffer(fileStatus.file);
    });
  }

  /**
   * Processes a document by uploading it to the document management system
   * @param content - The content of the document as a BlobPart
   * @param fileName - The name of the file
   * @param contentType - The MIME type of the file
   * @param tenantId - The ID of the tenant to upload the document to
   * @param callback - The callback function to call after processing the document
   * @param fileIndex - The index of the file being processed
   */
  private processDocument(
    content: BlobPart,
    fileName: string,
    contentType: string,
    tenantId: string,
    callback: () => void,
    fileIndex: number
  ): void {
    const document = new File([content], fileName, {
      type: contentType
    });

    this.autoclassifierSdkService.documentManagementSdk
      .uploadDocument(tenantId, document)
      .then((response: { id: string }) => {
        this.uploadedDocumentIds.push(response.id);
        this.files[fileIndex].status = 'completed';
        this.uploadStatusEvent.emit(this.files);
        callback();
      })
      .catch((error: Error) => {
        console.error('Failed to upload document:', error);
        this.files[fileIndex].status = 'failed';
        this.uploadStatusEvent.emit(this.files);
        callback();
      });
  }

  shouldDisableDropzone(): boolean {
    return this.isProcessing || this.files.some(file => file.status === 'uploading');
  }
}
