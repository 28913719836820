import { NgModule, CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, ApplicationRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CurrencyPipe } from '@angular/common';
import { providePrimeNG } from 'primeng/config';
import { MyPreset } from '../themes/default-light/default-light';
import { DragDropModule } from '@angular/cdk/drag-drop';

// PrimeNG modules
import { AutoComplete } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DatePicker } from 'primeng/datepicker';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { FluidModule } from 'primeng/fluid';
import { InputNumber } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { Listbox } from 'primeng/listbox';
import { Menu } from 'primeng/menu';
import { MessageService, ConfirmationService } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSpinnerModule } from 'ngx-spinner';
import { Overlay } from 'primeng/overlay';
import { OverlayBadgeModule } from 'primeng/overlaybadge';
import { OverlayModule } from 'primeng/overlay';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Popover } from 'primeng/popover';
import { RippleModule } from 'primeng/ripple';
import { Select } from 'primeng/select';
import { TableModule } from 'primeng/table';
import { TabsModule } from 'primeng/tabs';
import { TextareaModule } from 'primeng/textarea';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';



// ngx-translate for internationalization
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Frontegg modules
import { FronteggAppModule, FronteggAppService } from '@frontegg/angular';

// Application components
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AddInComponent } from './pages/add-in/add-in.component';
import { PrinterComponent } from './pages/printer/printer.component';
import { HeaderComponent } from './components/header/header.component';
import { FileSelectorComponent } from './components/file-selector/file-selector.component';
import { KeyFieldsComponent } from './components/key-fields/key-fields.component';
import { ClassificationResultsComponent } from './components/classification-results/classification-results.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { WebCaptureComponent } from './pages/web-capture/web-capture.component';
import { BatchCreationComponent } from './components/batch-creation/batch-creation.component';
import { BatchSettingsComponent } from './components/batch-creation/batch-settings/batch-settings.component';
import { BatchListComponent } from './components/batch-list/batch-list.component';
import { GoogleAddonComponent } from './pages/google-addon/google-addon.component';
import { OpenBatchComponent } from './pages/web-capture/open-batch/open-batch.component';
import { WebCaptureUploadComponent } from './pages/web-capture/web-capture-upload/web-capture-upload.component';
import { WebCaptureBatchingComponent } from './pages/web-capture/web-capture-batching/web-capture-batching.component';
import { WebCaptureBatchCreationComponent } from './pages/web-capture/web-capture-batch-creation/web-capture-batch-creation.component';
import { UnauthorizedComponent } from './pages/web-capture/unauthorized/unauthorized.component';
import { MergeDocumentsComponent } from './components/merge-documents/merge-documents.component';


// Services
import { TitleService } from './services/title/title.service';

// HTTP Interceptor
import { AuthInterceptor } from './http-interceptors/auth-interceptor';

// Environment Variables
import { environment } from './environments/environment';

// Function for the TranslateLoader
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const defaultPreset = MyPreset;


@NgModule({
  declarations: [
    AppComponent,
    AddInComponent,
    PrinterComponent,
    HeaderComponent,
    FileSelectorComponent,
    KeyFieldsComponent,
    ClassificationResultsComponent,
    FileUploaderComponent,
    WebCaptureComponent,
    BatchCreationComponent,
    BatchSettingsComponent,
    BatchListComponent,
    GoogleAddonComponent,
    OpenBatchComponent,
    WebCaptureUploadComponent,
    WebCaptureBatchingComponent,
    WebCaptureBatchCreationComponent,
    UnauthorizedComponent,
    MergeDocumentsComponent
  ],
  imports: [
    AppRoutingModule,
    AutoComplete,
    BadgeModule,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonModule,
    CardModule,
    CheckboxModule,
    CommonModule,
    ConfirmDialogModule,
    DatePicker,
    DialogModule,
    DividerModule,
    FormsModule,
    FluidModule,
    HttpClientModule,
    InputNumber,
    InputTextModule,
    Listbox,
    Menu,
    MultiSelectModule,
    NgxSpinnerModule,
    Overlay,
    OverlayBadgeModule,
    OverlayModule,
    OverlayPanelModule,
    Popover,
    ReactiveFormsModule,
    RippleModule,
    RouterModule,
    Select,
    TableModule,
    TabsModule,
    TextareaModule,
    ToastModule,
    TooltipModule,
    DragDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgxDropzoneModule,
    FronteggAppModule.forRoot(environment.frontegg.moduleConfig)
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    CurrencyPipe,
    Title,
    TitleService,
    MessageService,
    ConfirmationService,
    providePrimeNG({
      ripple: true,
      theme: {
        preset: defaultPreset,
        options: {
          darkModeSelector: false
        }
      }
    })
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule implements DoBootstrap {
  constructor(private _fronteggAppService: FronteggAppService) {}

  ngDoBootstrap(appRef: ApplicationRef) {}
}
