<div class="container">
  <div class="tabs-container">
    <button
      class="tab"
      [ngClass]="{ 'active-tab': currentTab === 'available' }"
      (click)="filterByTab('available')"
      [attr.aria-label]="'available' | translate"
    >
      {{ 'available' | translate }}
    </button>
    <hr class="separator" />
    <!-- Rest of buttons might be used in the future
    <button
      class="tab"
      [ngClass]="{ 'active-tab': currentTab === 'draft' }"
      (click)="filterByTab('draft')"
    >
      Drafts
    </button>
    <button
      class="tab"
      [ngClass]="{ 'active-tab': currentTab === 'deleted' }"
      (click)="filterByTab('deleted')"
    >
      Deleted
    </button>-->
    <button
      class="tab"
      [ngClass]="{ 'active-tab': currentTab === 'completed' }"
      (click)="filterByTab('completed')"
      [attr.aria-label]="'completed' | translate"
    >
      {{ 'completed' | translate }}
    </button>
  </div>

  <div
    #tableContainer
    class="table-container"
    (scroll)="onScroll()"
  >
    <table class="batch-table">
      <thead>
        <tr>
          <th *ngFor="let col of cols">
            {{ col.header | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let batch of batches"
          class="batch-row"
        >
          <td *ngFor="let col of cols">
            <ng-container [ngSwitch]="col.field">
              <ng-container *ngSwitchCase="'status'">
                <span [ngClass]="getStatusBadgeClass(batch.status)">
                  {{ batch.status ?? '' | translate }}
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="'filingProgress'">
                {{ getFilingProgress(batch) }}
              </ng-container>
              <ng-container *ngSwitchCase="'batchId'">
                <span
                  class="batch-id"
                  (click)="openBatch(batch.batchId)"
                  (keyup.enter)="openBatch(batch.batchId)"
                  tabindex="0"
                >
                  {{ getFieldValue(batch, col.field) }}
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="'createdAt'">
                <span class="creation-date">{{
                  getFieldValue(batch, col.field) | date: 'MM/dd/yyyy HH:mm a'
                }}</span>
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ getFieldValue(batch, col.field) }}
              </ng-container>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      *ngIf="isLoading"
      class="loading-indicator"
    >
      <p>{{ 'loading' | translate }}</p>
    </div>
  </div>
</div>
