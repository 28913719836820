import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Area,
  ContentApiService,
  DocumentType
} from '../../../services/content-api/content-api.service';
import { TenantApiService } from '../../../services/tenant-api/tenant-api.service';
import { switchMap } from 'rxjs';
import { Department } from '../../../models/sdk-interfaces';
import { UserPrivilegesService } from '../../../services/auth-privileges/auth-privileges.service';

@Component({
  selector: 'app-batch-settings',
  templateUrl: './batch-settings.component.html',
  styleUrl: './batch-settings.component.scss'
})
export class BatchSettingsComponent implements OnInit {
  @Input() batchFormGroup: FormGroup = this.fb.group({
    batchId: [''],
    name: [''],
    department: [''],
    defaultArea: [''],
    defaultDocumentType: ['']
  });
  @Input() tenantId: string = '';

  documentTypes: DocumentType[] = [];
  areas: Area[] = [];
  filteredDocumentTypes: DocumentType[] = [];
  departments: Department[] = [];

  constructor(
    private fb: FormBuilder,
    private contentService: ContentApiService,
    private tenantService: TenantApiService,
    private userPrivilegesService: UserPrivilegesService
  ) {}

  ngOnInit(): void {
    this.batchFormGroup.get('department')?.setValidators(Validators.required);
    this.tenantService
      .getTenantUrls(this.tenantId)
      .pipe(
        switchMap(tenantUrls => {
          this.contentService.setBaseUrl(tenantUrls.contentApiUrl);
          return this.contentService.getDocumentTypes();
        }),
        switchMap(documentTypes => {
          this.documentTypes = documentTypes;
          return this.contentService.getAreas();
        })
      )
      .subscribe(areas => {
        this.areas = areas.sort((a, b) => a.name.localeCompare(b.name));
        this.batchFormGroup.get('defaultArea')?.valueChanges.subscribe(area => {
          this.batchFormGroup.get('defaultDocumentType')?.reset();
          this.filteredDocumentTypes = this.documentTypes.filter(
            documentType => documentType.areaCode === area
          );
        });
        this.userPrivilegesService
          .getDepartmentsByPrivilege('CREATE_BATCH')
          .subscribe(departments => {
            this.departments = departments.sort((a, b) => a.name.localeCompare(b.name));
          });
      });
  }
}
