import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, map, mergeMap, switchMap, throwError } from 'rxjs';
import { FieldValue } from '../../models/etrieveContent';
import { Attachment } from '../../models/attachment';
import { base64ToFile } from '../../utils/documentUtils';
import { Injectable } from '@angular/core';

interface ParameterValue {
  parameterName: string;
  parameterCode: string;
  value: string;
}

interface Rule {
  name: string;
  code: string;
  parameterValues: ParameterValue[];
}

interface LookupType {
  code: string;
  name: string;
  keyfields: {
    name: string;
    code: string;
    dataType: string;
    isUniqueIdentifier: boolean;
    displayOrder: number;
  }[];
  namingFields: {
    fieldCode: string;
    required: boolean;
  }[];
}

export interface KeyField {
  code: string;
  dataType: string;
  name: string;
  displayOrder: number;
  rules: Rule[];
  lookupType?: LookupType;
  multiline?: boolean;
  textPattern?: string;
  default?: string;
  required?: boolean;
  maxLength?: number;
  multivalue?: boolean;
  minValue?: number;
  constraint: string[];
}

export interface DocumentType {
  name: string;
  code: string;
  areaName: string;
  areaCode: string;
  categoryName: string;
  categoryCode: string;
  keyfields: KeyField[];
}

export interface Area {
  name: string;
  code: string;
}

@Injectable({
  providedIn: 'root'
})
export class ContentApiService {
  private baseUrl: string = '';

  constructor(private http: HttpClient) {}

  /**
   *
   * @param url a url string that represents the baseUrl of the Softdocs content service
   */
  setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  /**
   * fetch a paged list of document types
   */
  getDocumentTypes(): Observable<DocumentType[]> {
    const documentTypes: DocumentType[] = [];

    return this.fetchDocumentTypes(0, 100, documentTypes);
  }

  /**
   * fetch a list of document types
   * @param offset number of items to skip before collecting response items
   * @param limit page size
   * @param documentTypes a collective list of document types
   */
  private fetchDocumentTypes(
    offset: number,
    limit: number,
    documentTypes: DocumentType[]
  ): Observable<DocumentType[]> {
    const params = new HttpParams().set('offset', offset.toString()).set('limit', limit.toString());

    const requestUrl = `${this.baseUrl}/api/documenttypes`;

    return this.http.get<DocumentType[]>(requestUrl, { params }).pipe(
      mergeMap(response => {
        documentTypes.push(...response);

        if (response.length === limit) {
          return this.fetchDocumentTypes(offset + limit, limit, documentTypes);
        } else {
          return [documentTypes];
        }
      }),
      catchError(throwError)
    );
  }

  /**
   * fetch a paged list of areas
   */
  getAreas(): Observable<Area[]> {
    const areas: Area[] = [];

    return this.fetchAreas(0, 100, areas);
  }

  /**
   * fetch a list of areas
   * @param offset number of items to skip before collecting response items
   * @param limit page size
   * @param areas a collective list of areas
   */
  private fetchAreas(offset: number, limit: number, areas: Area[]): Observable<Area[]> {
    const params = new HttpParams().set('offset', offset.toString()).set('limit', limit.toString());

    const requestUrl = `${this.baseUrl}/api/areas`;

    return this.http.get<Area[]>(requestUrl, { params }).pipe(
      mergeMap(response => {
        areas.push(...response);

        if (response.length === limit) {
          return this.fetchAreas(offset + limit, limit, areas);
        } else {
          return [areas];
        }
      }),
      catchError(throwError)
    );
  }

  /**
   * Uploads a document to Etrieve
   * @param attachment the attachment to upload
   * @param area the area the attachment should be placed in
   * @param documentType the document type for the attachment
   * @param fieldValues key fields for the attachment
   */
  uploadDocument(
    attachment: Attachment,
    area: string,
    documentType: string,
    fieldValues: FieldValue[]
  ): Observable<any> {
    const body = {
      areaCode: area,
      documentTypeCode: documentType,
      fieldValues: fieldValues
    };

    return this.http.post<any>(`${this.baseUrl}/api/documents`, body).pipe(
      switchMap(metadataResponse => {
        const headers = new HttpHeaders({
          'Content-Type': attachment.attachmentType,
          'X-File-Attributes': JSON.stringify({
            Filename: attachment.name,
            ContentType: attachment.attachmentType
          })
        });

        const file = base64ToFile(
          attachment.content.content,
          attachment.name,
          attachment.attachmentType
        );

        return this.http.post<any>(
          `${this.baseUrl}/api/documents/${metadataResponse.id}/contents`,
          file,
          { headers: headers }
        ).pipe(
          map(contentsResponse => ({
            metadataResponse,
            contentsResponse
          }))
        );
      })
    );
  }

  lookupUser(nameSearch: string, lookupTypeCode: string): Observable<any> {
    const params = new HttpParams()
      .set('lookupTypeCode', lookupTypeCode)
      .set('nameSearchText', nameSearch);
    return this.http.get<any>(`${this.baseUrl}/api/lookups`, { params }).pipe(
      mergeMap(response => {
        return [response];
      })
    );
  }
}
