<url-verification
  *ngIf="!isAccessFlagLoading && !isUrlVerified; else webCaptureContainer"
  [hasAccess]="isAccessEnabled"
  [noAccessMessage]="featureFlagErrorText | translate"
  (successfulVerificationEvent)="onSuccessfulVerification()"
  (mustSignOutEvent)="signOut()"
></url-verification>

<ng-template #webCaptureContainer>
  <div
    class="container"
    *ngIf="!isLoading && user"
  >
    <div class="header-container">
      <img
        src="../../../assets/etrieve.svg"
        class="etrieve-logo"
        alt="Etrieve Logo"
      />
      <span class="header-title">
        {{ 'webCapture' | translate }}
      </span>
      <div class="nav-buttons">
        <button
          class="header-button"
          [attr.aria-label]="'about' | translate"
          title="{{ 'resourceCenter' | translate }}"
        >
          <fa-icon
            class="header-icon pendo-icon"
            [icon]="faQuestionCircle"
          />
        </button>
        <button
          class="header-button"
          (click)="toggleShowSettings()"
          [attr.aria-label]="'settings' | translate"
          title="{{ 'settings' | translate }}"
          [ngClass]="{ active: showSettings }"
        >
          <fa-icon
            class="header-icon"
            [icon]="faGear"
          />
        </button>
        <div
          class="settings-menu"
          *ngIf="showSettings"
          (focusout)="checkNavButtonsFocus($event)"
        >
          <button
            (click)="showAboutModal = true; showSettings = false"
            [attr.aria-label]="'about' | translate"
            class="settings-option"
          >
            <span>
              <fa-icon [icon]="faInfoCircle" />
              {{ 'about' | translate }}
            </span>
          </button>
          <div class="separator-line"></div>
          <span class="only-text settings-option">
            {{ user.name }}
          </span>
          <button
            (click)="changeContentUrl()"
            [attr.aria-label]="'etrieveContentUrl' | translate"
            class="settings-option"
          >
            <span>
              {{ 'etrieveContentUrl' | translate }}
            </span>
          </button>
          <button
            (click)="signOut()"
            [attr.aria-label]="'signOut' | translate"
            class="settings-option"
          >
            <span>
              <fa-icon [icon]="faSignOut" />
              {{ 'signOut' | translate }}
            </span>
          </button>
        </div>
      </div>
    </div>
    <!-- Tab buttons for Ad hoc and Batch Upload -->
    <div class="tab-buttons">
      <button
        (click)="goToTab('adHocUpload')"
        [class.active]="currentTab === 'adHocUpload'"
        [attr.aria-label]="'documentUpload' | translate"
      >
        <fa-icon [icon]="faFile"></fa-icon>
        {{ 'documentUpload' | translate }}
      </button>
      <button
        (click)="goToTab('batchUpload')"
        [class.active]="currentTab === 'batchUpload'"
        [attr.aria-label]="'documentBatching' | translate"
      >
        <img
          src="../../../assets/rectangle-history-circle-plus-solid.svg"
          alt="Upload Batch Icon"
        />
        {{ 'documentBatching' | translate }}
      </button>
    </div>
    <!-- Document Filing section -->
    <div
      class="doc-filing-container"
      [hidden]="!isFilingBatch && !isFilingAdHoc"
      [ngStyle]="{ display: isFilingBatch || isFilingAdHoc ? 'flex' : 'none' }"
    >
      <app-file-selector
        [attachments]="getFileSelectorAttachments()"
        [currentAttachmentIndex]="currentAttachmentIndex"
        (selectAttachmentEvent)="selectAttachment($event)"
        [enableBatchOptions]="isFilingBatch"
        (openEditBatchEvent)="openEditBatchModal()"
        (openAddDocumentsEvent)="openAddDocumentsModal()"
        (deleteBatchEvent)="confirmDeleteBatch()"
        (closeBatchEvent)="confirmCloseBatch()"
        [isLoadingFiles]="loadingFilingFiles"
      ></app-file-selector>
      <div
        class="document-viewer-container"
        [hidden]="showUnsupportedFileMessage"
      >
        <document-viewer-widget
          [base64Content]="documents[currentAttachmentIndex]?.fileContent"
          [showClassificationButton]="false"
          (unsupportedFileType)="showUnsupportedFileMessage = true"
        ></document-viewer-widget>
      </div>
      <div
        *ngIf="showUnsupportedFileMessage"
        class="unsupported-msg-container"
      >
        <span class="unsupported-msg-text">{{ 'unsupportedFileType' | translate }}</span>
      </div>
      <div class="key-fields-container">
        <app-key-fields
          *ngIf="isFilingBatch || isFilingAdHoc"
          [docAreas]="areas"
          [docFormGroup]="documentForm"
          (changeEvent)="formOnChange($event)"
          [attachmentStatus]="documents[this.currentAttachmentIndex]?.status"
          [filteredDocumentTypes]="filteredDocumentTypes"
          [currentDocumentType]="currentDocumentType"
          (uploadDocumentEvent)="uploadDocument()"
          (cancelFileFilingEvent)="cancelFileFiling()"
          [showDeleteDocumentButton]="true"
          (deleteDocumentEvent)="deleteDocument()"
          [isAutoClassificationEnabled]="isAutoClassificationEnabled || isFilingBatch"
          (cancelFileAutoClassificationEvent)="cancelFileAutoClassification()"
        ></app-key-fields>
      </div>
    </div>

    <!-- Ad Hoc Upload Section -->
    <div
      class="ad-hoc-upload-container"
      *ngIf="currentTab === 'adHocUpload' && !isFilingAdHoc"
    >
      <div
        class="ad-hoc-upload"
        *ngIf="!isFilingAdHoc"
      >
        <div class="ad-hoc-upload-header">
          <span class="ad-hoc-upload-title">{{ title | translate }}</span>
          <span class="ad-hoc-upload-description">{{ 'adHocUploadDescription' | translate }}</span>
        </div>
        <div class="file-uploader-container">
          <app-file-uploader
            [tenantId]="tenantId"
            (uploadedDocumentIdsEvent)="startAdHocFiling($event)"
          ></app-file-uploader>
        </div>
      </div>
    </div>

    <!-- Batch Upload Section -->
    <div
      class="batch-upload-container"
      *ngIf="currentTab === 'batchUpload' && !isFilingBatch"
    >
      <!-- Batch Creation Section -->
      <div
        class="create-batch-container"
        *ngIf="!isCreatingBatch && !isFilingBatch"
      >
        <div class="create-batch-header">
          <div class="header-text">
            <span class="create-batch-title">{{ 'createBatchTitle' | translate }}</span>
            <span class="create-batch-description">{{ 'createBatchDescription' | translate }}</span>
          </div>
          <button
            class="create-batch-button"
            (click)="startBatchCreation()"
            [attr.aria-label]="'createBatch' | translate"
          >
            <img
              src="../../../assets/rectangle-history-circle-plus-solid.svg"
              alt="Create Batch Icon"
            />
            <span>{{ 'createBatch' | translate }}</span>
          </button>
        </div>

        <!-- Batch List Component -->
        <div class="batch-list-container">
          <app-batch-list
            [tenantId]="tenantId"
            (openBatchEvent)="openBatch($event)"
          ></app-batch-list>
        </div>
      </div>

      <!-- Batch Creation Component -->
      <app-batch-creation
        *ngIf="isCreatingBatch"
        [tenantId]="tenantId"
        [userId]="user.customClaims.principal_id"
        [areas]="areas"
        (cancelBatchCreationEvent)="cancelBatchCreation()"
        (hasUploadedFilesEvent)="hasUploadedFilesAtCreation = true"
      ></app-batch-creation>
    </div>
  </div>
  <p-dialog
    [header]="'editBatch' | translate"
    [modal]="true"
    [(visible)]="showEditBatchModal"
    [style]="{ width: '550px' }"
    class="edit-batch-dialog"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="batch-info">
      <div class="batch-info-item">
        <span>{{ 'batchId' | translate }}</span>
        <span>{{ currentOpenBatch?.batchId }}</span>
      </div>
      <div class="batch-info-item">
        <span>{{ 'creationDate' | translate }}</span>
        <span>{{ currentOpenBatch?.createdAt | date: 'MM/dd/yyyy HH:mm a' }}</span>
      </div>
      <div class="batch-info-item">
        <span>{{ 'documentCount' | translate }}</span>
        <span>{{ currentOpenBatch?.statistics?.documentCount }}</span>
      </div>
      <div class="batch-info-item">
        <span>{{ 'createdBy' | translate }}</span>
        <span>{{ currentOpenBatch?.createdBy }}</span>
      </div>
    </div>
    <div class="batch-settings">
      <span class="batch-setting-title">{{ 'batchSettings' | translate }}</span>
      <form
        *ngIf="showEditBatchModal"
        class="edit-batch-form"
        [formGroup]="editBatchFormGroup"
      >
        <label>
          <span>{{ 'batchName' | translate }}</span>
          <input
            type="text"
            formControlName="name"
          />
        </label>
        <label htmlFor="areaAccess">
          <span>{{ 'areaAccess' | translate }}</span>
          <p-multiSelect
            [options]="settingsAreas"
            formControlName="areas"
            optionLabel="name"
            display="chip"
            [showClear]="false"
          />
        </label>
        <label>
          <span>{{ 'defaultDocumentType' | translate }}</span>
          <select formControlName="defaultDocumentType">
            <option
              *ngFor="let documentType of batchEditFilteredDocumentTypes"
              [value]="documentType.code"
            >
              {{ documentType.name }}
            </option>
          </select>
        </label>
      </form>
    </div>
    <div class="buttons-container">
      <button
        class="cancel-button"
        (click)="closeEditBatchModal()"
        [attr.aria-label]="'cancel' | translate"
      >
        {{ 'cancel' | translate }}
      </button>
      <button
        class="save-button"
        (click)="saveBatchSettings()"
        [attr.aria-label]="'save' | translate"
      >
        {{ 'save' | translate }}
      </button>
    </div>
  </p-dialog>
  <p-dialog
    [header]="'addDocuments' | translate"
    [modal]="true"
    [(visible)]="showAddDocumentsModal"
    [style]="{ width: '647px' }"
    class="add-documents-dialog"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="file-uploader-container">
      <app-file-uploader
        [tenantId]="tenantId"
        (uploadedDocumentIdsEvent)="addDocumentsToBatch($event)"
      >
      </app-file-uploader>
    </div>
  </p-dialog>
  <p-dialog
    [header]="'about' | translate"
    [modal]="true"
    [(visible)]="showAboutModal"
    [style]="{ width: '680px' }"
    class="about-dialog"
    [draggable]="false"
    [resizable]="false"
  >
    <p>
      {{ 'aboutHeader' | translate }}
    </p>
    <span class="title">
      {{ 'conformanceStatus' | translate }}
    </span>
    <p>
      <a
        href="https://www.w3.org/WAI/standards-guidelines/wcag/"
        target="_blank"
        >{{ 'wcagLink' | translate }}</a
      >
      {{ 'conformanceStatusText' | translate }}
      <a
        href="https://www.softdocs.com/trust/"
        target="_blank"
        >{{ 'vpatLink' | translate }}</a
      >
    </p>
    <hr class="separator-line" />
    <span class="title">
      {{ 'feedback' | translate }}
    </span>
    <p>
      {{ 'feedbackText' | translate }}
    </p>
    <ul>
      <li>
        {{ 'feedbackPhone' | translate }}
      </li>
      <li>
        {{ 'feedbackEmail' | translate }}
      </li>
      <li>
        {{ 'feedbackPostal' | translate }}
      </li>
    </ul>
    <p>
      {{ 'feedbackEnd' | translate }}
    </p>
    <hr class="separator-line" />
    <div class="about-buttons">
      <button
        class="close-button"
        (click)="showAboutModal = false"
        [attr.aria-label]="'close' | translate"
      >
        {{ 'close' | translate }}
      </button>
    </div>
  </p-dialog>
  <p-confirmDialog
    #tabChangeDialog
    key="tabChangeDialog"
  >
    <ng-template
      pTemplate="headless"
      let-message
    >
      <div class="confirm-container">
        <div class="">
          <p>{{ message.message | translate }}</p>
        </div>
        <div class="buttons-container">
          <button
            class="cancel-button"
            (click)="tabChangeDialog.reject()"
            [attr.aria-label]="'cancel' | translate"
          >
            {{ 'cancel' | translate }}
          </button>
          <button
            class="confirm-button"
            (click)="tabChangeDialog.accept()"
            [attr.aria-label]="'confirm' | translate"
          >
            {{ 'confirm' | translate }}
          </button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>
  <p-confirmDialog
    #closeBatchDialog
    key="closeBatchDialog"
  >
    <ng-template
      pTemplate="headless"
      let-message
    >
      <div class="confirm-container">
        <div class="">
          <p>{{ message.message | translate }}</p>
        </div>
        <div class="buttons-container">
          <button
            class="cancel-button"
            (click)="closeBatchDialog.reject()"
            [attr.aria-label]="'cancel' | translate"
          >
            {{ 'cancel' | translate }}
          </button>
          <button
            class="confirm-button"
            (click)="closeBatchDialog.accept()"
            [attr.aria-label]="'confirm' | translate"
          >
            {{ 'confirm' | translate }}
          </button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>
  <p-confirmDialog
    #deleteBatchConfirmDialog
    key="deleteBatchConfirmDialog"
    [header]="'warning' | translate"
  >
    <ng-template
      pTemplate="message"
      let-message
    >
      <div class="delete-confirm-container">
        <div class="">
          <p>{{ 'confirmDeleteBatchMessage' | translate }}</p>
          <p>{{ 'confirmDeleteBatchMessage2' | translate }}</p>
        </div>
      </div>
    </ng-template>
    <ng-template
      pTemplate="footer"
      let-footer
    >
      <div class="delete-confirm-buttons-container">
        <button
          class="cancel-button"
          (click)="deleteBatchConfirmDialog.reject()"
        >
          {{ 'cancel' | translate }}
        </button>
        <button
          class="confirm-button"
          (click)="deleteBatchConfirmDialog.accept()"
        >
          {{ 'deleteBatch' | translate }}
        </button>
      </div>
    </ng-template>
  </p-confirmDialog>
  <p-confirmDialog
    #deleteBatchFileConfirmDialog
    key="deleteBatchFileConfirmDialog"
    [header]="'warning' | translate"
  >
    <ng-template
      pTemplate="message"
      let-message
    >
      <div class="delete-confirm-container">
        <div class="">
          <p>{{ 'confirmDeleteBatchFileMessage' | translate }}</p>
          <p>{{ 'confirmDeleteBatchFileMessage2' | translate }}</p>
        </div>
      </div>
    </ng-template>
    <ng-template
      pTemplate="footer"
      let-footer
    >
      <div class="delete-confirm-buttons-container">
        <button
          class="cancel-button"
          (click)="deleteBatchFileConfirmDialog.reject()"
        >
          {{ 'cancel' | translate }}
        </button>
        <button
          class="confirm-button"
          (click)="deleteBatchFileConfirmDialog.accept()"
        >
          {{ 'deleteDocument' | translate }}
        </button>
      </div>
    </ng-template>
  </p-confirmDialog><p-confirmDialog #deleteAdHocFileConfirmDialog key="deleteAdHocFileConfirmDialog" [header]="'warning' | translate">
    <ng-template pTemplate="message" let-message>
      <div class="delete-confirm-container">
        <div class="">
          <p>{{ 'confirmDeleteAdHocFileMessage' | translate }}</p>
          <p>{{ 'confirmDeleteAdHocFileMessage2' | translate }}</p>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer" let-footer>
      <div class="delete-confirm-buttons-container">
        <button class="cancel-button" (click)="deleteAdHocFileConfirmDialog.reject()">
          {{ 'cancel' | translate }}
        </button>
        <button class="confirm-button" (click)="deleteAdHocFileConfirmDialog.accept()">
          {{ 'deleteDocument' | translate }}
        </button>
      </div>
    </ng-template>
  </p-confirmDialog>
</ng-template>
