// External Libraries
import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BehaviorSubject,
  Subscription,
  catchError,
  combineLatest,
  lastValueFrom,
  of,
  switchMap
} from 'rxjs';
import { FronteggAuthService } from '@frontegg/angular';
import { ConfirmationService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';

// Project Services
import {
  Area,
  ContentApiService,
  DocumentType,
  KeyField as DocumentTypeKeyField
} from '../../../services/content-api/content-api.service';
import { DocumentManagementService } from '../../../services/document-management/document-management.service';
import { FeatureFlagService } from '../../../services/feature-flag/feature-flag.service';
import { TenantApiService } from '../../../services/tenant-api/tenant-api.service';
import { SignOutService } from '../../../services/sign-out-state/sign-out-state.service';
import { UserPrivilegesService } from '../../../services/auth-privileges/auth-privileges.service';

// Project Models
import { MappingKeyField, MapResponse } from '../../../models/docTypeMapping';
import { DocumentResponse } from '../../../models/documentResponse';
import { ErrorResponse } from '../../../models/errorResponse';
import { ExtractionResponse } from '../../../models/extractionResponse';
import { KeyField, StatusResponse } from '../../../models/statusResponse';
import { BulkCreateBatchDocumentRequest } from '../../../models/sdk-interfaces';

// Project Components
import { Attachment } from '../../../models/attachment';
import { FileSelectorAttachment } from '../../../components/file-selector/file-selector.component';
import { KeyFieldsComponent } from '../../../components/key-fields/key-fields.component';

// Utilities
import { base64ToFile, buildKeyFields } from '../../../utils/documentUtils';

// Environment Variables
import { AutoclassifierSdkService } from '../../../services/autoclassifier-sdk/autoclassifier-sdk.service';
import { KeyFieldsService } from '../../../services/key-fields/key-fields.service';

interface Document {
  contentUri: string;
  fileName: string;
  fileId: string;
  fileContent: string;
  contentType: string;
  requestId?: string;
  status?: StatusResponse;
  completed: boolean;
  classificationStarted: boolean;
  canceled?: boolean;
  mappedItems?: MapResponse['mapItems'];
}

@Component({
  selector: 'app-web-capture-upload',
  templateUrl: './web-capture-upload.component.html',
  styleUrls: ['./web-capture-upload.component.scss']
})
export class WebCaptureUploadComponent implements OnInit, OnDestroy {
  @ViewChild(KeyFieldsComponent) keyFieldsComponent!: KeyFieldsComponent;

  private activeKeys: Set<string> = new Set();
  private subscription = new Subscription();
  private signOutSubscription = new Subscription();

  documentsReceived$ = new BehaviorSubject<boolean>(false);
  autoclassificationFlag$ = new BehaviorSubject<boolean>(false);

  isAutoClassificationEnabled: boolean = false;
  isAutoclassificationFlagLoading: boolean = true;

  title = 'documentUpload';
  user?: any;

  tenantId: string = '';
  documents: Document[] = [];
  documentIds: string[] = [];
  documentTypes: DocumentType[] = [];
  areas: Area[] = [];
  documentForm!: FormGroup;
  filteredDocumentTypes: DocumentType[] = [];
  currentDocumentType: DocumentType | undefined;
  formStates: Map<number, any> = new Map();
  currentAttachmentIndex: number = 0;
  enableBatchOptions: boolean = false;
  showAddDocumentsModal: boolean = false;
  showMergeDocuments: boolean = false;

  isFiling: boolean = false;
  isLoadingFiles: boolean = false;
  currentMostConfidentDocument: any;
  showClassificationResults: boolean = false;
  showUnsupportedFileMessage: boolean = false;

  constructor(
    private fronteggAuthService: FronteggAuthService,
    private featureFlagService: FeatureFlagService,
    private tenantService: TenantApiService,
    private contentService: ContentApiService,
    private docMngmtService: DocumentManagementService,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private signOutService: SignOutService,
    private autoclassifierSdkService: AutoclassifierSdkService,
    private userPrivilegesService: UserPrivilegesService,
    public keyFieldsService: KeyFieldsService //public because that's how anna did it. Can this be private?
  ) {}

  /**
   * Initializes the component, setting up necessary data and feature flags
   */
  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') || '';
    this.keyFieldsService.setTenantId(this.tenantId);
    const organization = localStorage.getItem('vanityName');

    if (this.tenantId && organization) {
      this.subscription.add(
        this.fronteggAuthService.authState$.subscribe(authState => {
          if (authState.user?.accessToken) {
            this.user = authState.user;
          }
        })
      );
      this.keyFieldsService.currentDocumentType$.subscribe(documentType => {
        this.currentDocumentType = documentType;
      });
      this.keyFieldsService.documentForm$.subscribe(form => {
        this.documentForm = form || this.fb.group({}); //come back here. form should never be null really
      });
      
      this.keyFieldsService.formStates$.subscribe(formStates => {
        this.formStates = formStates;
      }
      );
    }

    this.subscription.add(
      combineLatest([this.documentsReceived$, this.autoclassificationFlag$]).subscribe(
        ([documentsReceived, autoclassificationEnabled]) => {
          if (documentsReceived && autoclassificationEnabled) {
            this.analyzeDocuments();
          }
        }
      )
    );

    this.signOutSubscription = this.signOutService.signOut$.subscribe(signOut => {
      if (signOut) {
        this.canDeactivate();
      }
    });
  }

  startAdHocFiling(docIds: string[]) {
    this.documentIds = docIds;
    this.isLoadingFiles = true;
    this.isFiling = true;
    this.signOutService.isFilingContent = true;

    this.loadData();

    this.documentsReceived$.next(false);

    this.initializeDocumentForm();
  }

  /**
   * Retrieves the attachments for the file selector
   * @returns {FileSelectorAttachment[]} - An array of file selector attachments
   */
  getFileSelectorAttachments(): FileSelectorAttachment[] {
    const attachments = this.documents.map(
      doc =>
        ({
          name: doc.fileName,
          id: doc.fileId,
          completed: doc.completed,
          status: doc.status as StatusResponse,
          classificationStarted: doc.classificationStarted,
          canceled: doc.canceled,
          fileContent: doc.fileContent
        }) as FileSelectorAttachment
    );
    return attachments;
  }

  analyzeDocuments(): void {
    if (this.documents.length === 0 || !this.isAutoClassificationEnabled) {
      return;
    }

    Promise.all(this.documents.map(doc => this.analyzeDocument(doc)))
      .then(() => {})
      .catch(error => {
        console.error('Error analyzing documents', error);
      });
  }

  /**
   * Initializes the document form with default values
   */
  initializeDocumentForm() {
    this.documentForm = this.fb.group({
      area: [''],
      documentTypeCode: ['']
    });
  }

  /**
   * Event listener to handle changes to an attachment's classification request
   * @param event - The documentStatusUpdate event
   */
  @HostListener('window:documentStatusUpdate', ['$event'])
  handleDocumentStatusUpdate(event: CustomEvent) {
    const index = this.documents.findIndex(
      document => document.requestId === event.detail.requestId
    );
    if (index !== -1 && !this.documents[index].status) {
      this.documents[index].status = event.detail.status;
      this.keyFieldsService.patchDocuments(this.documents[this.currentAttachmentIndex], this.currentAttachmentIndex);
      if (
        index === this.currentAttachmentIndex &&
        this.documents[this.currentAttachmentIndex].classificationStarted
      ) {
        this.keyFieldsService.buildFormFields()
      }
    }
  }

  /**
   * Event listener to handle errors in a document's classification request
   * @param {CustomEvent} event - The documentStatusError event
   */
  @HostListener('window:documentStatusError', ['$event'])
  handleDocumentStatusError(event: CustomEvent) {
    const { requestId, errorCode } = event.detail;
    const index = this.documents.findIndex(doc => doc.requestId === requestId);

    if (index !== -1) {
      const doc = this.documents[index];

      let errorMessage;
      switch (errorCode) {
        case 'documentInvalid':
          errorMessage = 'documentInvalidError';
          break;
        default:
          errorMessage = 'analyzeError';
          break;
      }

      this.showErrorToast(errorMessage, doc.fileName);
      this.cancelFileAutoClassification(index);

      if (
        index === this.currentAttachmentIndex &&
        this.documents[this.currentAttachmentIndex].classificationStarted
      ) {
        this.keyFieldsService.buildFormFields();
      }
    }
  }

  /**
   * Initializes data to be used by the component
   */
  loadData(): void {
    this.featureFlagService.identify({
      key: this.user.customClaims.principal_id,
      name: this.user.name,
      tenantId: this.tenantId
    });

    //clear data if it exists
    this.formStates.clear();
    this.documents = [];
    this.keyFieldsService.clearDocuments(); 

    this.subscription.add(
      this.featureFlagService
        .getFlag('autoclassification-for-web-capture', false)
        .pipe(
          switchMap(flagValue => {
            this.isAutoclassificationFlagLoading = false;
            this.isAutoClassificationEnabled = flagValue as boolean;

            console.log(
              'autoclassification-for-web-capture flag value:',
              this.isAutoClassificationEnabled
            );

            this.autoclassificationFlag$.next(flagValue);

            return this.tenantService.getTenantUrls(this.tenantId);
          }),
          switchMap(tenantUrls => {
            this.contentService.setBaseUrl(tenantUrls.contentApiUrl);
            
            return this.contentService.getDocumentTypes();
          }),
          switchMap(docTypes => {
            this.documentTypes = docTypes;
            this.keyFieldsService.setDocumentTypes(docTypes);
            return this.contentService.getAreas();
          }),
          catchError(error => {
            console.error('Error in chained requests', error);
            this.showErrorToast('dataError');
            return of([]); // Return an observable to keep the stream alive
          })
        )
        .subscribe(areas => {
          this.areas = areas;
          Promise.all(this.documentIds.map(docId => this.getDocumentFromDocMgmtSdk(docId))).then(
            () => {
              this.isLoadingFiles = false;
              this.documentsReceived$.next(true);

              if (this.documents.length > 0) {
                this.currentAttachmentIndex = 0;
                //make sure this is updated in the service too
                this.keyFieldsService.setCurrentAttachmentIndex(0);
                this.selectAttachment(0);
              }
            }
          );
        })
    );
  }

  /**
   * Retrieves the document from the Document Management SDK
   * @param {string} documentId - The ID of the document to retrieve
   */
  getDocumentFromDocMgmtSdk(documentId: string): Promise<void> {
    return this.autoclassifierSdkService.documentManagementSdk
      .getDocument(this.tenantId, documentId)
      .then((response: DocumentResponse) => {
        const newDocument: Document = {
          contentUri: response.contentUri,
          fileName: response.fileName,
          fileId: response.id,
          contentType: response.type,
          fileContent: '',
          completed: false,
          classificationStarted: true
        };

        return lastValueFrom(this.docMngmtService.getDocument(newDocument.contentUri)).then(
          arrayBuffer => {
            const binaryString = Array.from(new Uint8Array(arrayBuffer))
              .map(byte => String.fromCharCode(byte))
              .join('');
            const base64 = btoa(binaryString);
            newDocument.fileContent = base64;
            this.documents.push(newDocument); 
            this.keyFieldsService.addDocument(newDocument);
            this.cdr.detectChanges();

            this.spinner.show('fileClassificationSpinner-' + newDocument.fileName);
          }
        );
      })
      .catch((error: ErrorResponse) => {
        console.error('Document get Error:', error);
        this.showErrorToast('getDocumentError');
      });
  }

  /**
   * Selects an attachment and builds form fields for that attachment
   * @param {number} index - The index of the selected attachment
   */
  selectAttachment(index: number): void {
    this.showUnsupportedFileMessage = false;
    if (index >= 0 && index < this.documents.length) {
      this.formStates.set(this.currentAttachmentIndex, this.documentForm.value);
      this.currentAttachmentIndex = index;
      this.keyFieldsService.setCurrentAttachmentIndex(index);

      if (
        this.documents[this.currentAttachmentIndex].classificationStarted ||
        !this.isAutoClassificationEnabled
      ) {
        this.keyFieldsService.buildFormFields();
      } else {
        this.initializeDocumentForm(); // Initialize the form if no classification has started
      }
    }
  }

  /**
   * Handles the keydown event for both macOS and Windows platforms
   * @param {KeyboardEvent} event - The keydown event
   */
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.activeKeys.add(event.key.toLowerCase());

    const isMac = this.isMacPlatform();

    // CMD + SHIFT + A + I
    const isMacShortcutPressed =
      isMac &&
      event.metaKey &&
      event.shiftKey &&
      this.activeKeys.has('a') &&
      this.activeKeys.has('i');

    // CTRL + SHIFT + A + I
    const isWindowsShortcutPressed =
      !isMac &&
      event.ctrlKey &&
      event.shiftKey &&
      this.activeKeys.has('a') &&
      this.activeKeys.has('i');

    if (isMacShortcutPressed || isWindowsShortcutPressed) {
      event.preventDefault();
      this.openDialog();
      this.activeKeys.clear();
    }
  }

  /**
   * Handles the keyup event by removing the key from the activeKeys set
   * @param {KeyboardEvent} event - The keyup event
   */
  @HostListener('window:keyup', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    this.activeKeys.delete(e.key.toLowerCase());
  }

  /**
   * Checks if the platform is macOS
   * @returns {boolean} - Returns true if the platform is macOS, otherwise false
   */
  isMacPlatform(): boolean {
    // Use navigator.userAgentData if available (future-proof)
    if ((navigator as any).userAgentData) {
      return (navigator as any).userAgentData.platform === 'macOS';
    }

    // Fallback to user agent string check if userAgentData is not available
    return /Mac|iPhone|iPod|iPad/.test(navigator.userAgent);
  }

  /**
   * Opens the classification results dialog.
   */
  openDialog() {
    if (!this.showClassificationResults) {
      this.currentMostConfidentDocument = this.keyFieldsService.getMostConfidentDocument(
        this.documents[this.currentAttachmentIndex]
      );
    } else {
      this.currentMostConfidentDocument = null;
    }
    this.showClassificationResults = !this.showClassificationResults;
  }

  /**
   * Closes the classification results dialog.
   */
  closeClassificationResults(): void {
    this.showClassificationResults = false;
  }

  /**
   * Handles changes in the form, such as area or document type selection
   * @param {Event} event - The change event from the form
   */
  formOnChange(event: any): void {
    this.keyFieldsService.formOnChange(event);
  }



  /**
   * Starts the classification process for the selected attachment
   */
  startAttachmentClassification(): void {
    this.keyFieldsService.patchDocuments(this.documents[this.currentAttachmentIndex], this.currentAttachmentIndex);
    if (this.documents[this.currentAttachmentIndex].status) {
      //this.buildFormFields();
      this.keyFieldsService.buildFormFields();
    }
  }

  /**
   * Analyzes a document using the autoclassifier SDK
   * @param {PrinterDocument} document - The document to analyze
   * @returns {Promise<void>} - A promise that resolves when the analysis is complete
   */
  async analyzeDocument(document: Document): Promise<void> {
    const file = base64ToFile(document.fileContent, document.fileName, 'application/pdf');
    this.autoclassifierSdkService.documentAnalysisSdk
      .extractDocumentFromUpload(file, true)
      .then((response: ExtractionResponse) => {
        document.requestId = response.requestId;
      })
      .catch((error: ErrorResponse) => {
        console.error('Error analyzing document:', error);
        this.showErrorToast('analyzeError', document.fileName);
      });
  }

  /**
   * Uploads an attachment with type, area, and key field information to Softdocs Etrieve Content.
   */
  uploadDocument(): void {
    if (this.documentForm?.valid) {
      const formData = buildKeyFields(
        this.documentForm.value,
        this.currentDocumentType as DocumentType
      );

      const multiValueFields = this.currentDocumentType?.keyfields.filter(kf => kf.multivalue);

      if (multiValueFields) {
        multiValueFields.forEach(multiValueField => {
          const multiValueFieldKey = `${multiValueField.code}-${this.currentDocumentType?.code}-${this.documents[this.currentAttachmentIndex]?.fileId}`;

          if (this.keyFieldsComponent.multiValueFields[multiValueFieldKey]) {
            this.keyFieldsComponent.multiValueFields[multiValueFieldKey].forEach(value => {
              formData.push({
                fieldCode: multiValueField.code,
                value: value
              });
            });
          }
        });
      }

      const attachment: Attachment = {
        content: {
          content: this.documents[this.currentAttachmentIndex].fileContent,
          format: 'base64'
        },
        attachmentType: this.documents[this.currentAttachmentIndex].contentType,
        requestId: this.documents[this.currentAttachmentIndex].requestId,
        name: this.documents[this.currentAttachmentIndex].fileName,
        contentType: this.documents[this.currentAttachmentIndex].contentType,
        id: '',
        isInline: false,
        size: 0
      };

      this.contentService
        .uploadDocument(
          attachment,
          this.documentForm.controls['area'].value as string,
          this.documentForm.controls['documentTypeCode'].value as string,
          formData
        )
        .subscribe({
          error: error => {
            console.error('Failed to upload document', error);
            this.showErrorToast('uploadError');
            this.keyFieldsComponent.onUploadComplete();
          },
          complete: () => {
            this.autoclassifierSdkService.documentManagementSdk
              .deleteDocument(this.tenantId, this.documents[this.currentAttachmentIndex].fileId)
              .then(() => {
                console.log(
                  'Document deleted;',
                  this.documents[this.currentAttachmentIndex].fileId
                );
              });
            this.showUploadDoneToast();
            this.keyFieldsComponent.onUploadComplete();
            this.documents[this.currentAttachmentIndex].completed = true; //update in the service too
            this.keyFieldsService.patchDocuments(this.documents[this.currentAttachmentIndex], this.currentAttachmentIndex);
            this.spinner.hide(
              'fileClassificationSpinner-' + this.documents[this.currentAttachmentIndex].fileName
            );

            if (this.documents.findIndex(doc => !doc.completed) !== -1) {
              this.selectAttachment(this.documents.findIndex(doc => !doc.completed));
            } else {
              this.restartAdHocFiling();
            }
          }
        });
    } else {
      console.error('Form is not valid, cannot upload document.');
      this.keyFieldsComponent.onUploadComplete();
      this.showErrorToast('validationError');
    }
  }

  /**
   * Cancels the file filing process for the current attachment
   */
  cancelFileFiling(): void {
    this.confirmationService.confirm({
      message: '',
      key: 'deleteAdHocFileConfirmDialog',
      accept: () => {
        this.deleteDocument();
      }
    });
  }

  restartAdHocFiling(): void {
    this.documentsReceived$.next(false);
    this.autoclassificationFlag$.next(false);

    this.documents = []; //update this in the service too
    this.keyFieldsService.clearDocuments();
    this.documentIds = [];
    this.isFiling = false;
    this.currentDocumentType = undefined;
    this.formStates.clear();
    this.currentAttachmentIndex = -1; //update this in this.keyfieldsService too
    this.keyFieldsService.setCurrentAttachmentIndex(-1);
  }

  deleteDocument(): void {
    this.autoclassifierSdkService.documentManagementSdk
      .deleteDocument(this.tenantId, this.documents[this.currentAttachmentIndex].fileId)
      .then(() => {
        this.documents[this.currentAttachmentIndex].canceled = true; //update in service too
        this.keyFieldsService.patchDocuments(this.documents[this.currentAttachmentIndex], this.currentAttachmentIndex);
        if (this.documents.findIndex(doc => !doc.completed && !doc.canceled) !== -1) {
          this.selectAttachment(this.documents.findIndex(doc => !doc.completed && !doc.canceled));
        } else {
          this.restartAdHocFiling();
        }
      });
  }

  /**
   * Shows a toast message indicating that the file upload is complete
   */
  showUploadDoneToast(): void {
    this.messageService.add({
      severity: 'success',
      summary: 'uploadToastTitleWebCapture',
      detail: 'uploadToastMessageWebCapture',
      key: 'uploadDoneToast',
      life: 10000
    });
  }

  /**
   * Shows an error toast message.
   * @param {string} toastMessage - The error message to display.
   * @param {string} [toastData] - Additional data to display in the toast.
   */
  showErrorToast(toastMessage: string, toastData?: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: toastMessage,
      key: 'errorToast',
      data: toastData ? toastData : '',
      life: 10000
    });
  }

  /**
   * Sets the current document status to an empty object, cancelling the auto-classification process
   */
  cancelFileAutoClassification(index: number): void {
    this.documents[index].status = {
      documents: []
    };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.signOutSubscription.unsubscribe();
  }

  openAddDocumentsModal(): void {
    this.showAddDocumentsModal = true;
  }

  openMergeDocuments() {
    this.showMergeDocuments = true;
  }

  closeMergeDocuments() {
    this.showMergeDocuments = false;
  }

  canDeactivate(): Promise<boolean> {
    if (!this.isFiling) {
      return Promise.resolve(true);
    } else {
      return new Promise(resolve => {
        this.confirmationService.confirm({
          message: 'unsavedDataWarning',
          key: 'tabChangeDialog',
          accept: () => {
            if (this.signOutService.getSignOutState()) {
              this.signOutService.signOut();
            }
            resolve(true);
          },
          reject: () => {
            if (this.signOutService.getSignOutState()) {
              this.signOutService.setSignOutState(false);
            }
            resolve(false);
          }
        });
      });
    }
  }
}
