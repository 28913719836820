import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class AccountSettingsApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.updateSettings = async (body, options) => {
      return this.put(urls.tenants.accountSettings.v1, body, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.getSettings = async options => {
      return this.get(urls.tenants.accountSettings.v1, undefined, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.getPublicSettings = async () => {
      return this.get(`${urls.tenants.accountSettings.v1}/public`);
    };
  }

  /**
   * Update account settings for a tenant.
   * TenantId is calculated in the backend from context of the `authorized user`.
   */
}
export default new AccountSettingsApi('default');