export const tooltipTheme = {
    css: ({ dt: any }: any) => `
    .p-tooltip {
      max-width: 250px;
    }

    .p-tooltip-text {
      font-size: 14px;
    }
  }
  `
  };