<div class="batch-creation-container">
  <div class="batch-creation-header">
    <span class="header-title">{{ 'createNewBatch' | translate }}</span>
  </div>
  <div class="batch-content-columns">
    <!-- Batch Settings Column -->
    <div class="batch-settings-column column">
      <app-batch-settings
        [batchFormGroup]="batchFormGroup"
        [tenantId]="tenantId"
      >
      </app-batch-settings>
    </div>

    <!-- Upload Documents Column -->
    <div class="upload-documents-column column">
      <span class="column-header">{{ 'uploadDocuments' | translate }}
      <span class="required-text">*</span>
    </span>
      <app-file-uploader
        [tenantId]="tenantId"
        (uploadedDocumentIdsEvent)="setFileIdsToAssociate($event)"
        (uploadStatusEvent)="onUploadStatusUpdate($event)"
        aria-required="true">
      </app-file-uploader>
    </div>

    <!-- Upload Status Column -->
    <div class="upload-status-column column">
      <span class="column-header">{{ 'uploadStatus' | translate }}</span>
      <div class="upload-status-section">

        <div 
          class="upload-status-summary" 
          (click)="toggleUploadStatus()" 
          (keydown.enter)="toggleUploadStatus()" 
          (keydown.space)="toggleUploadStatus()" 
          role="button" 
          tabindex="0" 
          [attr.aria-expanded]="isExpanded" 
          [attr.aria-label]="isExpanded ? 'Collapse upload status' : 'Expand upload status' | translate"
        >
          <div class="status-summary-content">
            <div class="summary-text">
              <span class="summary-title">
                {{ 'uploading' | translate }} {{ uploadStatuses.length }} {{ 'files' | translate }}
              </span>
              <span class="summary-description">
                {{ uploadedCount() }}/{{ uploadStatuses.length }} {{ 'filesUploaded' | translate }}
              </span>
            </div>
            <i 
              class="arrow-icon" 
              [ngClass]="isExpanded ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'"
            ></i>
          </div>
        </div>
        <div
          *ngIf="isExpanded"
          class="upload-status-details"
        >
          <div
            *ngFor="let file of uploadStatuses"
            class="file-upload-detail"
          >
            <span class="file-name">{{ file.file.name }}</span>
            <span class="spinner-span">
              <ngx-spinner
                color="#006CFA"
                [name]="'uploadSpinner-' + file.index"
                bdColor="rgba(255,255,255,0.3)"
                size="small"
                type="ball-spin-clockwise"
                [fullScreen]="false"
              ></ngx-spinner>
            </span>
            <span *ngIf="file.status === 'completed'">
              <i class="success-icon fa-solid fa-circle-check"></i>
            </span>
            <span *ngIf="file.status === 'failed'">
              <i class="error-icon fa-solid fa-circle-exclamation"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Bottom Section: Batch Creation Buttons -->
  <div class="batch-creation-buttons">
    <p-button 
      class="cancel-button" 
      (click)="cancelBatchCreation()" 
      [label]="'cancel' | translate" 
      [ariaLabel]="'cancel' | translate" 
      variant="text"
      >
    </p-button>
<!-- TODO: Add validation and errors when required fields arent filled out -->
    <p-button 
      class="create-button" 
      (click)="createBatch()" 
      [label]="'create' | translate" 
      [ariaLabel]="'create' | translate" 
      [disabled]="!canCreateBatch()"
      severity="secondary"
      size="large">
    </p-button>
  </div>
</div>
