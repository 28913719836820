import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class NotificationsApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.getNotifications = async params => {
      return this.get(urls.notifications.v1, {
        params
      });
    };
    this.updateNotificationStatus = async params => {
      return this.put(`${urls.notifications.v1}/status`, {
        params
      });
    };
    this.updateNotificationIsPinned = async params => {
      const {
        notificationId,
        pinStatus
      } = params;
      return this.put(`${urls.notifications.v1}/${pinStatus}`, {
        notificationId
      });
    };
    this.markAllAsRead = async () => {
      return this.post(`${urls.notifications.v1}/status/mark-all-read`);
    };
  }

  /**
   * Get notifications
   */
}
export default new NotificationsApi('default');