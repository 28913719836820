<div
  class="ad-hoc-upload-container"
  *ngIf="!isFiling"
>
  <div
    class="ad-hoc-upload"
    *ngIf="!isFiling"
  >
    <div class="ad-hoc-upload-header">
      <span class="ad-hoc-upload-title">{{ 'documentUpload' | translate }}</span>
      <span class="ad-hoc-upload-description">{{ 'adHocUploadDescription' | translate }}</span>
    </div>
    <div class="file-uploader-container">
      <app-file-uploader
        [tenantId]="tenantId"
        (uploadedDocumentIdsEvent)="startAdHocFiling($event)"
      ></app-file-uploader>
    </div>
  </div>
</div>

@if (showMergeDocuments) {
  <div class="merge-documents-container">
    <app-merge-documents
      [attachments]="getFileSelectorAttachments()"
      [currentAttachmentIndex]="currentAttachmentIndex"
      (closeMergeEvent)="closeMergeDocuments()"
      [isLoadingFiles]="isLoadingFiles"
    >
    </app-merge-documents>
  </div>
}
<!-- Document Filing section -->
@if (!showMergeDocuments) {
<div
  class="doc-filing-container"
  [hidden]="!isFiling"
  [ngStyle]="{ display: isFiling ? 'flex' : 'none' }"
>

    <ng-container>
      <app-file-selector
        [attachments]="getFileSelectorAttachments()"
        (selectAttachmentEvent)="selectAttachment($event)"
        [currentAttachmentIndex]="currentAttachmentIndex"
        [isLoadingFiles]="isLoadingFiles"
        (openAddDocumentsEvent)="openAddDocumentsModal()"
        (openMergeDocumentsEvent)="openMergeDocuments()"
        [isAutoclassificationEnabled]="isAutoClassificationEnabled"
      >
      </app-file-selector>
      <div
        class="document-viewer-container"
        [hidden]="showUnsupportedFileMessage"
      >
        <document-viewer-widget
          [base64Content]="documents[currentAttachmentIndex]?.fileContent"
          [showClassificationButton]="false"
          (startClassification)="startAttachmentClassification()"
          (unsupportedFileType)="showUnsupportedFileMessage = true"
        ></document-viewer-widget>
      </div>
      <div
        *ngIf="showUnsupportedFileMessage"
        class="unsupported-msg-container"
      >
        <span class="unsupported-msg-text">{{ 'unsupportedFileType' | translate }}</span>
      </div>
      <div
        *ngIf="showClassificationResults"
        class="classification-results-container"
      >
        <app-classification-results
          [currentMostConfidentDocument]="currentMostConfidentDocument"
          (closeResultsEvent)="closeClassificationResults()"
        ></app-classification-results>
      </div>
      <div class="key-fields-container">
        <app-key-fields
          *ngIf="
            (isAutoClassificationEnabled && documents[currentAttachmentIndex]?.classificationStarted) ||
            (!isAutoClassificationEnabled && !isAutoclassificationFlagLoading)
          "
          [docAreas]="areas"
          [docFormGroup]="documentForm"
          [documentId]="documents[this.currentAttachmentIndex]?.fileId"
          (changeEvent)="formOnChange($event)"
          [attachmentStatus]="documents[this.currentAttachmentIndex]?.status"
          [filteredDocumentTypes]="filteredDocumentTypes"
          [currentDocumentType]="currentDocumentType"
          (uploadDocumentEvent)="uploadDocument()"
          (cancelFileFilingEvent)="cancelFileFiling()"
          [showDeleteDocumentButton]="true"
          (deleteDocumentEvent)="cancelFileFiling()"
          [isAutoClassificationEnabled]="isAutoClassificationEnabled"
          (cancelFileAutoClassificationEvent)="
            cancelFileAutoClassification(this.currentAttachmentIndex)
          "
        ></app-key-fields>
      </div>
    </ng-container>  
</div>
}
<p-toast
  position="bottom-right"
  key="uploadDoneToast"
  styleClass="upload-done-toast"
>
  <ng-template
    let-message
    pTemplate="message"
  >
    <div class="toast-message-container">
      <div class="icon-container">
        <i class="fa-solid fa-circle-check"></i>
      </div>
      <div class="message-container">
        <span class="message-summary">{{ message.summary | translate }}</span>
        <span>{{ message.detail | translate }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-toast
  position="bottom-right"
  key="errorToast"
  styleClass="error-toast"
>
  <ng-template
    let-message
    pTemplate="message"
  >
    <div class="toast-message-container">
      <div class="icon-container">
        <i class="fa-solid fa-circle-exclamation"></i>
      </div>
      <div class="message-container">
        <span class="message-summary">{{ message.summary | translate }}</span>
        <span>{{ message.detail | translate }}</span>
        <span *ngIf="message.data">{{ message.data }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
