import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, catchError, combineLatest, lastValueFrom, of, Subscription, switchMap } from 'rxjs';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';

import { AuthService } from '../../services/auth/auth.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoclassifierSdkService } from '../../services/autoclassifier-sdk/autoclassifier-sdk.service';
import { ContentApiService, Area, DocumentType, KeyField as DocumentTypeKeyField } from '../../services/content-api/content-api.service';
import { DocumentManagementService } from '../../services/document-management/document-management.service';
import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';
import { TenantApiService } from '../../services/tenant-api/tenant-api.service';

import { Attachment } from '../../models/attachment';
import { DocumentResponse } from '../../models/documentResponse';
import { ErrorResponse } from '../../models/errorResponse';
import { ExtractionResponse } from '../../models/extractionResponse';
import { KeyField, StatusResponse } from '../../models/statusResponse';
import { MappingKeyField, MapResponse } from '../../models/docTypeMapping';

import { base64ToFile, buildKeyFields } from '../../utils/documentUtils';

import { FileSelectorAttachment } from '../../components/file-selector/file-selector.component';
import { KeyFieldsComponent } from '../../components/key-fields/key-fields.component';

import { KeyFieldsService } from '../../services/key-fields/key-fields.service';

import { NgxSpinnerService } from 'ngx-spinner';

interface PrinterDocument {
  canceled?: boolean;
  classificationStarted: boolean;
  contentType: string;
  contentUri: string;
  completed: boolean;
  fileContent: string;
  fileId: string;
  fileName: string;
  mappedItems?: MapResponse['mapItems'];
  requestId?: string;
  status?: StatusResponse;
  }

declare const pendo: any;

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.scss']
})
export class PrinterComponent implements OnInit, OnDestroy {
  @ViewChild(KeyFieldsComponent) keyFieldsComponent!: KeyFieldsComponent;

  private activeKeys: Set<string> = new Set();
  private subscription = new Subscription();

  documentsReceived$ = new BehaviorSubject<boolean>(false);
  autoclassificationFlag$ = new BehaviorSubject<boolean>(false);

  isAutoClassificationEnabled: boolean = false;
  isAutoclassificationFlagLoading: boolean = true;

  tenantId: string = '';
  documents: PrinterDocument[] = [];
  documentIds: string[] = [];
  documentTypes: DocumentType[] = [];
  areas: Area[] = [];
  documentForm!: FormGroup;
  filteredDocumentTypes: DocumentType[] = [];
  currentDocumentType: DocumentType | undefined;
  formStates: Map<number, any> = new Map();
  currentAttachmentIndex: number = 0;

  authToken: string = '';

  currentMostConfidentDocument: any;
  showClassificationResults: boolean = false;
  showUnsupportedFileMessage: boolean = false;

  constructor(
    private _location: Location,
    private autoclassifierSdkService: AutoclassifierSdkService,
    private authService: AuthService,
    private contentService: ContentApiService,
    private docMngmtService: DocumentManagementService,
    private fb: FormBuilder,
    private featureFlagService: FeatureFlagService,
    public keyFieldsService: KeyFieldsService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private tenantService: TenantApiService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService
  ) {
    this.subscription.add(
      this.route.queryParams.subscribe(params => {
        this.documentIds = params['id'].split(',');
        this.authToken = params['accessToken'];
      })
    );
  }

  /**
   * Initializes the component, setting up necessary data and feature flags
   */
  ngOnInit() {
    const { key, name, tenantId } = this.authService.extractUserInfo(this.authToken);
    this.tenantId = tenantId;
    // added
    this.keyFieldsService.setTenantId(this.tenantId);        
    // end added

    pendo.initialize({
      account: {
        id: tenantId
      }
    });

    this.featureFlagService.initialize({ key, name, tenantId });

    this.subscription.add(
      this.featureFlagService
        .getFlag('autoclassification-for-virtual-printer', true)
        .subscribe(flagValue => {
          this.isAutoclassificationFlagLoading = false;
          this.isAutoClassificationEnabled = flagValue as boolean;
          this.autoclassificationFlag$.next(flagValue);
        })
    );

    this.keyFieldsService.currentDocumentType$.subscribe(documentType => {
      this.currentDocumentType = documentType;
    });
    this.keyFieldsService.documentForm$.subscribe(form => {
      this.documentForm = form || this.fb.group({}); //come back here. form should never be null really
    });
    this.keyFieldsService.formStates$.subscribe(formStates => {
      this.formStates = formStates;
    }
    );
    

    this.loadData();

    this.subscription.add(
      combineLatest([this.documentsReceived$, this.autoclassificationFlag$]).subscribe(
        ([documentsReceived, autoclassificationEnabled]) => {
          if (documentsReceived && autoclassificationEnabled) {
            this.analyzeDocuments();
          }
        }
      )
    );

    this.initializeDocumentForm();
  }

  /**
   * Initializes the document form with default values
   */
  initializeDocumentForm() {
    this.documentForm = this.fb.group({
      area: [''],
      documentTypeCode: ['']
    });
  }

  analyzeDocuments(): void {
    if (this.documents.length === 0 || !this.isAutoClassificationEnabled) {
      return;
    }

    Promise.all(this.documents.map(doc => this.analyzeDocument(doc)))
      .then(() => {})
      .catch(error => {
        console.error('Error analyzing documents', error);
      });
  }

  /**
   * Retrieves the document from the Document Management SDK
   * @param {string} documentId - The ID of the document to retrieve
   */
  getDocumentFromDocMgmtSdk(documentId: string): Promise<void> {
    return this.autoclassifierSdkService.documentManagementSdk
      .getDocument(this.tenantId, documentId)
      .then((response: DocumentResponse) => {
        const newDocument: PrinterDocument = {
          contentUri: response.contentUri,
          fileName: response.fileName,
          fileId: response.id,
          contentType: response.type,
          fileContent: '',
          completed: false,
          classificationStarted: true
        };

        return lastValueFrom(this.docMngmtService.getDocument(newDocument.contentUri)).then(
          arrayBuffer => {
            const binaryString = Array.from(new Uint8Array(arrayBuffer))
              .map(byte => String.fromCharCode(byte))
              .join('');
            const base64 = btoa(binaryString);
            newDocument.fileContent = base64;
            this.documents.push(newDocument);
            //add in service too
            this.keyFieldsService.addDocument(newDocument);
            this.cdr.detectChanges();
            this.spinner.show('fileClassificationSpinner-' + newDocument.fileName);
          }
        );
      })
      .catch((error: any) => {
        console.error('Document get Error:', error);
        this.showErrorToast('getDocumentError');
      });
  }

  /**
   * Initializes data to be used by the component
   */
  loadData(): void {
    this.authService.setAuthToken(this.authToken);
    this.tenantId = this.authService.extractTenantId(this.authToken);

    this.subscription.add(
      this.tenantService
        .getTenantUrls(this.tenantId)
        .pipe(
          switchMap(tenantUrls => {
            this.contentService.setBaseUrl(tenantUrls.contentApiUrl);
            return this.contentService.getDocumentTypes();
          }),
          switchMap(docTypes => {
            this.documentTypes = docTypes;
            //make sure to set this in the service as well
            this.keyFieldsService.setDocumentTypes(docTypes);
            return this.contentService.getAreas();
          }),
          catchError(error => {
            console.error('Error in chained requests', error);
            this.showErrorToast('dataError');
            return of([]); // Return an observable to keep the stream alive
          })
        )
        .subscribe(areas => {
          this.areas = areas;
          Promise.all(this.documentIds.map(docId => this.getDocumentFromDocMgmtSdk(docId))).then(
            () => {
              this.documentsReceived$.next(true);
            }
          );
        })
    );
  }

  /**
   * Event listener to handle changes to a document's classification request
   * @param {CustomEvent} event - The documentStatusUpdate event
   */
  @HostListener('window:documentStatusUpdate', ['$event'])
  handleDocumentStatusUpdate(event: CustomEvent) {
    const index = this.documents.findIndex(
      document => document.requestId === event.detail.requestId
    );
    if (index !== -1 && !this.documents[index].status) {
      this.documents[index].status = event.detail.status;
      //patch here
      this.keyFieldsService.patchDocuments(this.documents[index], index);
      if (
        index === this.currentAttachmentIndex &&
        this.documents[this.currentAttachmentIndex].classificationStarted
      ) {
        //this.buildFormFields();
        this.keyFieldsService.buildFormFields();
      }
    }
  }

  /**
   * Event listener to handle errors in a document's classification request
   * @param {CustomEvent} event - The documentStatusError event
   */
  @HostListener('window:documentStatusError', ['$event'])
  handleDocumentStatusError(event: CustomEvent) {
    const { requestId, errorCode } = event.detail;
    const index = this.documents.findIndex(doc => doc.requestId === requestId);

    if (index !== -1) {
      const doc = this.documents[index];

      let errorMessage;
      switch (errorCode) {
        case 'documentInvalid':
          errorMessage = 'documentInvalidError';
          break;
        default:
          errorMessage = 'analyzeError';
          break;
      }

      this.showErrorToast(errorMessage, doc.fileName);
      this.cancelFileAutoClassification(index);

      if (
        index === this.currentAttachmentIndex &&
        this.documents[this.currentAttachmentIndex].classificationStarted
      ) {
        //this.buildFormFields();
        this.keyFieldsService.buildFormFields();
      }
    }
  }

  /**
   * Handles the keydown event for both macOS and Windows platforms
   * @param {KeyboardEvent} event - The keydown event
   */
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.activeKeys.add(event.key.toLowerCase());

    const isMac = this.isMacPlatform();

    // CMD + SHIFT + A + I
    const isMacShortcutPressed =
      isMac &&
      event.metaKey &&
      event.shiftKey &&
      this.activeKeys.has('a') &&
      this.activeKeys.has('i');

    // CTRL + SHIFT + A + I
    const isWindowsShortcutPressed =
      !isMac &&
      event.ctrlKey &&
      event.shiftKey &&
      this.activeKeys.has('a') &&
      this.activeKeys.has('i');

    if (isMacShortcutPressed || isWindowsShortcutPressed) {
      event.preventDefault();
      this.openDialog();
      this.activeKeys.clear();
    }
  }

  /**
   * Handles the keyup event by removing the key from the activeKeys set
   * @param {KeyboardEvent} event - The keyup event
   */
  @HostListener('window:keyup', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    this.activeKeys.delete(e.key.toLowerCase());
  }

  /**
   * Checks if the platform is macOS
   * @returns {boolean} - Returns true if the platform is macOS, otherwise false
   */
  isMacPlatform(): boolean {
    // Use navigator.userAgentData if available (future-proof)
    if ((navigator as any).userAgentData) {
      return (navigator as any).userAgentData.platform === 'macOS';
    }

    // Fallback to user agent string check if userAgentData is not available
    return /Mac|iPhone|iPod|iPad/.test(navigator.userAgent);
  }

  /**
   * Opens the classification results dialog.
   */
  openDialog() {
    if (!this.showClassificationResults) {
      this.currentMostConfidentDocument = this.keyFieldsService.getMostConfidentDocument(
        this.documents[this.currentAttachmentIndex]
      );
    } else {
      this.currentMostConfidentDocument = null;
    }
    this.showClassificationResults = !this.showClassificationResults;
  }

  /**
   * Closes the classification results dialog.
   */
  closeClassificationResults(): void {
    this.showClassificationResults = false;
  }


  /**
   * Filters the document types list by the currently selected area
   * @param {string} areaCode - The code of the selected area
   */
  filterDocumentTypes(areaCode: string): void {
    this.filteredDocumentTypes = this.documentTypes.filter(type => type.areaCode === areaCode);
  }

  /**
   * Builds and prepopulates form fields for the current document
   */
  buildFormFields(): void {
    this.currentMostConfidentDocument = this.getMostConfidentDocument(
      this.documents[this.currentAttachmentIndex]
    );

    const savedState = this.formStates.get(this.currentAttachmentIndex);

    if (savedState && savedState.area) {
      this.initializeFormControls(savedState.area, savedState.documentTypeCode);
      this.documentForm.patchValue(savedState);
    } else {
      this.buildFormFieldsForNewDocument();
    }
  }

  /**
   * Initializes form controls with area and document type
   * @param {string} area - The selected area
   * @param {string} documentTypeCode - The selected document type code
   */
  private initializeFormControls(area: string, documentTypeCode: string): void {
    const formControls: { [key: string]: FormControl } = {
      area: new FormControl(area),
      documentTypeCode: new FormControl(documentTypeCode)
    };
    this.filterDocumentTypes(area);
    this.currentDocumentType = this.filteredDocumentTypes.find(
      type => type.code === documentTypeCode
    );

    if (this.currentDocumentType) {
      this.keyFieldsService.addKeyFieldsToFormControls(formControls);
    }

    this.documentForm = this.fb.group(formControls);
  }

  /**
   * Builds form fields for a new document when there is no saved state
   */
  private buildFormFieldsForNewDocument(): void {
    const formControls: { [key: string]: FormControl } = {
      area: new FormControl(''),
      documentTypeCode: new FormControl('')
    };

    this.currentDocumentType = undefined;
    const attachment = this.documents[this.currentAttachmentIndex];

    if (
      attachment.status &&
      Array.isArray(attachment.status.documents) &&
      attachment.status.documents.length > 0
    ) {
      const document = this.getMostConfidentDocument(attachment);

      if (document) {
        const keyFields = this.keyFieldsService.extractKeyFields(document);
        this.keyFieldsService.mapKeyFields(document.classifyInfo?.documentType as string, keyFields).then(
          response => {
            if (response && response.mapItems.length > 0) {
              const mapItem = response.mapItems[0];

              attachment.mappedItems = response.mapItems;

              const docTypeCode = mapItem.docTypeCode;
              this.currentDocumentType = this.getDocumentTypeByCode(docTypeCode);
              this.populateFormControls(formControls, docTypeCode, mapItem.keyFields);

              this.documentForm = this.fb.group(formControls);

              this.keyFieldsService.handleLookupFields(mapItem.keyFields);
            }
          }
        );
      }
    } else {
      // Create empty form controls if no status is available
      this.initializeFormControls('', '');
    }
  }

  /**
   * Gets the document with the highest confidence from the list of documents in an attachment
   * @param {PrinterDocument} attachment - The attachment containing the documents
   * @returns {any} - The document with the highest confidence
   */
  private getMostConfidentDocument(attachment: PrinterDocument): any {
    if ((attachment.status?.documents.length ?? 0) < 1) {
      return null;
    }
    return attachment.status?.documents.reduce((prev, current) => {
      if (prev.classifyInfo && current.classifyInfo) {
        return prev.classifyInfo.confidence > current.classifyInfo.confidence ? prev : current;
      }
      return prev;
    });
  }

  /**
   * Retrieves a document type by its code
   * @param {string | undefined} docTypeCode - The document type code
   * @returns {DocumentType | undefined} - The matching document type, if found
   */
  private getDocumentTypeByCode(docTypeCode: string | undefined): DocumentType | undefined {
    return this.documentTypes.find(type => type.code === docTypeCode);
  }

  /**
   * Populates form controls with values based on the selected document type and key fields
   * @param {Object} formControls - The form controls to populate
   * @param {string | undefined} docTypeCode - The document type code
   * @param {MappingKeyField[]} mappedKeyFields - The key fields to populate the form controls with
   */
  private populateFormControls(
    formControls: { [key: string]: FormControl },
    docTypeCode: string | undefined,
    mappedKeyFields: MappingKeyField[]
  ): void {
    if (this.currentDocumentType) {
      const areaCode = this.currentDocumentType.areaCode;
      this.filterDocumentTypes(areaCode);
      (formControls['area'] as FormControl).setValue(areaCode);
      (formControls['documentTypeCode'] as FormControl).setValue(docTypeCode);

      this.keyFieldsService.addKeyFieldsToFormControls(formControls);

      mappedKeyFields.forEach(mappedField => {
        const formControl = formControls[mappedField.key];
        if (formControl) {
          if (
            this.currentDocumentType?.keyfields.find(kf => kf.code === mappedField.key)
              ?.dataType === 'Date'
          ) {
            const date = new Date(mappedField.value);
            if (!isNaN(date.getDate())) {
              formControl.setValue(new Date(mappedField.value));
            }
          } else {
            formControl.setValue(mappedField.value);
          }
        }
      });

      this.documentForm.patchValue(this.documentForm.getRawValue(), { emitEvent: true });
    }
  }


  /**
   * Applies validation and default rules to a form control
   * @param {DocumentTypeKeyField} field - The field metadata
   * @param {Object} formControls - The form controls to which rules should be applied
   */
  applyFieldRules(field: DocumentTypeKeyField, formControls: { [key: string]: FormControl }) {
    if (field.default) {
      if (field.dataType === 'Date' && field.default === 'CurrentDate') {
        formControls[field.code].setValue(new Date());
      } else {
        formControls[field.code].setValue(field.default);
      }
    }

    if (field.required) {
      formControls[field.code].setValidators([Validators.required]);
    }

    if (field.maxLength) {
      formControls[field.code].setValidators([Validators.maxLength(field.maxLength)]);
    }

    if (field.textPattern) {
      formControls[field.code].setValidators([Validators.pattern(field.textPattern)]);
    }

    if (field.minValue) {
      formControls[field.code].setValidators([Validators.min(field.minValue)]);
    }
  }


  /**
   * Analyzes a document using the autoclassifier SDK
   * @param {PrinterDocument} document - The document to analyze
   * @returns {Promise<void>} - A promise that resolves when the analysis is complete
   */
  async analyzeDocument(document: PrinterDocument): Promise<void> {
    const file = base64ToFile(document.fileContent, document.fileName, 'application/pdf');
    this.autoclassifierSdkService.documentAnalysisSdk
      .extractDocumentFromUpload(file, true)
      .then((response: ExtractionResponse) => {
        document.requestId = response.requestId;
      })
      .catch((error: ErrorResponse) => {
        console.error('Error analyzing document:', error);
        this.showErrorToast('analyzeError', document.fileName);
      });
  }

  /**
   * Selects an attachment and builds form fields for that attachment
   * @param {number} index - The index of the selected attachment
   */
  selectAttachment(index: number): void {
    this.showUnsupportedFileMessage = false;
    if (index >= 0 && index < this.documents.length) {
      this.formStates.set(this.currentAttachmentIndex, this.documentForm.value);
      this.currentAttachmentIndex = index;
      //set this in the service as well
      this.keyFieldsService.setCurrentAttachmentIndex(index);

      if (
        this.documents[this.currentAttachmentIndex].classificationStarted ||
        !this.isAutoClassificationEnabled
      ) {
        //this.buildFormFields();
        this.keyFieldsService.buildFormFields();
      } else {
        this.initializeDocumentForm(); // Initialize the form if no classification has started
      }
    }
  }

  /**
   * Performs a lookup on key fields that are of type 'Lookup'
   * @param {MappingKeyField[]} mappedKeyFields - The mapped key field data to use for the lookup
   */
  setLookup(mappedKeyFields: MappingKeyField[]): void {
    this.currentDocumentType?.keyfields
      .filter(keyfield => keyfield.dataType === 'Lookup')
      .forEach(keyfield => {
        if (keyfield.lookupType?.namingFields.some(namingField => namingField.required)) {
          let lookupSearchTerm = '';
          keyfield.lookupType.namingFields
            .filter(namingField => namingField.required)
            .forEach(namingField => {
              const matchingField = mappedKeyFields?.find(
                mappedField => mappedField.key === `${keyfield.code}.${namingField.fieldCode}`
              );
              if (matchingField) {
                lookupSearchTerm += `${matchingField.value} `;
              }
            });

          lookupSearchTerm = lookupSearchTerm.trim();
          if (
            lookupSearchTerm &&
            this.documents[this.currentAttachmentIndex].classificationStarted
          ) {
            this.keyFieldsService.lookupUsers(
              keyfield.code,
              keyfield.lookupType.code,
              lookupSearchTerm,
              true
            );
          }
        }
      });
  }

  /**
   * Starts the classification process for the selected attachment
   */
  startAttachmentClassification(): void {
    this.documents[this.currentAttachmentIndex].classificationStarted = true;
    //set this in the service here too
    this.keyFieldsService.patchDocuments(this.documents[this.currentAttachmentIndex], this.currentAttachmentIndex);
    if (this.documents[this.currentAttachmentIndex].status) {
      //this.buildFormFields();
      this.keyFieldsService.buildFormFields();
    }
  }

  /**
   * Uploads an attachment with type, area, and key field information to Softdocs Etrieve Content.
   */
  uploadDocument(): void {
    if (this.documentForm?.valid) {
      const formData = buildKeyFields(
        this.documentForm.value,
        this.currentDocumentType as DocumentType
      );

      const multiValueFields = this.currentDocumentType?.keyfields.filter(kf => kf.multivalue);

      if (multiValueFields) {
        multiValueFields.forEach(multiValueField => {
          const multiValueFieldKey = `${multiValueField.code}-${this.currentDocumentType?.code}-${this.documents[this.currentAttachmentIndex]?.fileId}`;

          if (this.keyFieldsComponent.multiValueFields[multiValueFieldKey]) {
            this.keyFieldsComponent.multiValueFields[multiValueFieldKey].forEach(value => {
              formData.push({
                fieldCode: multiValueField.code,
                value: value
              });
            });
          }
        });
      }

      const attachment: Attachment = {
        content: {
          content: this.documents[this.currentAttachmentIndex].fileContent,
          format: 'base64'
        },
        attachmentType: this.documents[this.currentAttachmentIndex].contentType,
        requestId: this.documents[this.currentAttachmentIndex].requestId,
        name: this.documents[this.currentAttachmentIndex].fileName,
        contentType: this.documents[this.currentAttachmentIndex].contentType,
        id: '',
        isInline: false,
        size: 0
      };

      this.contentService
        .uploadDocument(
          attachment,
          this.documentForm.controls['area'].value as string,
          this.documentForm.controls['documentTypeCode'].value as string,
          formData
        )
        .subscribe({
          error: error => {
            console.error('Failed to upload document', error);
            this.showErrorToast('uploadError');
            this.keyFieldsComponent.onUploadComplete();
          },
          complete: () => {
            this.autoclassifierSdkService.documentManagementSdk
              .deleteDocument(this.tenantId, this.documents[this.currentAttachmentIndex].fileId)
              .then(() => {
                console.log(
                  'Document deleted;',
                  this.documents[this.currentAttachmentIndex].fileId
                );
              });
            this.showUploadDoneToast();
            this.keyFieldsComponent.onUploadComplete();
            this.documents[this.currentAttachmentIndex].completed = true;
            //update this in the service too
            this.keyFieldsService.patchDocuments(this.documents[this.currentAttachmentIndex], this.currentAttachmentIndex);
            if (this.documents.findIndex(doc => !doc.completed) !== -1) {
              this.selectAttachment(this.documents.findIndex(doc => !doc.completed));
            } else {
              this.redirectBack();
            }
          }
        });
    } else {
      console.error('Form is not valid, cannot upload document.');
      this.keyFieldsComponent.onUploadComplete();
      this.showErrorToast('validationError');
    }
  }

  /**
   * Cancels the file filing process for the current attachment
   */
  cancelFileFiling(): void {
    this.confirmationService.confirm({
      message: '',
      key: 'deletePrinterFileConfirmDialog',
      accept: () => {
        this.autoclassifierSdkService.documentManagementSdk
          .deleteDocument(this.tenantId, this.documents[this.currentAttachmentIndex].fileId)
          .then(() => {
            console.log('Document deleted;', this.documents[this.currentAttachmentIndex].fileId);
            this.documents[this.currentAttachmentIndex].canceled = true;
            if (this.documents?.findIndex(doc => !doc.completed && !doc.canceled) !== -1) {
              this.selectAttachment(this.documents.findIndex(doc => !doc.completed && !doc.canceled));
            } else {
              this.redirectBack();
            }
          });
      }
    });
  }

  /**
   * Redirects the user back to the printer application
   */
  redirectBack(): void {
    setTimeout(() => {
      this._location.back();
    }, 2000); // Wait 2 seconds before navigating back
  }

  /**
   * Retrieves the attachments for the file selector
   * @returns {FileSelectorAttachment[]} - An array of file selector attachments
   */
  getFileSelectorAttachments(): FileSelectorAttachment[] {
    const attachments = this.documents.map(
      doc =>
        ({
          name: doc.fileName,
          completed: doc.completed,
          status: doc.status as StatusResponse,
          classificationStarted: doc.classificationStarted,
          canceled: doc.canceled
        }) as FileSelectorAttachment
    );

    return attachments;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Shows a toast message indicating that the file upload is complete.
   */
  showUploadDoneToast(): void {
    this.messageService.add({
      severity: 'success',
      summary: 'uploadToastTitlePrinter',
      detail: 'uploadToastMessagePrinter',
      key: 'uploadDoneToast',
      life: 10000
    });
  }

  /**
   * Shows an error toast message.
   * @param {string} toastMessage - The error message to display.
   * @param {string} [toastData] - Additional data to display in the toast.
   */
  showErrorToast(toastMessage: string, toastData?: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: toastMessage,
      key: 'errorToast',
      data: toastData ? toastData : '',
      life: 10000
    });
  }

  /**
   * Sets the current document status to an empty object, cancelling the auto-classification process
   */
  cancelFileAutoClassification(index: number): void {
    this.documents[index].status = {
      documents: []
    };
    this.keyFieldsService.patchDocuments(this.documents[index], index);
  }

  
}
