import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';

declare const Softdocs: any;

@Injectable({
  providedIn: 'root'
})
export class AutoclassifierSdkService implements OnDestroy {
  private _batchSdk: any;
  private _documentAnalysisSdk: any;
  private _documentManagementSdk: any;
  private _docTypeMappingSdk: any;
  private _usersSdk: any;

  constructor() {
    // Instantiate all SDKs once
    this._batchSdk = new Softdocs.BatchSdk(environment.urls.autoclassifierApi);

    this._documentAnalysisSdk = new Softdocs.DocumentAnalysisSdk(
      environment.urls.autoclassifierApi
    );

    this._documentManagementSdk = new Softdocs.DocumentManagementSdk(
      environment.urls.autoclassifierApi
    );

    this._docTypeMappingSdk = new Softdocs.DocTypeMappingSdk(environment.urls.autoclassifierApi);

    this._usersSdk = new Softdocs.UsersSdk(environment.urls.autoclassifierApi);
  }

  // Expose getters for each SDK
  get batchSdk(): any {
    return this._batchSdk;
  }

  get documentAnalysisSdk(): any {
    return this._documentAnalysisSdk;
  }

  get documentManagementSdk(): any {
    return this._documentManagementSdk;
  }

  get docTypeMappingSdk(): any {
    return this._docTypeMappingSdk;
  }

  get usersSdk(): any {
    return this._usersSdk;
  }

  // Stop the SignalR connection when the service is destroyed
  ngOnDestroy(): void {
    if (this._documentAnalysisSdk && this._documentAnalysisSdk.client) {
      this._documentAnalysisSdk.client
        .stop()
        .catch((error: any) => console.error('Error stopping SignalR connection:', error));
    }
  }
}
