<url-verification
  *ngIf="!isAccessFlagLoading && !isUrlVerified; else webCaptureContainer"
  [hasAccess]="isAccessEnabled"
  [noAccessMessage]="featureFlagErrorText | translate"
  (successfulVerificationEvent)="onSuccessfulVerification()"
  (mustSignOutEvent)="signOut()"
></url-verification>

<ng-template #webCaptureContainer>
  <div
    class="container"
    *ngIf="!isLoading && user"
  >
    <div class="header-container">
      <img
        src="../../../assets/etrieve.svg"
        class="etrieve-logo"
        alt="Etrieve Web Capture Logo"
      />
      <div class="logo-divider"></div>
      <h1 class="header-title">
        {{ 'webCapture' | translate }}
      </h1>
      <div class="nav-buttons">
        <p-button 
          class="pendo-icon header-button" 
          [ariaLabel]="'resourceCenter' | translate"
          [pTooltip]="'resourceCenter' | translate"
          aria-haspopup="menu"
          tooltipPosition="top"
          variant="text"
          severity="contrast"
          icon="fa-solid fa-circle-question">
        </p-button>

        <p-button 
          class="header-button"
          (click)="toggleShowSettings()" 
          [ariaLabel]="'settings' | translate"
          [pTooltip]="'settings' | translate"
          [attr.aria-haspopup]="'true'"
          [attr.aria-expanded]="showSettings ? 'true' : 'false'"
          tooltipPosition="top"
          [ngClass]="{ 'active': showSettings }"
          variant="text"
          severity="contrast"
          icon="fa-solid fa-gear">
        </p-button>

        <div
          class="settings-menu"
          *ngIf="showSettings"
          (focusout)="checkNavButtonsFocus($event)"
          (keydown)="handleKeydown($event)"
          tabindex="-1"
          role="menu"
          aria-labelledby="settings-button"
          [attr.aria-expanded]="showSettings ? 'true' : 'false'"
        >
            <ul class="settings-list" role="none">
              <li class="settings-list-items" role="none">
                <a
                  href="#"
                  (click)="changeContentUrl(); $event.preventDefault()"
                  [attr.aria-label]="'contentUrl' | translate"
                  class="settings-option"
                  role="menuitem"
                  [attr.tabindex]="showSettings ? 0 : -1"
                >
                  <span>
                    <i class="fa-solid fa-browser" aria-hidden="true"></i>
                    {{ 'contentUrl' | translate }}
                  </span>
                </a>
              </li>
              <li class="settings-list-items" role="none">
                <a
                  href="#"
                  (click)="showAboutModal = true; showSettings = false; $event.preventDefault()"
                  [attr.aria-label]="'about' | translate"
                  class="settings-option"
                  role="menuitem"
                  [attr.tabindex]="showSettings ? 0 : -1"
                >
                  <span>
                    <i class="fa-solid fa-circle-info" aria-hidden="true"></i>
                    {{ 'about' | translate }}
                  </span>
                </a>
              </li>
              <hr class="separator-line"/>
              <li class="user-name settings-list-items" role="none">
                {{ user.customClaims.display_name }}
              </li>
              <hr class="separator-line"/>
              <li class="settings-list-items" role="none">
                <a
                  href="#"
                  (click)="signOut(); $event.preventDefault()"
                  [attr.aria-label]="'signOut' | translate"
                  class="settings-option"
                  role="menuitem"
                  [attr.tabindex]="showSettings ? 0 : -1"
                >
                  <span>
                    <i class="fa-solid fa-right-from-bracket" aria-hidden="true"></i>
                    {{ 'signOut' | translate }}
                  </span>
                </a>
              </li>
            </ul>
          </div>

      </div>
    </div>

    <!-- Tab buttons for Ad hoc and Batch Upload -->
    <p-tabs [value]="currentTab">
      <p-tablist>
        <p-tab class="tab-item" value="" routerLink="">
          <i class="fa-solid fa-file"></i>
          <span>{{ 'documentUpload' | translate }}</span>
        </p-tab>
        <p-tab value="batches" routerLink="batches">
          <i class="fa-solid fa-rectangle-history-circle-plus"></i>
          <span>{{ 'documentBatching' | translate }}</span>
        </p-tab>
      </p-tablist>
    </p-tabs>
    <div class="router-container">
      <router-outlet></router-outlet>
    </div>
  </div>

  <p-dialog
    [header]="'about' | translate"
    [modal]="true"
    [(visible)]="showAboutModal"
    [style]="{ width: '680px' }"
    class="about-dialog"
    [draggable]="false"
    [resizable]="false"
    [closeAriaLabel]="'close' | translate"
  >
    <p>
      {{ 'aboutHeader' | translate }}
    </p>
    <span class="title">
      {{ 'conformanceStatus' | translate }}
    </span>
    <p>
      <a
        href="https://www.w3.org/WAI/standards-guidelines/wcag/"
        target="_blank"
        >{{ 'wcagLink' | translate }}</a
      >
      {{ 'conformanceStatusText' | translate }}
      <a
        href="https://www.softdocs.com/trust/"
        target="_blank"
        >{{ 'vpatLink' | translate }}</a
      >
    </p>
    <hr class="separator-line" />
    <span class="title">
      {{ 'feedback' | translate }}
    </span>
    <p>
      {{ 'feedbackText' | translate }}
    </p>
    <ul>
      <li>
        {{ 'feedbackPhone' | translate }}
      </li>
      <li>
        {{ 'feedbackEmail' | translate }}
      </li>
      <li>
        {{ 'feedbackPostal' | translate }}
      </li>
    </ul>
    <p>
      {{ 'feedbackEnd' | translate }}
    </p>
    <hr class="separator-line" />
    <div class="about-buttons">
      <p-button 
        label="{{ 'close' | translate }}" 
        (click)="showAboutModal = false" 
        [attr.aria-label]="'close' | translate"  
        class="close-button">
      </p-button>
    </div>
  </p-dialog>

  <p-confirmDialog
    #tabChangeDialog
    key="tabChangeDialog"
  >
    <ng-template
      pTemplate="headless"
      let-message
    >
      <div class="confirm-container">
        <div>
          <p>{{ message.message | translate }}</p>
        </div>
        <div class="buttons-container">
          <p-button 
            class="cancel-button" 
            (click)="tabChangeDialog.onReject()" 
            [ariaLabel]="'cancel' | translate" 
            [label]="'cancel' | translate" 
            variant="text">
          </p-button>

          <p-button 
            class="confirm-button" 
            (click)="tabChangeDialog.onAccept()" 
            [ariaLabel]="'confirm' | translate" 
            [label]="'confirm' | translate">
          </p-button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>

  <p-confirmDialog
    #closeBatchDialog
    key="closeBatchDialog"
  >
    <ng-template
      pTemplate="headless"
      let-message
    >
      <div class="confirm-container">
        <div>
          <p>{{ message.message | translate }}</p>
        </div>
        <div class="buttons-container">
          <p-button 
            class="cancel-button" 
            (click)="closeBatchDialog.onReject()" 
            [ariaLabel]="'cancel' | translate" 
            [label]="'cancel' | translate" 
            variant="text">
          </p-button>
        
          <p-button 
            class="confirm-button" 
            (click)="closeBatchDialog.onAccept()" 
            [ariaLabel]="'confirm' | translate" 
            [label]="'confirm' | translate" 
            >
          </p-button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>

  
  <!-- Canceling Merge Dialog -->
  <p-confirmDialog
    #closeMergeDialog
    key="closeMergeDialog"
  >
    <ng-template
      pTemplate="headless"
      let-message
    >
      <div class="confirm-container">
        <div>
          <p>{{ message.message | translate }}</p>
        </div>
        <div class="buttons-container">
          <p-button 
            class="cancel-button" 
            (click)="closeMergeDialog.onReject()" 
            [ariaLabel]="'cancel' | translate" 
            [label]="'cancel' | translate" 
            variant="text">
          </p-button>
        
          <p-button 
            class="confirm-button" 
            (click)="closeMergeDialog.onAccept()" 
            [ariaLabel]="'confirm' | translate" 
            [label]="'confirm' | translate" 
            >
          </p-button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>  

  <p-confirmDialog
    #deleteBatchConfirmDialog
    key="deleteBatchConfirmDialog"
    [header]="'warning' | translate"
  >
    <ng-template
      pTemplate="message"
      let-message
    >
      <div class="delete-confirm-container">
        <div class="">
          <p>{{ 'confirmDeleteBatchMessage' | translate }}</p>
          <p>{{ 'confirmDeleteBatchMessage2' | translate }}</p>
        </div>
      </div>
    </ng-template>
    <ng-template
      pTemplate="footer"
      let-footer
    >
      <div class="delete-confirm-buttons-container">
        <p-button 
          class="cancel-button" 
          (click)="deleteBatchConfirmDialog.onReject()" 
          [label]="'cancel' | translate" 
          variant="text">
        </p-button>

        <p-button 
          class="confirm-button" 
          (click)="deleteBatchConfirmDialog.onAccept()" 
          [label]="'deleteBatch' | translate" 
          severity="danger"
          variant="outlined">
        </p-button>
      </div>
    </ng-template>
  </p-confirmDialog>

  <p-confirmDialog
    #deleteBatchFileConfirmDialog
    key="deleteBatchFileConfirmDialog"
    [header]="'warning' | translate"
  >
    <ng-template
      pTemplate="message"
      let-message
    >
      <div class="delete-confirm-container">
        <div class="">
          <p>{{ 'confirmDeleteBatchFileMessage' | translate }}</p>
          <p>{{ 'confirmDeleteBatchFileMessage2' | translate }}</p>
        </div>
      </div>
    </ng-template>
    <ng-template
      pTemplate="footer"
      let-footer
    >
      <div class="delete-confirm-buttons-container">
        <p-button 
          class="cancel-button" 
          (click)="deleteBatchFileConfirmDialog.onReject()" 
          [label]="'cancel' | translate" 
          variant="text">
        </p-button>
      
        <p-button 
          class="confirm-button" 
          (click)="deleteBatchFileConfirmDialog.onAccept()" 
          [label]="'deleteDocument' | translate" 
          severity="danger"
          variant="outlined">
        </p-button>      
      </div>
    </ng-template>
  </p-confirmDialog>

  <p-confirmDialog
    #deleteAdHocFileConfirmDialog
    key="deleteAdHocFileConfirmDialog"
    [header]="'warning' | translate"
  >
    <ng-template
      pTemplate="message"
      let-message
    >
      <div class="delete-confirm-container">
        <div class="">
          <p>{{ 'confirmDeleteAdHocFileMessage' | translate }}</p>
          <p>{{ 'confirmDeleteAdHocFileMessage2' | translate }}</p>
        </div>
      </div>
    </ng-template>
    <ng-template
      pTemplate="footer"
      let-footer
    >
      <div class="delete-confirm-buttons-container">
        <p-button 
          class="cancel-button" 
          (click)="deleteAdHocFileConfirmDialog.onReject()" 
          [label]="'cancel' | translate" 
          variant="text">
        </p-button>
      
        <p-button 
          class="confirm-button" 
          (click)="deleteAdHocFileConfirmDialog.onAccept()" 
          [label]="'deleteDocument' | translate" 
          severity="danger"
          variant="outlined">
        </p-button>
      </div>
    </ng-template>
  </p-confirmDialog>
</ng-template>
