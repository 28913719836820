<div class="container" cdkDropListGroup>
    <aside class="sidebar">
        <div class="head-label"> {{ 'mergeDocuments' | translate }} </div>
        <div id="docList" class="document-list">
            <div 
                *ngFor="let file of attachments; trackBy: trackByIndex" 
                class="document-item" 
            >
              <p-checkbox
                [inputId]="file.id"
                [(ngModel)]="file.selected"
                [binary]="true"
                (change)="toggleFileSelection(file, $event)"
              ></p-checkbox>
              <label
                [for]="file.id"
                class="file-name-label"
                [pTooltip]="file.name"
                tooltipPosition="right"
                showDelay="500"
                hideDelay="300"
                [tooltipDisabled]="!isTextOverflowing(labelRef)"
                #labelRef
              >
                {{ file.name }}
              </label>
            </div>
          </div>
    </aside>
    <div class="workspace">
        <div class="toolbar">
            <div class="merge-toolbar-right">
                <p-button (onClick)="closeMerge()" [label]="'cancel' | translate" variant="text" />
                <p-button 
                    [label]="'mergeDocuments' | translate" 
                    severity="secondary"
                    [disabled]="selectedFiles.length < 2"
                    (onClick)="openMergeDialog()"
                />
            </div>
        </div>
        <div 
            class="merge-container" 
            cdkDropList 
            id="mergeList"
            [cdkDropListData]="selectedFiles" 
            (cdkDropListDropped)="drop($event)">

            <div *ngIf="selectedFiles.length === 0" class="no-files-message">
                {{ 'emptyMergeMessage' | translate }}
            </div>
            
            <div *ngFor="let file of selectedFiles; let i = index" cdkDrag cdkDragLockAxis="y">
                <!-- Custom Drag Preview -->
                <ng-container *cdkDragPreview>
                    <p-card>
                        <p-button
                            class="drag-button"
                            [ariaLabel]="'moveDocument' | translate"
                            icon="fa-regular fa-grip-dots-vertical"
                            [rounded]="true"
                            severity="contrast"
                            variant="text">
                        </p-button>
                        <span class="file-name-preview">{{ file.name }}</span>
                    </p-card>
                </ng-container>
                <p-card>
                    <div class="merge-item" [ngClass]="{ 'keyboard-move-active': isMoveActive && activeItemIndex === i }">
                        <div *cdkDragPlaceholder class="drag-line-placeholder"></div>
                        <p-button
                            class="drag-button move-document-button"
                            [ngClass]="{ 'no-focus-visible': isMoveActive }"
                            [ariaLabel]="'moveDocument' | translate"
                            icon="fa-regular fa-grip-dots-vertical"
                            [rounded]="true"
                            severity="contrast"
                            variant="text"
                            cdkDragHandle
                            (keydown)="trapFocus($event, i)"
                            (keyup)="moveItem($event, i)">
                        </p-button>
            
                        <span class="file-name">{{ file.name }}</span>
            
                        <p-button
                            (click)="removeFile(file)"
                            [ariaLabel]="'removeDocument' | translate"
                            icon="fa-solid fa-circle-x"
                            severity="contrast"
                            variant="text"
                            size="small"
                            [rounded]="true">
                        </p-button>
                    </div>
                </p-card>
            </div>
        </div>
    </div>
    <p-dialog
        header="{{ 'mergeDocuments' | translate }}"
        [(visible)]="isDialogVisible"
        [modal]="true"
        [closable]="true"
        [style]="{ width: '50vw' }"
        [resizable]="false"
        (onHide)="closeMergeDialog()"
        >
        <p class="dialog-content">{{ 'mergeDialogMessage' | translate }}</p>
        <p class="confirm-message">{{ 'confirmMergeMessage' | translate }}</p>
        <div class="dialog-footer">
            <p-button
                [label]="'cancel' | translate"
                (onClick)="closeMergeDialog()"
                variant="text"
            >
            </p-button>
            <p-button
                [label]="'confirm' | translate"
                (onClick)="closeMergeDialog()"
            >
            </p-button>
        </div>
    </p-dialog>
</div>
