import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-in-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Output() changeContentUrlEvent = new EventEmitter();
  @Input() includeSettings = true;

  showSettings = false;
  isSettingsOpening = false;

  constructor(private router: Router) {}

  @HostListener('document:click')
  public onClick(): void {
    if (!this.isSettingsOpening) {
      this.showSettings = false;
    } else {
      this.isSettingsOpening = false;
    }
  }

  isPrinter(): boolean {
    return this.router.url.includes('/printer');
  }

  changeContentUrl(): void {
    this.changeContentUrlEvent.emit();
  }

  toggleShowSettings(): void {
    this.isSettingsOpening = !this.showSettings ? true : false;
    this.showSettings = !this.showSettings;
  }

    checkNavButtonsFocus(event: FocusEvent): void {
      if (!(event.relatedTarget as HTMLInputElement)?.classList.contains('settings-option')) {
        this.showSettings = false;
      }
    }
  
    handleKeydown(event: KeyboardEvent): void {
      if (event.key === 'Escape') {
        this.showSettings = false;
        event.preventDefault();
      }
    }
}
