<div class="container">
  <div class="tabs-container">
    <button
      *ngIf="showAvailableList()"
      class="tab"
      [ngClass]="{ 'active-tab': currentTab === 'available' }"
      (click)="filterByTab('available')"
      [attr.aria-label]="'available' | translate"
    >
      {{ 'available' | translate }}
    </button>
    <!-- Rest of buttons might be used in the future
    <button
      class="tab"
      [ngClass]="{ 'active-tab': currentTab === 'draft' }"
      (click)="filterByTab('draft')"
    >
      Drafts
    </button>
    <button
      class="tab"
      [ngClass]="{ 'active-tab': currentTab === 'deleted' }"
      (click)="filterByTab('deleted')"
    >
      Deleted
    </button>-->
    <hr
      *ngIf="showCompletedList() && showAvailableList()"
      class="separator"
    />
    <button
      *ngIf="showCompletedList()"
      class="tab"
      [ngClass]="{ 'active-tab': currentTab === 'completed' }"
      (click)="filterByTab('completed')"
      [attr.aria-label]="'completed' | translate"
    >
      {{ 'completed' | translate }}
    </button>
    <hr
      *ngIf="showCheckedOutList()"
      class="separator"
    />
    <button
      *ngIf="showCheckedOutList()"
      class="tab"
      [ngClass]="{ 'active-tab': currentTab === 'checked_out' }"
      (click)="filterByTab('checked_out')"
      [attr.aria-label]="'checkedOut' | translate"
    >
      {{ 'checkedOut' | translate }}
    </button>
  </div>

  <div
    #tableContainer
    class="table-container"
  >

  <p-table 
    #dt
    [value]="batches"
    [columns]="cols" 
    [scrollable]="true"
    sortMode="multiple"
    [resizableColumns]="true"
    scrollHeight="flex"
    class="batch-table">
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" pSortableColumn="{{ col.field }}" pResizableColumn>
          {{ col.header | translate }}
          <span class="custom-sort-icon">
            <i class="sort-icon fa-duotone fa-solid fa-sort-up" *ngIf="getSortOrder(col.field) === 1"></i>
            <i class="sort-icon fa-duotone fa-solid fa-sort-down" *ngIf="getSortOrder(col.field) === -1"></i>
          </span>
        </th>
      </tr>
    </ng-template>
    
    <ng-template pTemplate="body" let-batch let-i="rowIndex">
      <tr class="batch-row">
        <td *ngFor="let col of cols">
          <ng-container [ngSwitch]="col.field">
            <ng-container *ngSwitchCase="'status'">
              <span [ngClass]="getStatusBadgeClass(batch.status)">
                {{ batch.status ?? '' | translate }}
              </span>
            </ng-container>
            <ng-container *ngSwitchCase="'filingProgress'">
              {{ getFilingProgress(batch) }}
            </ng-container>
            <ng-container *ngSwitchCase="'batchId'">
              <a 
                class="batch-id" 
                (click)="openBatch($event, batch)" 
                (keyup.enter)="openBatch($event, batch)" 
                [ngClass]="{ disabled: !canOpenBatch(batch) || currentTab === 'checked_out' }" 
                [attr.tabindex]="currentTab === 'checked_out' ? null : 0"
                [attr.href]="currentTab === 'checked_out' ? null : getBatchUrl(batch)"
              >
                {{ getFieldValue(batch, col.field) }}
              </a>
            </ng-container>
            <ng-container *ngSwitchCase="'createdAt'">
              <span class="creation-date">
                {{ getFieldValue(batch, col.field) | date: 'MM/dd/yyyy HH:mm a' }}
              </span>
            </ng-container>
            <ng-container *ngSwitchCase="'settings'">
                <p-button 
                  *ngIf="userPrivilegesService.canPerformAction('OVERRIDE_CHECKED_OUT_BATCH', batch.department) | async"
                  class="settings-button"
                  [label]="'checkIn' | translate"
                  [attr.aria-label]="'open' | translate"
                  (click)="checkInBatch(batch.batchId ?? '')"
                  variant="outlined"
                  size="small">
                </p-button>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ getFieldValue(batch, col.field) }}
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>

    <div
      *ngIf="isLoading"
      class="loading-indicator"
    >
      <p>{{ 'loading' | translate }}</p>
    </div>
  </div>
</div>
