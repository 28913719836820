import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class DirectoryApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.getConfigs = async () => {
      return this.get(`${urls.directory.v1}`);
    };
    this.updateConfiguration = async (id, body) => {
      return this.patch(`${urls.directory.v1}/${id}`, body);
    };
    this.createConfiguration = async body => {
      return this.post(`${urls.directory.v1}`, body);
    };
    this.deleteConfiguration = async id => {
      return this.delete(`${urls.directory.v1}/${id}`);
    };
    this.countConfigurationUsers = async id => {
      return this.get(`${urls.directory.temp}/${id}/Users/count`);
    };
  }

  /**
   * Returns all SCIM2 configurations
   *
   * ``authorized user``
   */
}
export default new DirectoryApi('default');