export const inputTextTheme = {
    colorScheme: {
      light: {
        disabledColor: '{sd.text.readonly}',
        disabled: {
          background: '{sd.backgrounds.readonly}'
        },
      }
    }
  };
