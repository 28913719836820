import { Injectable, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContentApiService, DocumentType, KeyField as DocumentTypeKeyField } from '../content-api/content-api.service';
import { MappingKeyField, MapResponse } from '../../models/docTypeMapping';
import { KeyField, StatusResponse } from '../../models/statusResponse';
import { ErrorResponse } from '../../models/errorResponse';

// Environment Variables
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { DocumentManagementService } from '../document-management/document-management.service';
import { Attachment } from '../../models/attachment';

interface Document {
  contentUri: string;
  fileName: string;
  fileId: string;
  fileContent: string;
  contentType: string;
  requestId?: string;
  status?: StatusResponse;
  completed: boolean;
  classificationStarted: boolean;
  canceled?: boolean;
  mappedItems?: MapResponse['mapItems'];
}

interface LookupResponse {
  name: string;
  lookupTypeCode: string;
  fieldValues: { fieldCode: string; value: string }[];
}

declare const Softdocs: any;

@Injectable({
  providedIn: 'root'
})
export class KeyFieldsService {
  docTypeMappingSdk = new Softdocs.DocTypeMappingSdk(environment.urls.autoclassifierApi);
  private documentFormSubject = new BehaviorSubject<FormGroup | undefined>(undefined);
  documentForm$ = this.documentFormSubject.asObservable();

  documentTypes: DocumentType[] = [];
  private filteredDocumentTypesSubject = new BehaviorSubject<DocumentType[]>([]);
  filteredDocumentTypes$ = this.filteredDocumentTypesSubject.asObservable();
  private currentDocumentTypeSubject = new BehaviorSubject<DocumentType | undefined>(undefined);
  currentDocumentType$ = this.currentDocumentTypeSubject.asObservable();
  private formStatesSubject = new BehaviorSubject<Map<number, any>>(new Map());
  formStates$ = this.formStatesSubject.asObservable();
  // BehaviorSubject to track lookup suggestions
  private lookupSuggestionsSubject = new BehaviorSubject<{keyFieldCode: string; lookupSuggestion: LookupResponse[]} []>([]);
  // Observable that components can subscribe to
  public lookupSuggestions$ = this.lookupSuggestionsSubject.asObservable();
  buildFormFieldsForNewDocumentEvent = new EventEmitter<void>();
  documents: Document[] = [];
  currentAttachmentIndex: number = 0;
  tenantId: string = '';
  lookupUsersResponse: LookupResponse[] = [];
  constructor(
    private fb: FormBuilder,
    private docMngmtService: DocumentManagementService,
    private contentService: ContentApiService,
    ) {}


  /**
   * Builds and prepopulates form fields for the current document
   */
  buildFormFields(): void { 
    const savedState = this.formStatesSubject.getValue().get(this.currentAttachmentIndex);
    console.log("buildformfields in keyfields service savedState:");
    console.log(savedState);

    if (savedState && savedState.area) {
      this.initializeFormControls(savedState.area, savedState.documentTypeCode);
      this.documentFormSubject.value?.patchValue(savedState);
    } else {
      this.buildFormFieldsForNewDocument(this.currentAttachmentIndex);
    }
  }

    /**
       * Builds form fields for a new document when there is no saved state
       */
      private buildFormFieldsForNewDocument(currentAttachmentIndex: number): void {
        console.log("buildFormFieldsForNewDocument in keyfields service");
        const formControls: { [key: string]: FormControl } = {
          area: new FormControl(''),
          documentTypeCode: new FormControl('')
        };
    
        this.currentDocumentTypeSubject.next(undefined);
        const attachment = this.documents[currentAttachmentIndex];
        console.log(attachment);
    
        if (
          attachment.status &&
          Array.isArray(attachment.status.documents) &&
          attachment.status.documents.length > 0
        ) {
          console.log("attachment.status.documents.length > 0 and Array.isArray(attachment.status.documents)");
          const document = this.getMostConfidentDocument(attachment);
          console.log(document);
          if (document) {
            console.log("document not null");
            const keyFields = this.extractKeyFields(document);
            console.log(keyFields);
            this.mapKeyFields(document.classifyInfo?.documentType as string, keyFields).then(
              response => {
                if (response && response.mapItems.length > 0) {
                  console.log(response);
                  console.log("have response and response.mapitems");
                  const mapItem = response.mapItems[0];
                  console.log(mapItem);
    
                  attachment.mappedItems = response.mapItems;
                  
                  const docTypeCode = mapItem.docTypeCode;
                  console.log(mapItem.docTypeCode);
    
                  this.currentDocumentTypeSubject.next(this.getDocumentTypeByCode(docTypeCode));
                  console.log(this.currentDocumentType$);
                  this.populateFormControls(formControls, docTypeCode, mapItem.keyFields);
    
                  this.documentFormSubject.next(this.fb.group(formControls));
    
                  this.handleLookupFields(mapItem.keyFields);
                } else{
                  //If mapping fails, create empty form controls
                  this.initializeFormControls('', '');
                }
              }
            );
          }
        } else {
          // Create empty form controls if no status is available
          this.initializeFormControls('', '');
        }
      }



    /**
   * Initializes form controls with area and document type
   * @param {string} area - The selected area
   * @param {string} documentTypeCode - The selected document type code
   */
    initializeFormControls(area: string, documentTypeCode: string): void {

        const formControls: { [key: string]: FormControl } = {
        area: new FormControl(area),
        documentTypeCode: new FormControl(documentTypeCode)
        };
        this.filterDocumentTypes(area);
        //copilot wrote this
        let selectedDocumentType: DocumentType | undefined;
        this.filteredDocumentTypes$.subscribe(types => {
          selectedDocumentType = types.find(type => type.code === documentTypeCode);
        });

        this.currentDocumentTypeSubject.next(selectedDocumentType);

        if (this.currentDocumentType$) {
        this.addKeyFieldsToFormControls(formControls);
        }

        this.documentFormSubject.next(this.fb.group(formControls));
    }


  /**
   * Gets the document with the highest confidence from the list of documents in an attachment
   * @param {PrinterDocument} attachment - The attachment containing the documents
   * @returns {any} - The document with the highest confidence
   */
  getMostConfidentDocument(attachment: Document): any {
    if ((attachment.status?.documents.length ?? 0) < 1) {
      return null;
    }
    return attachment.status?.documents.reduce((prev, current) => {
      if (prev.classifyInfo && current.classifyInfo) {
        return prev.classifyInfo.confidence > current.classifyInfo.confidence ? prev : current;
      }
      return prev;
    });
  }

    /**
     * Retrieves the most confident document from an attachment
     * @param attachment - The attachment containing documents
     * @returns {any} - The document with the highest confidence level
     */
    getMostConfidentAttachment(attachment: Attachment): any {
      if ((attachment.status?.documents.length ?? 0) < 1) {
        return null;
      }
      return attachment.status?.documents.reduce((prev, current) => {
        if (prev.classifyInfo && current.classifyInfo) {
          return prev.classifyInfo.confidence > current.classifyInfo.confidence ? prev : current;
        }
        return prev;
      });
    }


  /**
   * Maps key fields based on the classified document type and provided key fields
   * @param {string} classifiedDocType - The classified document type
   * @param {KeyField[]} keyFields - The key fields to map
   * @param {string} [documentType] - Optional document type to map to
   * @returns {Promise<MapResponse>} - A promise of the map response
   */
  mapKeyFields(
    classifiedDocType: string,
    keyFields: KeyField[],
    documentType?: string,
  ): Promise<MapResponse> {
    console.log("map key fields in keyfields service");
    const mappingKeyFields: MappingKeyField[] = keyFields
      .filter(kf => kf.value !== undefined && kf.value !== null && kf.value !== '')
      .map(kf => ({ key: kf.key, value: kf.value }));

    return this.docTypeMappingSdk
      .mapKeyFields(this.tenantId, {
        modelDocType: classifiedDocType,
        keyFields: mappingKeyFields,
        docTypeCode: documentType
      })
      .then((response: MapResponse) => {
        return response;
      })
      .catch((error: ErrorResponse) => {
        console.error('Error mapping key fields: ', error);
        return undefined;
      });
  }



 /**
   * Adds key fields to the form controls
   * @param {Object} formControls - The form controls to add key fields to
   */
 addKeyFieldsToFormControls(formControls: { [key: string]: FormControl }): void {

    this.currentDocumentTypeSubject.value?.keyfields.forEach(field => {
      formControls[field.code] = new FormControl('');
      this.applyFieldRules(field, formControls);
    });
  }


  /**
   * Filters the document types list by the currently selected area
   * @param {string} areaCode - The code of the selected area
   */
  filterDocumentTypes(areaCode: string): void {
    this.filteredDocumentTypesSubject.next(this.documentTypes.filter(type => type.areaCode === areaCode));
  }



  /**
   * Applies validation and default rules to a form control
   * @param {DocumentTypeKeyField} field - The field metadata
   * @param {Object} formControls - The form controls to which rules should be applied
   */
  applyFieldRules(field: DocumentTypeKeyField, formControls: { [key: string]: FormControl }) {
    if (field.default) {
      if (field.dataType === 'Date' && field.default === 'CurrentDate') {
        formControls[field.code].setValue(new Date());
      } else {
        formControls[field.code].setValue(field.default);
      }
    }

    if (field.required) {
      formControls[field.code].setValidators([Validators.required]);
    }

    if (field.maxLength) {
      formControls[field.code].setValidators([Validators.maxLength(field.maxLength)]);
    }

    if (field.textPattern) {
      formControls[field.code].setValidators([Validators.pattern(field.textPattern)]);
    }

    if (field.minValue) {
      formControls[field.code].setValidators([Validators.min(field.minValue)]);
    }
  }




  /**
   * Handles lookup fields in the form, performing lookups as necessary
   * @param {MappingKeyField[]} mappedKeyFields - The key fields for lookup
   */
  handleLookupFields(mappedKeyFields: MappingKeyField[]): void {
    if (this.currentDocumentTypeSubject.value?.keyfields.some(keyfield => keyfield.dataType === 'Lookup')) {
      this.setLookup(mappedKeyFields);
    }
  }



  /**
   * Performs a lookup on key fields that are of type 'Lookup'
   * @param {MappingKeyField[]} mappedKeyFields - The mapped key field data to use for the lookup
   */
  setLookup(mappedKeyFields: MappingKeyField[]): void {
    this.currentDocumentTypeSubject.value?.keyfields
      .filter(keyfield => keyfield.dataType === 'Lookup')
      .forEach(keyfield => {
        if (keyfield.lookupType?.namingFields.some(namingField => namingField.required)) {
          let lookupSearchTerm = '';
          keyfield.lookupType.namingFields
            .filter(namingField => namingField.required)
            .forEach(namingField => {
              const matchingField = mappedKeyFields?.find(
                mappedField => mappedField.key === `${keyfield.code}.${namingField.fieldCode}`
              );
              if (matchingField) {
                lookupSearchTerm += `${matchingField.value} `;
              }
            });

          lookupSearchTerm = lookupSearchTerm.trim();
          if (
            lookupSearchTerm &&
            this.documents[this.currentAttachmentIndex].classificationStarted //TODO: fix this
          ) {
            this.lookupUsers(
              keyfield.code,
              keyfield.lookupType.code,
              lookupSearchTerm,
              true
            );
          }
        }
      });
  }

   /**
   * Performs a lookup for users based on a query and updates the form with the results
   * @param keyFieldCode The code of the key field to perform the lookup for
   * @param lookupTypeCode The code of the lookup type to use
   * @param query The query string to search for
   * @param isInitialLookup Whether this is the initial lookup (optional, default is false)
   */
   lookupUsers(
    keyFieldCode: string,
    lookupTypeCode: string,
    query: string,
    isInitialLookup = false
  ): void {
    this.contentService.lookupUser(query, lookupTypeCode).subscribe({
      next: response => {
        this.lookupUsersResponse = response;

        const lookupSuggestion = this.lookupSuggestionsSubject.getValue().find(
                suggestion => suggestion.keyFieldCode === keyFieldCode
        );

        if (lookupSuggestion) {
          lookupSuggestion.lookupSuggestion = response;
        } else {
          const currentSuggestions = this.lookupSuggestionsSubject.getValue();
          currentSuggestions.push({
            keyFieldCode: keyFieldCode,
            lookupSuggestion: response
          });
          this.lookupSuggestionsSubject.next(currentSuggestions);
        }

        if (isInitialLookup && this.lookupUsersResponse.length > 0) {
          if (this.lookupUsersResponse.length >= 1) {
            const firstResult = this.lookupUsersResponse[0];
            this.documentFormSubject.value?.controls[keyFieldCode].setValue(firstResult);
          }
        }
      },
      error: error => {
        console.error(error);
        this.lookupUsersResponse = [];
        this.lookupSuggestionsSubject.next([]);
      }
    });
  }




  /**
   * Populates form controls with values based on the selected document type and key fields
   * @param {Object} formControls - The form controls to populate
   * @param {string | undefined} docTypeCode - The document type code
   * @param {MappingKeyField[]} mappedKeyFields - The key fields to populate the form controls with
   */
  populateFormControls(
    formControls: { [key: string]: FormControl },
    docTypeCode: string | undefined,
    mappedKeyFields: MappingKeyField[]
  ): void {
    if (this.currentDocumentType$) {
      const areaCode = this.currentDocumentTypeSubject.value?.areaCode || '';
      this.filterDocumentTypes(areaCode);
      (formControls['area'] as FormControl).setValue(areaCode);
      (formControls['documentTypeCode'] as FormControl).setValue(docTypeCode);

      this.addKeyFieldsToFormControls(formControls);

      mappedKeyFields.forEach(mappedField => {
        const formControl = formControls[mappedField.key];
        if (formControl) {
          if (
            this.currentDocumentTypeSubject.value?.keyfields.find(kf => kf.code === mappedField.key)
              ?.dataType === 'Date'
          ) {
            const date = new Date(mappedField.value);
            if (!isNaN(date.getDate())) {
              formControl.setValue(new Date(mappedField.value));
            }
          } else {
            formControl.setValue(mappedField.value);
          }
        }
      });

      this.documentFormSubject.value?.patchValue(this.documentFormSubject.value.getRawValue(), { emitEvent: true });
    }
  }



 /**
   * Handles changes in the form, such as area or document type selection
   * @param {Event} event - The change event from the form
   */
  formOnChange(event: any): void {
    console.log("formOnChange in keyfields service event:");
    const target = event;

    if (target && target.area) {
      const selectedArea = target.area;
      this.documentFormSubject.value?.controls['area'].setValue(selectedArea);
      this.filterDocumentTypes(selectedArea);
      //copilot wrote this
      let foundType: DocumentType | undefined;
      this.filteredDocumentTypes$.subscribe(types => {
        foundType = types.find(type => type.code === this.currentDocumentTypeSubject.value?.code);
      }).unsubscribe();
      
      if (!foundType) {
        this.initializeFormControls(selectedArea, '');
        this.currentDocumentTypeSubject.next(undefined);
      }
    }

    if (target && target.documentType) {

      const selectedTypeCode = target.documentType.code;
      this.documentFormSubject.value?.controls['documentTypeCode'].setValue(selectedTypeCode);

      this.formStatesSubject.getValue().set(this.currentAttachmentIndex, this.documentFormSubject.value?.value); //value.value looks weird. double check
    
      if (!selectedTypeCode) {
        this.initializeFormControls(this.documentFormSubject.value?.controls['area'].value || '', '');
        this.currentDocumentTypeSubject.next(undefined);
      } else {
        //copilot wrote this
        let selectedDocumentType: DocumentType | undefined;
        this.filteredDocumentTypes$.subscribe(types => {
          selectedDocumentType = types.find(type => type.code === selectedTypeCode);
        }).unsubscribe();

        this.currentDocumentTypeSubject.next(selectedDocumentType);

        if (this.currentDocumentType$) {
          const currentDocument = this.documents[this.currentAttachmentIndex];
          const savedMapItems = currentDocument.mappedItems;

          if (savedMapItems) {
            const mapItem = savedMapItems.find(item => item.docTypeCode === selectedTypeCode);

            if (mapItem) {
              const controlsAsFormControl = this.documentFormSubject.value?.controls as {
                [key: string]: FormControl;
              };
              this.handleLookupFields(mapItem.keyFields);

              this.populateFormControls(controlsAsFormControl, selectedTypeCode, mapItem.keyFields);
            } else {
              this.buildFormFields();
            }
          } else {
            this.buildFormFields();
          }
        } else {
          this.currentDocumentTypeSubject.next(undefined);
          this.documentFormSubject.value?.controls['documentTypeCode'].reset();
        }
      }
      
    }
  }



  /**
   * Retrieves a document type by its code
   * @param {string | undefined} docTypeCode - The document type code
   * @returns {DocumentType | undefined} - The matching document type, if found
   */
  getDocumentTypeByCode(docTypeCode: string | undefined): DocumentType | undefined {
    console.log(this.documentTypes);
    return this.documentTypes.find(type => type.code === docTypeCode);
  }




  /**
   * Extracts key fields from a document
   * @param {any} document - The document to extract key fields from
   * @returns {KeyField[]} - An array of extracted key fields
   */
  extractKeyFields(document: any): KeyField[] {
    return document.extractInfo?.keyFields.map((field: KeyField) => ({
      ...field,
      value: field.value === null ? '' : field.value
    })) as KeyField[];
  }


  // Setter function for tenantId
  setTenantId(tenantId: string): void {
    this.tenantId = tenantId;
  }

  // Setter function for currentAttachmentIndex
  setCurrentAttachmentIndex(index: number): void {
    this.currentAttachmentIndex = index;
  }

    // Setter function for documentTypes
    setDocumentTypes(documentTypes: DocumentType[]): void {
        this.documentTypes = documentTypes;
      }
    
    // Function to patch documents
    patchDocuments(document: Document, index: number): void {
        if (index >= 0 && index < this.documents.length) {
        this.documents[index] = document;
        } else {
        console.error('Index out of bounds');
        }
    }

    // Function to clear documents
    clearDocuments(): void {
     this.documents = [];
    }

    addDocument(document: Document): void {
        this.documents.push(document);
    }

    clearLookupSuggestions(): void {
        this.lookupSuggestionsSubject.next([]);
    }

}


