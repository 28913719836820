import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faGear } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-in-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Output() changeContentUrlEvent = new EventEmitter();

  showSettings = false;
  isSettingsOpening = false;
  faGear = faGear;

  constructor(private router: Router) {}

  @HostListener('document:click')
  public onClick(): void {
    if (!this.isSettingsOpening) {
      this.showSettings = false;
    } else {
      this.isSettingsOpening = false;
    }
  }

  isPrinter(): boolean {
    return this.router.url.includes('/printer');
  }

  changeContentUrl(): void {
    this.changeContentUrlEvent.emit();
  }

  toggleShowSettings(): void {
    this.isSettingsOpening = !this.showSettings ? true : false;
    this.showSettings = !this.showSettings;
  }
}
