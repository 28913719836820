@if (showMergeDocuments) {
  <div class="merge-documents-container">
    <ng-container>
      <app-merge-documents
      [attachments]="getFileSelectorAttachments()"
      [batch]="batch"
      [currentAttachmentIndex]="currentAttachmentIndex"
      (closeMergeEvent)="closeMergeDocuments()"
      [isLoadingFiles]="loadingFilingFiles"
      [batchLoading]="batchLoading"
    >
    </app-merge-documents>
    </ng-container>    
  </div>
  }
@if (!showMergeDocuments) {
<div class="doc-filing-container">
  <ng-container>
    <!-- We have an existing KI for feature flags in batch. For now isAutoClassificationEnabled is always true but we will need to change it to a variable when we fix the KI -->
    <app-file-selector
      [attachments]="getFileSelectorAttachments()"
      [batch]="batch"
      [currentAttachmentIndex]="currentAttachmentIndex"
      (selectAttachmentEvent)="selectAttachment($event)"
      [enableBatchOptions]="enableBatchOptions"
      [canCloseBatch]="true"
      (openEditBatchEvent)="openEditBatchModal()"
      (openAddDocumentsEvent)="openAddDocumentsModal()"
      (openMergeDocumentsEvent)="openMergeDocuments()"
      (deleteBatchEvent)="confirmDeleteBatch()"
      (closeBatchEvent)="confirmCloseBatch()"
      [isLoadingFiles]="loadingFilingFiles"
      [batchLoading]="batchLoading"

      [isAutoclassificationEnabled]="true"
    ></app-file-selector>
  </ng-container>
  <div
    class="document-viewer-container"
    [hidden]="showUnsupportedFileMessage"
  >
    <document-viewer-widget
      [base64Content]="documents[currentAttachmentIndex]?.fileContent"
      [showClassificationButton]="false"
      (unsupportedFileType)="showUnsupportedFileMessage = true"
    ></document-viewer-widget>
  </div>
  <div
    *ngIf="showUnsupportedFileMessage"
    class="unsupported-msg-container"
  >
    <span class="unsupported-msg-text">{{ 'unsupportedFileType' | translate }}</span>
  </div>
  <div
    *ngIf="showClassificationResults"
    class="classification-results-container"
  >
    <app-classification-results
      [currentMostConfidentDocument]="currentMostConfidentDocument"
      (closeResultsEvent)="closeClassificationResults()"
    ></app-classification-results>
  </div>
  <div class="key-fields-container">
    <app-key-fields
      [docAreas]="areas"
      [docFormGroup]="documentForm"
      [documentId]="documents[this.currentAttachmentIndex]?.fileId"
      (changeEvent)="formOnChange($event)"
      [attachmentStatus]="documents[this.currentAttachmentIndex]?.status"
      [isDocumentCompleted]="documents[this.currentAttachmentIndex]?.completed ?? false"
      [ecmDocId]="documents[this.currentAttachmentIndex]?.ecmDocId"
      [filteredDocumentTypes]="filteredDocumentTypes"
      [currentDocumentType]="currentDocumentType"
      (uploadDocumentEvent)="uploadDocument()"
      [showDeleteDocumentButton]="
        (!isBatchCompleted() &&
          (userPrivilegesService.canPerformAction(
            'MANAGE_BATCH_DOCUMENTS',
            batch.department,
            batch.status
          ) | async)) ??
        false
      "
      (deleteDocumentEvent)="deleteDocument()"
      [isAutoClassificationEnabled]="true"
      (cancelFileAutoClassificationEvent)="
        cancelFileAutoClassification(this.currentAttachmentIndex)
      "
      [disableFileByPrivilege]="
        (userPrivilegesService.canPerformAction(
          'FILE_BATCH_DOCUMENT',
          batch.department,
          batch.status
        ) | async) === false
      "
    ></app-key-fields>
  </div>
</div>
}
<p-dialog
  [header]="'addDocuments' | translate"
  [modal]="true"
  [(visible)]="showAddDocumentsModal"
  [style]="{ width: '647px' }"
  class="add-documents-dialog"
  [draggable]="false"
  [resizable]="false"
  (onShow)="onDialogShow()"
  [closeAriaLabel]="'close' | translate"
>
  <div class="file-uploader-container">
    <app-file-uploader
      [tenantId]="tenantId"
      (uploadedDocumentIdsEvent)="addDocumentsToBatch($event)"
    >
    </app-file-uploader>
  </div>
</p-dialog>
<p-dialog
  [header]="'editBatch' | translate"
  [modal]="true"
  [(visible)]="showEditBatchModal"
  
  class="edit-batch-dialog"
  [draggable]="false"
  [resizable]="false"
  [closeAriaLabel]="'close' | translate"
>
  <div class="batch-info">
    <div class="batch-info-item">
      <span class="batch-info-item-label">{{ 'batchId' | translate }}</span>
      <span class="batch-info-item-text">{{ batch?.batchId }}</span>
    </div>
    <div class="batch-info-item">
      <span class="batch-info-item-label">{{ 'creationDate' | translate }}</span>
      <span class="batch-info-item-text">{{ batch?.createdAt | date: 'MM/dd/yyyy HH:mm a' }}</span>
    </div>
    <div class="batch-info-item">
      <span class="batch-info-item-label">{{ 'documentCount' | translate }}</span>
      <span class="batch-info-item-text">{{ batch?.statistics?.documentCount }}</span>
    </div>
    <!--
    <div class="batch-info-item">
      <span class="batch-info-item-label">{{ 'createdBy' | translate }}</span>
      <span class="batch-info-item-text">{{ batch?.createdBy }}</span>
    </div>
    -->
  </div>
  <hr class="modal-divider-line"/>
  <div class="batch-settings">
    <span class="batch-setting-title">{{ 'batchSettings' | translate }}</span>
    <form
      *ngIf="showEditBatchModal"
      class="edit-batch-form"
      [formGroup]="editBatchFormGroup"
    >
      <div class="flex flex-col gap-2">
        <label for="name">{{ 'batchName' | translate }}
          <input type="text" pInputText id="name" formControlName="name" />
        </label>
      </div>

      <div class="flex flex-col gap-2">
        <label for="batchDepartment">
          {{ 'department' | translate }}
            <p-select
              formControlName="department"
              id="batchDepartment"
              [options]="settingsDepartments()"
              optionLabel="name"
              optionValue="code"
              placeholder="{{ 'selectDepartment' | translate }}"
              [checkmark]="true"
              [filter]="true"
              [appendTo]="'body'"
              class="w-full md:w-56">
            </p-select>
        </label>
      </div>

      <div class="flex flex-col gap-2">
        <label for="defaultArea">
          {{ 'defaultArea' | translate }}
            <p-select
            id="defaultArea"
            [options]="settingsAreas"
            optionLabel="name"
            optionValue="code"
            placeholder=""
            [checkmark]="true"
            [filter]="true"
            [appendTo]="'body'"
            [showClear]="editBatchFormGroup.get('defaultArea')?.value !== ''"
            (onChange)="onDefaultAreaChange($event)"
            (onClear)="onDefaultAreaClear()"
            formControlName="defaultArea"
            class="w-full md:w-56">
          </p-select>
        </label>
      </div>

      <div class="flex flex-col gap-2">
        <label for="defaultDocumentType">
          {{ 'defaultDocumentType' | translate }}
            <p-select
              id="defaultDocumentType"
              [options]="batchEditFilteredDocumentTypes"
              optionLabel="name"
              optionValue="code"
              placeholder=""
              [checkmark]="true"
              [filter]="true"
              [appendTo]="'body'"
              [showClear]="editBatchFormGroup.get('defaultDocumentType')?.value !== ''"
              (onChange)="onDefaultDocumentTypeChange($event)"
              (onClear)="onDefaultDocumentTypeClear()"
              formControlName="defaultDocumentType"
              class="w-full md:w-56">
            </p-select>
        </label>
      </div>
    </form>
  </div>
  <div class="buttons-container">
    <p-button 
      class="cancel-button"
      (click)="closeEditBatchModal()"
      [label]="'cancel' | translate"
      [ariaLabel]="'cancel' | translate"
      severity="primary"
      variant="text">
    </p-button>
    <p-button 
      class="save-button"
      (click)="saveBatchSettings()"
      [label]="'save' | translate"
      [ariaLabel]="'save' | translate"
      [disabled]="isBatchCompleted()">
    </p-button>
  </div>
</p-dialog>

<p-toast
  position="bottom-right"
  key="uploadDoneToast"
  styleClass="upload-done-toast"
>
  <ng-template
    let-message
    pTemplate="message"
  >
    <div class="toast-message-container">
      <div class="icon-container">
        <i class="fa-solid fa-circle-check"></i>
      </div>
      <div class="message-container">
        <span class="message-summary">{{ message.summary | translate }}</span>
        <span>{{ message.detail | translate }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>

<p-toast
  position="bottom-right"
  key="errorToast"
  styleClass="error-toast"
>
  <ng-template
    let-message
    pTemplate="message"
  >
    <div class="toast-message-container">
      <div class="icon-container">
        <i class="fa-solid fa-circle-exclamation"></i>
      </div>
      <div class="message-container">
        <span class="message-summary">{{ message.summary | translate }}</span>
        <span>{{ message.detail | translate }}</span>
        <span *ngIf="message.data">{{ message.data }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
