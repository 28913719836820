import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddInComponent } from './pages/add-in/add-in.component';
import { PrinterComponent } from './pages/printer/printer.component';
import { WebCaptureComponent } from './pages/web-capture/web-capture.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/webcapture',
    pathMatch: 'full'
  },
  {
    path: 'outlookaddin',
    component: AddInComponent,
    data: { title: 'Softdocs for Outlook' }
  },
  {
    path: 'printer',
    component: PrinterComponent,
    data: { title: 'Softdocs Virtual Printer' }
  },
  {
    path: 'webcapture',
    component: WebCaptureComponent,
    data: { title: 'Softdocs Web Capture' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
