import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BatchService } from '../../services/batch/batch.service';
import { FileUploadStatus } from '../file-uploader/file-uploader.component';
import {
  BatchDocumentRequest,
  BulkCreateBatchDocumentRequest,
  CreateBatchRequest
} from '../../models/sdk-interfaces';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { AutoclassifierSdkService } from '../../services/autoclassifier-sdk/autoclassifier-sdk.service';

@Component({
  selector: 'app-batch-creation',
  templateUrl: './batch-creation.component.html',
  styleUrls: ['./batch-creation.component.scss']
})
export class BatchCreationComponent implements OnInit, OnDestroy {
  @Input() tenantId!: string;
  @Input() userId!: string;
  @Input() deleteDocumentsEvent = new Observable<void>();

  @Output() cancelBatchCreationEvent = new EventEmitter();
  @Output() hasUploadedFilesEvent = new EventEmitter();
  @Output() finishBatchCreationEvent = new EventEmitter();

  private deleteDocumentsSubscription = new Subscription();

  batchFormGroup!: FormGroup;
  uploadStatuses: FileUploadStatus[] = [];
  isExpanded: boolean = true;
  fileIdsToAssociate: string[] = [];

  constructor(
    private fb: FormBuilder,
    private batchService: BatchService,
    private autoclassifierSdkService: AutoclassifierSdkService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.batchFormGroup = this.fb.group({
      batchId: [''],
      name: [''],
      department: [''],
      defaultArea: [''],
      defaultDocumentType: ['']
    });

    this.deleteDocumentsSubscription = this.deleteDocumentsEvent.subscribe(() => {
      this.deleteDocuments();
    });

    this.createBatchId();

    this.batchFormGroup.valueChanges.subscribe(() => {
      if (this.fileIdsToAssociate.length === 0 && this.batchFormGroup.touched) {
        this.batchFormGroup.setErrors({ noFiles: true });
      }
    });
  }

  ngOnDestroy(): void {
    this.deleteDocumentsSubscription.unsubscribe();
  }

  /**
   * Creates a new batch ID
   */
  createBatchId() {
    this.batchService.createBatchId().then(response => {
      console.log('Batch ID created', response);
      this.batchFormGroup.controls['batchId'].setValue(response.batchId);
      this.batchFormGroup.controls['batchId'].disable();
    });
  }

  /**
   * Checks if the form has errors
   * @returns true if the form has errors, false otherwise
   */
  formHasErrors(): boolean {
    const errors = this.batchFormGroup.errors;
    return errors !== null && Object.keys(errors).length > 0;
  }

  /**
   * Checks if the form has a specific error
   * @param error
   * @returns true if the form has the specified error, false otherwise
   */
  checkFormError(error: string): boolean {
    return this.batchFormGroup.getError(error);
  }

  canCreateBatch(): boolean {
    return (
      this.uploadStatuses.some(file => file.status === 'completed') &&
      this.uploadStatuses.every(file => file.status === 'completed' || file.status === 'failed') &&
      this.batchFormGroup.valid
    );
  }

  /**
   * Creates a new batch with the provided data
   */
  createBatch() {
    if (this.fileIdsToAssociate.length > 0 && this.batchFormGroup.valid) {
      const batchData: CreateBatchRequest = {
        ...this.batchFormGroup.value,
        batchId: this.batchFormGroup.controls['batchId'].value,
        status: 'available',
        defaultArea: this.batchFormGroup.controls['defaultArea'].value,
        defaultDocumentType: this.batchFormGroup.controls['defaultDocumentType'].value
      };

      console.log('Document IDs to associate', this.fileIdsToAssociate);
      const bulkCreateRequest: BulkCreateBatchDocumentRequest = {
        documents: []
      };

      this.fileIdsToAssociate.forEach(fileId => {
        const batchDocument: BatchDocumentRequest = {
          documentId: fileId,
          source: 'document'
        };
        bulkCreateRequest.documents.push(batchDocument);
      });

      const totalDocuments = bulkCreateRequest.documents.length;
      const maxDocumentsPerCall = 100;

      if (totalDocuments <= maxDocumentsPerCall) {
        // If the number of documents is less than or equal to 100, create the batch in a single call
        batchData.documents = bulkCreateRequest.documents;
        this.batchService.createBatch(batchData).then(response => {
          console.log('Batch created', response);
          this.finishBatchCreation();
        });
      } else {
        // If the number of documents is greater than 100, create the batch in multiple calls
        const initialBatchDocuments = bulkCreateRequest.documents.slice(0, maxDocumentsPerCall);
        batchData.documents = initialBatchDocuments;

        this.batchService.createBatch(batchData).then(() => {
          const remainingDocuments = bulkCreateRequest.documents.slice(maxDocumentsPerCall);
          const batches = this.chunkDocuments(remainingDocuments, maxDocumentsPerCall);

          batches.forEach(batch => {
            const bulkCreateBatchRequest: BulkCreateBatchDocumentRequest = {
              documents: batch
            };
            this.batchService.bulkCreateBatchDocuments(
              batchData.batchId ?? '',
              bulkCreateBatchRequest
            );
          });

          this.finishBatchCreation();
        });
      }
    } else {
      if (!this.batchFormGroup.valid) {
        this.batchFormGroup.markAllAsTouched();
      }
      if (this.fileIdsToAssociate.length === 0) {
        this.batchFormGroup.setErrors({ noFiles: true });
      }
    }
  }

  /**
   * Splits a BatchDocumentRequest array into smaller arrays of a specified size
   */
  chunkDocuments(array: BatchDocumentRequest[], size: number): BatchDocumentRequest[][] {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

  cancelBatchCreation() {
    this.cancelBatchCreationEvent.emit();
  }

  deleteDocuments() {
    this.fileIdsToAssociate.forEach(fileId => {
      this.autoclassifierSdkService.documentManagementSdk
        .deleteDocument(this.tenantId, fileId)
        .then(() => console.log('Document deleted:', fileId));
    });
  }

  finishBatchCreation() {
    this.finishBatchCreationEvent.emit();
  }

  /**
   * Handles the upload status emitted from the FileUploaderComponent
   * @param status - The current status of the file uploads
   */
  onUploadStatusUpdate(status: FileUploadStatus[]): void {
    if (
      this.checkFormError('noFiles') &&
      status.some(file => file.status === 'completed') &&
      this.batchFormGroup.errors
    ) {
      this.batchFormGroup.setErrors(null);
    }
    this.uploadStatuses = status;
  }

  /**
   * Toggles the expanded state of the upload status section
   */
  toggleUploadStatus(): void {
    this.isExpanded = !this.isExpanded;
  }

  /**
   * Returns the number of files that have been successfully uploaded
   */
  uploadedCount(): number {
    return this.uploadStatuses.filter(file => file.status === 'completed').length;
  }

  setFileIdsToAssociate(fileIds: string[]) {
    this.hasUploadedFilesEvent.emit();
    fileIds.forEach(fileId => {
      this.fileIdsToAssociate.push(fileId);
    });

    console.log('File IDs to associate', this.fileIdsToAssociate);
  }
}
