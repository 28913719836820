export const dialogTheme = {
    css: ({ dt: any }: any) => `
    .p-dialog {
      width: 40vw !important;
      min-width: 230px;
    }

    
    .p-dialog-title {
      font-size: 24px;
      font-weight: 700;
    }
    
    .p-dialog-header {
      padding: 16px;
    }
    .p-dialog .p-dialog-header .p-dialog-close-button {
      color: var(--p-sd-text-primary);
    }
    .p-dialog .p-dialog-header .p-dialog-close-button:hover {
      color: var(--p-sd-text-primary);
      background: var(--p-button-text-primary-hover-background);
      
    }
    `
  };