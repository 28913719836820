import { definePreset } from '@primeng/themes';
import Aura from '@primeng/themes/aura';
import { autocompleteTheme } from './autocomplete-theme';
import { buttonTheme } from './button-theme';
import { cardTheme } from './card-theme';
import { checkboxTheme } from './checkbox-theme';
import {
  accentColor,
  customColors,
  neutral,
  overrideColor,
  sdGreen,
  sdPrimaryBlue,
  sdRed,
  sdSecondaryBlue,
  sdYellow,
  semanticColor
} from './colors';
import { dataTableTheme } from './datatable-theme';
import { datepickerTheme } from './datepicker-theme';
import { dialogTheme } from './dialog-theme';
import { inputNumberTheme } from './input-number-theme';
import { inputTextTheme } from './input-text-theme';
import { menuTheme } from './menu-theme';
import { messageTheme } from './message-theme';
import { multiselectTheme } from './multiselect-theme';
import { tabTheme } from './tab-theme';
import { textAreaTheme } from './text-area-theme';
import { toastTheme } from './toast-theme';
import {tooltipTheme} from  './tooltip-theme'
import { danger, success, warning } from './utility-tokens';

export const MyPreset = definePreset(Aura, {
  primitive: {
    sdPrimaryBlue: sdPrimaryBlue,
    sdSecondaryBlue: sdSecondaryBlue,
    neutral: neutral,
    sdGreen: sdGreen,
    sdRed: sdRed,
    sdYellow: sdYellow,
    customColors: customColors,
    // base 8 size system from style guide in px and rem
    size: {
      // direct pixel sizing
      pixel: {
        2: '2px',
        4: '4px',
        8: '8px',
        12: '12px',
        16: '16px',
        18: '18px',
        20: '20px',
        24: '24px',
        32: '32px',
        40: '40px',
        48: '48px',
        64: '64px',
        80: '80px'
      },
      // px: rem ratio
      rem: {
        2: '0.125rem',
        4: '0.25rem',
        8: '0.5rem',
        12: '0.75rem',
        16: '1rem',
        20: '1.25rem',
        24: '1.5rem',
        32: '2rem',
        40: '2.5rem',
        48: '3rem',
        64: '4rem',
        80: '5rem'
      }
    }
  },
  semantic: {
    primary: semanticColor('sdPrimaryBlue'),
    surface: semanticColor('neutral'),
    override: overrideColor('sdPrimaryBlue'),
    focusRing: {
      width: '2px',
      style: 'solid',
      color: '{slate.950}'
    },
    list: {
      option: {
        group: {
          color: '{sd.text.primary}'
        }
      }
    },
    // Used for adding new tokens. We prefix with 'sd' so we know which ones are ours at a glance in the DOM
    sd: {
      // Generalized values for shadows, Some may be overrwritten in component tokens or component CSS
      shadow: {
        color: '{sd.text.primary}',
        opacity: '.16',
        rgbColor: 'rgba({sd.shadow.color}, {sd.shadow.opacity})',
        box: '0px 3px 6px 0px {sd.shadow.rgbColor}',
        heavy: {
          color: '{primary.950}',
          opacity: '25%',
          rgbColor: `color-mix(in srgb, {sd.shadow.heavy.color}, transparent {sd.shadow.heavy.opacity})`,
          box: '0 3px 6px {sd.shadow.heavy.rgbColor}'
        }
      },
      // Groups refer to radios, checkboxes, etc.
      group: {
        vertical: {
          gap: '{size.rem.8}'
        }
      },
      primary: {
        color: '{primary.800}',
        normal: '{primary.800}',
        hover: '{primary.950}',
        light: '{primary.50}',
        disabled: '{primary.400}'

      },
      secondary: {
        color: '{primary.600}',
        disabled: '{primary.300}',
        hover: '{primary.100}',
        hovercolor: {
          lighter: '{primary.100}',
          darker: '{primary.700}'
        }
      },
      utility: {
        success: success('sdGreen'),
        danger: danger('sdRed'),
        warning: warning('sdYellow')
      },
      accent: {
        central: {
          color: accentColor('mulberry'),
          hover: {
            color: '{sd.accent.central.color.darker}'
          }
        },
        content: {
          color: accentColor('teal', '50', '700', '800'),
          hover: {
            color: '{sd.accent.content.color.darker}'
          }
        }
      },
      contrast: {
        low: '{surface.50}',
        high: '{surface.800}'
      },
      backgrounds: {
        primary: '{surface.200}',
        secondary: {
          color: '{primary.600}',
          hover: '{primary.100}'
        },
        hover: '{surface.300}',
        options: {
          hover: '{surface.200}'
        },
        panel: '{surface.100}',
        disabled: '{surface.50}',
        readonly: '{surface.300}',
        elementfill: '{surface.300}',
        contrast: '{surface.50}'
      },
      divider: {
        default: '{surface.300}',
        darker: '{surface.600}'
      },
      border: {
        default: '{surface.600}',
        bold: '{surface.700}',
        hover: '{sd.primary.hover}',
        disabled: '{surface.300}',
        shadowcolor: '{surface.300}',
        lighter: '{surface.300}',
        accent: {
          default: '{surface.300}',
          heavy: '{surface.400}'
        },
        radius: {
          small: '4px',
          medium: '6px',
          large: '8px'
        }
      },
      text: {
        primary: '{surface.800}',
        label: '{surface.700}',
        header: '{surface.700}',
        placeholder: '{surface.600}',
        helper: '{surface.600}',
        disabled: '{surface.500}',
        readonly: '{neutral.950}'
      },
      form: {
        action: {
          font: '{}',
          background: {
            color: '{sd.backgrounds.secondary.color}',
            hover: '{sd.secondary.hovercolor.darker}'
          }
        },
        divider: {
          color: '{sd.divider.default}'
        },
        header: {
          font: '{}',
          textColor: '{sd.text.primary}',
          background: '{sd.backgrounds.contrast}'
        },
        subheader: {
          font: '{sd.text.primary}',
          text: { color: '{sd.text.primary}' },
          background: '{sd.backgrounds.contrast}'
        },
        section: {
          background: '{sd.backgrounds.contrast}'
        }
      }
    },
    // General use primeng overrides for light mode
    colorScheme: {
      light: {
        surface: semanticColor('neutral'),
        navigation: {
          item: {
            focus: {
              background: '{surface.300}'
            }
          }
        },
        list: {
          optionGroup: {
            color: '{sd.text.primary}'
          }
        },
        primary: {
          color: '{sd.primary.normal}'
        },
        content: {
          border: {
            color: '{sd.border.default}'
          },
          background: '{sd.backgrounds.contrast}'
        },

        formField: {
          focusRing: {
            width: '1px',
            style: 'solid',
            color: '{sd.secondary.color}'
          },
          disabled: {
            background: '{surface.300}',
            color: '{sd.text.primary}'
          },
          background: '{sd.backgrounds.contrast}',
          hover: {
            border: {
              color: '{sd.border.hover}'
            }
          },
          border: {
            color: '{sd.border.default}'
          },
          icon: {
            color: '{surface.600}'
          },
          placeholder: {
            color: '{sd.text.placeholder}'
          },
          invalid: {
            placeholderColor: '{sd.text.placeholder}',
            borderColor: '{sd.utility.danger.normal}'
          },
          input: {
            height: '{size.rem.40}'
          }
        }
      }
    },
    button: {
      idle: {
        background: '{primary.800}'
      },
      hover: {
        background: '{primary.950}'
      },
      active: {
        background: '{primary.600}'
      }
    },
    selector: {
      background: '{primary.700}',
      icon: `{primary.100}`
    }
  },

  // Component level tokens. These are split into separate files to prevent the main theme file from becoming too large and minimize merge conflicts.
  components: {
    autocomplete: autocompleteTheme,
    button: buttonTheme,
    card: cardTheme,
    checkbox: checkboxTheme,
    datatable: dataTableTheme,
    datepicker: datepickerTheme,
    dialog:dialogTheme,
    inputnumber: inputNumberTheme,
    inputText: inputTextTheme,
    menu: menuTheme,
    message: messageTheme,
    multiselect: multiselectTheme,
    textarea: textAreaTheme,
    toast:toastTheme,
    tabs: tabTheme,
    tooltip: tooltipTheme,
  }  
});
