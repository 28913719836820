<url-verification
  *ngIf="!isAccessFlagLoading && !isUrlVerified; else addInContainer"
  [hasAccess]="outlookAddInAccessEnabled"
  [noAccessMessage]="featureFlagErrorText | translate"
  (successfulVerificationEvent)="onSuccessfulVerification()"
  (mustSignOutEvent)="signOut()"
></url-verification>
<ng-template #addInContainer>
  <ng-container *ngIf="!isLoading && user">
    <app-add-in-header (changeContentUrlEvent)="changeContentUrl()"></app-add-in-header>
    <div class="container">
      <app-file-selector
        [attachments]="getFileSelectorAttachments()"
        (selectAttachmentEvent)="selectAttachment($event)"
        [currentAttachmentIndex]="currentAttachmentIndex"
        [isAutoclassificationEnabled]="isAutoClassificationEnabled"
      ></app-file-selector>
      <div
        *ngIf="currentAttachmentIndex < 0"
        class="all-reviews-done-container"
      >
        <i class="fa-solid fa-circle-check"></i>
        <span>
          {{ 'allReviewsDone' | translate }}
        </span>
        <span>
          {{ 'closeWindow' | translate }}
        </span>
        <div class="buttons-container">
          <p-button 
            class="close-button" 
            (click)="closeDialog()" 
            [label]="'close' | translate" 
            variant="text">
          </p-button>
          <p-button 
            class="go-to-content-button" 
            (click)="goToContent()" 
            [label]="'goToContent' | translate">
          </p-button>
        </div>
      </div>
      <div
        *ngIf="isUnsupportedFile"
        class="unsupported-msg-container"
      >
        <span class="unsupported-msg-text"> Unsupported File Type </span>
      </div>
      <div
        [attr.hidden]="currentAttachmentIndex < 0 || isUnsupportedFile ? '' : null"
        class="document-viewer-container"
      >
        <document-viewer-widget
          [base64Content]="attachments[currentAttachmentIndex]?.content?.content"
          [showClassificationButton]="false"
          (startClassification)="startAttachmentClassification()"
          (unsupportedFileType)="showUnsupportedFileMessage($event)"
        ></document-viewer-widget>
      </div>
      <div
        *ngIf="showClassificationResults"
        class="classification-results-container"
      >
        <app-classification-results
          [currentMostConfidentDocument]="currentMostConfidentDocument"
          (closeResultsEvent)="closeClassificationResults()"
        ></app-classification-results>
      </div>
      <div class="key-fields-container">
        <app-key-fields
          *ngIf="
            currentAttachmentIndex >= 0 &&
            (attachments[currentAttachmentIndex]?.classificationStarted ||
              !isAutoClassificationEnabled) &&
            !isAutoclassificationFlagLoading
          "
          [docAreas]="areas"
          [docFormGroup]="documentForm"
          [documentId]="attachments[this.currentAttachmentIndex]?.id"
          (changeEvent)="formOnChange($event)"
          [attachmentStatus]="attachments[currentAttachmentIndex]?.status"
          [filteredDocumentTypes]="filteredDocumentTypes"
          [currentDocumentType]="currentDocumentType"
          (uploadDocumentEvent)="uploadDocument()"
          [isAutoClassificationEnabled]="isAutoClassificationEnabled"
          (cancelFileAutoClassificationEvent)="
            cancelFileAutoClassification(this.currentAttachmentIndex)
          "
        ></app-key-fields>
      </div>
    </div>
  </ng-container>
</ng-template>
<p-toast
  position="bottom-right"
  key="uploadDoneToast"
  styleClass="upload-done-toast"
>
  <ng-template
    let-message
    pTemplate="message"
  >
    <div class="toast-message-container">
      <div class="icon-container">
        <i class="fa-solid fa-circle-check"></i>
      </div>
      <div class="message-container">
        <span class="message-summary">{{ message.summary | translate }}</span>
        <span>{{ message.detail | translate }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-toast
  position="bottom-right"
  key="errorToast"
  styleClass="error-toast"
>
  <ng-template
    let-message
    pTemplate="message"
  >
    <div class="toast-message-container">
      <div class="icon-container">
        <i class="fa-solid fa-circle-exclamation"></i>
      </div>
      <div class="message-container">
        <span class="message-summary">{{ message.summary | translate }}</span>
        <span>{{ message.detail | translate }}</span>
        <span *ngIf="message.data">{{ message.data }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
