import { Component, ChangeDetectorRef, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FileSelectorAttachment } from '../file-selector/file-selector.component';


@Component({
  selector: 'app-merge-documents',
  templateUrl: './merge-documents.component.html',
  styleUrls: ['./merge-documents.component.scss']
})
export class MergeDocumentsComponent {
  @Input() attachments: FileSelectorAttachment[] = [];
  @Output() closeMergeEvent = new EventEmitter<void>();

  selectedFiles: FileSelectorAttachment[] = [];
  isMoveActive: boolean = false;
  activeItemIndex: number | null = null;
  isDialogVisible: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}

  @ViewChild('labelRef', { static: false }) labelRef!: ElementRef;

  isTextOverflowing(element: HTMLElement | null): boolean {
    if (!element) {
      return false;
    }
    return element.offsetWidth < element.scrollWidth;
  }
  
  toggleFileSelection(file: FileSelectorAttachment, event: any) {
    file.selected = event.target.checked;
    if (file.selected) {
      this.addFile(file);
    } else {
      this.removeFile(file);
    }
  }

  addFile(file: FileSelectorAttachment) {
    if (!this.selectedFiles.some(f => f.id === file.id)) {
      this.selectedFiles.push(file);
    }
    file.selected = true;
  }

  removeFile(file: FileSelectorAttachment) {
    this.selectedFiles = this.selectedFiles.filter(f => f.id !== file.id);

    const originalFile = this.attachments.find(f => f.id === file.id);
    if (originalFile) {
      originalFile.selected = false;
    }

    this.cdr.detectChanges();
  }
  
  drop(event: CdkDragDrop<FileSelectorAttachment[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.cdr.detectChanges();
    }
  }
  
  // Keyboard Navigation - Reordering Selected Filed
  moveItem(event: KeyboardEvent, index: number): void {
    switch (event.key) {
      case 'Enter':
        this.toggleMoveMode(event, index);
        break;
      case 'Escape':
      case 'Tab':
        this.deactivateMoveMode(event);
        break;
      case 'ArrowUp':
        this.moveUp(event, index);
        break;
      case 'ArrowDown':
        this.moveDown(event, index);
        break;
    }
  }
  
  private toggleMoveMode(event: KeyboardEvent, index: number): void {
    this.isMoveActive = !this.isMoveActive;
    this.activeItemIndex = this.isMoveActive ? index : null;
  
    event.preventDefault();
  
    if (this.isMoveActive) {
      this.focusItem(index);
    }
  }
  
  private deactivateMoveMode(event: KeyboardEvent): void {
    if (this.isMoveActive) {
      this.isMoveActive = false;
      this.activeItemIndex = null;
      event.preventDefault();
    }
  }
  
  private moveUp(event: KeyboardEvent, index: number): void {
    if (this.isMoveActive && index > 0) {
      moveItemInArray(this.selectedFiles, index, index - 1);
      this.activeItemIndex = index - 1;
      event.preventDefault();
      this.focusItem(this.activeItemIndex);
    }
  }
  
  private moveDown(event: KeyboardEvent, index: number): void {
    if (this.isMoveActive && index < this.selectedFiles.length - 1) {
      moveItemInArray(this.selectedFiles, index, index + 1);
      this.activeItemIndex = index + 1;
      event.preventDefault();
      this.focusItem(this.activeItemIndex);
    }
  }
  
  private focusItem(index: number): void {
    this.cdr.detectChanges();
    const buttons = Array.from(document.querySelectorAll('.drag-button'));
    if (index >= 0 && index < buttons.length) {
      const pButton = buttons[index] as HTMLElement;
      const nativeButton = pButton.querySelector('button');
      if (nativeButton) {
        nativeButton.focus();
      }
    }
  }

  trapFocus(event: KeyboardEvent, index: number): void {
    if (this.isMoveActive && event.key === 'Tab') {
      event.preventDefault();
  
      const button = document.querySelectorAll('.drag-button')[index] as HTMLElement;
      if (button) {
        button.focus();
      }  
    }
  }
  
  closeMerge(): void {
    this.closeMergeEvent.emit();
  }

  trackByIndex(index: number): number {
    return index;
  }

  openMergeDialog() {
    this.isDialogVisible = true;
  }

  closeMergeDialog() {
    this.isDialogVisible = false;
  }
}
