import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-classification-results',
  templateUrl: './classification-results.component.html',
  styleUrl: './classification-results.component.scss'
})
export class ClassificationResultsComponent {
  @Input() currentMostConfidentDocument: any;
  @Output() closeResultsEvent = new EventEmitter();


  closeResults(): void {
    this.closeResultsEvent.emit();
  }
}
