import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Attachment } from '../../models/attachment';
import { StatusResponse } from '../../models/statusResponse';
import { Router } from '@angular/router';
import {
  faEllipsis,
  faPenToSquare,
  faTrash,
  faFileCirclePlus
} from '@fortawesome/free-solid-svg-icons';

export interface FileSelectorAttachment {
  name: string;
  completed: boolean;
  status: StatusResponse;
  classificationStarted: boolean;
  canceled?: boolean;
}

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss']
})
export class FileSelectorComponent {
  @Input() attachments: FileSelectorAttachment[] = [];
  @Input() currentAttachmentIndex: number = 0;
  @Input() batchName: string = '';
  @Input() enableBatchOptions: boolean = false;
  @Input() isLoadingFiles: boolean = false;

  @Output() selectAttachmentEvent = new EventEmitter<number>();
  @Output() selectedAttachmentsEvent = new EventEmitter<Attachment[]>();
  @Output() openEditBatchEvent = new EventEmitter();
  @Output() deleteBatchEvent = new EventEmitter();
  @Output() closeBatchEvent = new EventEmitter();
  @Output() openAddDocumentsEvent = new EventEmitter();

  faEllipsis = faEllipsis;
  faPenToSquare = faPenToSquare;
  faTrash = faTrash;
  faFileCirclePlus = faFileCirclePlus;

  classificationStarted: boolean = false;
  selectedAttachments: Attachment[] = [];
  selectedAll: boolean = false;
  attachmentsTitle = this.isAddIn() ? 'attachments' : 'documents';
  showBatchOptions: boolean = false;
  isBatchOptionsOpening: boolean = false;

  constructor(private router: Router) {}

  @HostListener('document:click')
  public onClick(): void {
    if (!this.isBatchOptionsOpening) {
      this.showBatchOptions = false;
    } else {
      this.isBatchOptionsOpening = false;
    }
  }

  /**
   * Checks if the batch options should be hidden after a focus event.
   */
  checkBatchOptionsFocus(event: FocusEvent): void {
    if (!(event.relatedTarget as HTMLInputElement)?.classList.contains('batch-option')) {
      this.showBatchOptions = false;
    }
  }

  trackByIndex(index: number): number {
    return index;
  }

  get headerLabel(): string {
    return this.classificationStarted ? 'fileSelectorHeader1' : 'fileSelectorHeader2';
  }

  isAddIn(): boolean {
    return this.router.url.includes('/outlookaddin');
  }

  isPrinter(): boolean {
    return this.router.url.includes('/printer');
  }

  isWebCapture(): boolean {
    return this.router.url.includes('/webcapture');
  }

  selectAttachment(index: number): void {
    this.selectAttachmentEvent.emit(index);
  }

  closeDialog(): void {
    // Signal to Outlook to close the dialog window
    Office.context.ui.messageParent(JSON.stringify({ status: 'closeDialog' }));
  }

  toggleBatchOptions(): void {
    this.isBatchOptionsOpening = !this.showBatchOptions ? true : false;
    this.showBatchOptions = !this.showBatchOptions;
  }

  openEditBatch(): void {
    this.toggleBatchOptions();
    this.openEditBatchEvent.emit();
  }

  openAddDocuments(): void {
    this.toggleBatchOptions();
    this.openAddDocumentsEvent.emit();
  }

  deleteBatch(): void {
    this.toggleBatchOptions();
    this.deleteBatchEvent.emit();
  }

  closeBatch(): void {
    this.closeBatchEvent.emit();
  }
}
