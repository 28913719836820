export const menuTheme = {
    colorScheme: {
      light: {
        item: {
          icon: {
            focus: {
              color: '{sd.secondary.color}'
            },
            color: '{sd.secondary.color}'
          }
        }
      }
    },
    css: ({ dt: any }: any) => `
    .p-menu {
      border: none;
    }
    .p-menu-list {
      margin: 8px 0px;
      padding: 0;
    }
    .p-menu-item {
      height: 40px;
      padding-top: 4px;

      &:hover {
      background: var(--p-sd-backgrounds-hover);
      }
    }
    .p-menu-item-content {
      border-radius: 0px;
    }
    .p-menu-item:not(.p-disabled) .p-menu-item-content:hover {
      background: var(--p-sd-backgrounds-hover);
    }
    .p-menu-item-icon {
      margin-right: 8px
    }
  `
  };
  