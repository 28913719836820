<table *ngIf="currentMostConfidentDocument">
  <tr>
    <th>{{ 'classification' | translate }}</th>
    <td></td>
    <!-- TODO: Add label for th and create button to wrap the icon in -->
    <th class="close-cell">
      <i (click)="closeResults()" class="fa-regular fa-xmark"></i>
    </th>
  </tr>
  <tr>
    <td>{{ currentMostConfidentDocument.classifyInfo.documentType }}</td>
    <td>{{ currentMostConfidentDocument.classifyInfo.confidence * 100 | number: '1.2-2' }}%</td>
  </tr>
  <tr>
    <th>{{ 'extraction' | translate }}</th>
  </tr>
  <tr *ngFor="let keyField of currentMostConfidentDocument.extractInfo.keyFields">
    <td>{{ keyField.key }}</td>
    <td>{{ keyField.value }}</td>
    <td>{{ keyField.confidence * 100 | number: '1.2-2' }}%</td>
  </tr>
  <tr *ngIf="currentMostConfidentDocument.extractInfo.keyFields.length < 1">
    <td>None</td>
  </tr>
</table>
<div *ngIf="!currentMostConfidentDocument">
  <span class="no-results-span">{{ 'noExtractionResults' | translate }}</span>
  <!-- TODO: Add a button to wrap the icon in -->
    <i (click)="closeResults()" class="fa-regular fa-xmark"></i>
</div>
