export const success = (colorName: string) => ({
    light: `{${colorName}.100}`,
    normal: `{${colorName}.700}`,
    dark: `{${colorName}.800}`
  });
  
  export const danger = (colorName: string) => ({
    light: `{${colorName}.100}`,
    normal: `{${colorName}.600}`,
    dark: `{${colorName}.700}`
  });
  
  export const warning = (colorName: string) => ({
    light: `{${colorName}.100}`,
    normal: `{${colorName}.300}`,
    dark: `{${colorName}.500}`
  });