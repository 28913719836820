export const messageTheme = {
    text: {
      smFontSize: `{size.rem.12}`
    },
    colorScheme: {
      light: {
        errorSimpleColor: '{sd.utility.danger.normal}'
      }
    }
  };
  