export const autocompleteTheme = {
    css: ({ dt: any }: any) => `  .form {
      .p-autocomplete-dropdown {
        background: var(--p-autocomplete-sd-form-button-background);
        color: var(--p-autocomplete-sd-form-button-hover-color);
      }
  
      .p-autocomplete-dropdown:not(:disabled):hover {
        background: var(--p-autocomplete-sd-form-button-hover-background);
        color: var(--p-autocomplete-sd-form-button-hover-color);
      }
  
      .p-autocomplete-input:disabled {
        border-color: var(--p-textarea-disabled-color);
        color: var(--p-sd-text-readonly);
      }
    }`,
    colorScheme: {
      light: {
        sd: {
          form: {
            button: {
              hover: {
                background: '{sd.form.action.background.hover}',
                color: '{selector.icon}'
              },
              background: '{sd.form.action.background.color}',
              color: '{selector.icon}'
            }
          }
        },
        option: {
          group: {
            color: '{sd.text.primary}'
          }
        },
        dropdown: {
          background: '{sd.backgrounds.contrast}'
        }
      }
    }
  };
  