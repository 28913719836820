import { Injectable } from '@angular/core';
import { UserPrivilegesResponse } from '../../models/sdk-interfaces';
import { AutoclassifierSdkService } from '../autoclassifier-sdk/autoclassifier-sdk.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private autoclassifierSdkService: AutoclassifierSdkService) {}

  /**
   * Get Current User Privileges
   * @param accessToken bearer token
   * @returns User privileges
   */
  getCurrentUserPrivileges(accessToken: string): Promise<UserPrivilegesResponse> {
    return this.autoclassifierSdkService.usersSdk.getCurrentUserPrivileges(accessToken);
  }
}
