// External Libraries
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription, combineLatest, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { HttpClient, HttpParams } from '@angular/common/http';

// Services
import { AuthService } from '../../services/auth/auth.service';
import { TenantApiService } from '../../services/tenant-api/tenant-api.service';
import {
  ContentApiService,
  DocumentType,
  Area,
  KeyField as DocumentTypeKeyField
} from '../../services/content-api/content-api.service';
import { DocumentManagementService } from '../../services/document-management/document-management.service';
import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';

// Models
import { Attachment } from '../../models/attachment';
import { MappingKeyField, MapResponse } from '../../models/docTypeMapping';
import { KeyField } from '../../models/statusResponse';
import { ErrorResponse } from '../../models/errorResponse';
import { ExtractionResponse } from '../../models/extractionResponse';
import { DocumentResponse } from '../../models/documentResponse';
import { FileSelectorAttachment } from '../../components/file-selector/file-selector.component';

// Components
import { KeyFieldsComponent } from '../../components/key-fields/key-fields.component';

// Utilities
import { base64ToFile, buildKeyFields } from '../../utils/documentUtils';

// Environment Variables
import { environment } from '../../environments/environment';
import { KeyFieldsService } from '../../services/key-fields/key-fields.service';

declare const Softdocs: any;
declare const pendo: any;

@Component({
  selector: 'app-google-addon',
  templateUrl: './google-addon.component.html',
  styleUrls: ['./google-addon.component.scss']
})
export class GoogleAddonComponent implements OnInit, OnDestroy {
  @ViewChild(KeyFieldsComponent) keyFieldsComponent!: KeyFieldsComponent;

  private activeKeys: Set<string> = new Set();
  private subscription = new Subscription();

  // SDK Instances
  autoclassifierSdk = new Softdocs.DocumentAnalysisSdk(environment.urls.autoclassifierApi);
  docTypeMappingSdk = new Softdocs.DocTypeMappingSdk(environment.urls.autoclassifierApi);
  documentManagementSdk = new Softdocs.DocumentManagementSdk(environment.urls.autoclassifierApi);

  // Observables and Subjects
  attachments$: BehaviorSubject<Attachment[]> = new BehaviorSubject<Attachment[]>([]);
  autoclassificationFlag$ = new BehaviorSubject<boolean>(false);

  
  // Component State
  isLoading = true;
  isAutoclassificationFlagLoading = true;
  isUnsupportedFile = false;
  showClassificationResults = false;

  // Data
  attachments: Attachment[] = [];
  documentIds: string[] = [];
  documentTypes: DocumentType[] = [];
  areas: Area[] = [];
  filteredDocumentTypes: DocumentType[] = [];
  currentDocumentType: DocumentType | undefined;
  currentMostConfidentDocument: any;
  formStates: Map<number, any> = new Map();
  currentAttachmentIndex: number = 0;
  authToken: string = '';
  tenantId: string = '';
  featureFlagErrorText: string = '';
  userToken: string = '';

  // Flags and Configurations
  isAccessFlagLoading: boolean = true;
  isAutoClassificationEnabled: boolean = false;
  isUrlVerified: boolean = false;
  outlookAddInAccessEnabled: boolean | null = null;

  // Form
  documentForm!: FormGroup;

  constructor(
    private authService: AuthService,
    private tenantService: TenantApiService,
    private contentService: ContentApiService,
    private docMngmtService: DocumentManagementService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private _location: Location,
    private messageService: MessageService,
    private featureFlagService: FeatureFlagService,
    private http: HttpClient,
    public keyFieldsService: KeyFieldsService
  ) {}

  ngOnInit(): void {
    // Subscribe to route parameters to get document IDs and auth token
    this.subscription.add(
      this.route.queryParams.subscribe(async params => {
        switch (params['action']) {
          case 'SetURL':
            if (params['userToken']) {
              this.userToken = params['userToken'];
              console.log('Extracted userToken:', this.userToken);
            }

            localStorage.removeItem('tenantId');
            localStorage.removeItem('vanityName');
            localStorage.removeItem('contentURL');
            break;
          case 'ChangeURL':
            if (params['userToken']) {
              this.userToken = params['userToken'];
              console.log('Extracted userToken:', this.userToken);
            }

            localStorage.removeItem('tenantId');
            localStorage.removeItem('vanityName');
            break;
          case 'fileAttachments':
            if (params['ref']) {
              const refToken = params['ref'];
              const addonScriptUrl = environment.urls.addonScript;
              const body = new HttpParams().set('ref', refToken).toString();
              const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

              try {
                const response =
                  (await this.http
                    .post(addonScriptUrl, body, { headers, responseType: 'text' })
                    .toPromise()) || '';

                console.log('Successfully retrieved info from the add-on:', response);

                const data = JSON.parse(response);
                if (data.error) {
                  console.error('Error from add-on:', data.error);
                } else {
                  this.authToken = data.accessToken;
                  this.tenantId = data.tenantId;
                  this.documentIds = data.docIds;
                }
              } catch (error) {
                console.error('Error retrieving attachment info from add-on:', error);
              }
            }
            break;
          default:
            break;
        }

        this.checkFeatureFlag();
      })
    );
  }

  private getRefTokenFromUrl(): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('ref');
  }

  /**
   * Starts the Google Add-on functionality by loading data and initializing feature flags
   */
  startAddOn(): void {
    console.log('start add on');
    this.isUrlVerified = true;

    if (!this.authToken) {
      const organization = localStorage.getItem('vanityName');
      this.tenantId = localStorage.getItem('tenantId') || '';

      this.sendTenantInfoToAddon(this.tenantId, organization || '').then(() => {
        console.log('Tenant information sent to add-on.');
        window.top?.close();
      });
    }

    const { key, name, tenantId } = this.authService.extractUserInfo(this.authToken);
    this.tenantId = tenantId;

    // Initialize Pendo analytics
    pendo.initialize({
      account: {
        id: tenantId
      }
    });

    // Initialize feature flags
    this.featureFlagService.initialize({ key, name, tenantId });

    // Subscribe to autoclassification feature flag
    this.subscription.add(
      // TODO: Replace with 'autoclassification-for-google-add-on' once available
      this.featureFlagService
        .getFlag('autoclassification-for-outlook-add-in', true)
        .subscribe(flagValue => {
          this.isAutoclassificationFlagLoading = false;
          this.isAutoClassificationEnabled = flagValue as boolean;
          this.autoclassificationFlag$.next(flagValue);
        })
    );

    // Load necessary data
    this.loadData();

    // Subscribe to attachments and autoclassification flags
    this.subscription.add(
      combineLatest([this.attachments$, this.autoclassificationFlag$]).subscribe(
        ([attachments, autoclassificationEnabled]) => {
          if (attachments.length > 0 && autoclassificationEnabled) {
            this.analyzeAttachments();
          }
        }
      )
    );

    this.initializeDocumentForm();
    this.initializeEventListeners();
  }

  /**
   * Run the feature flag check for the provided tenantId
   * @param tenantId the provided tenantId
   */
  runFeatureFlagCheck(tenantId: string): void {
    this.isAccessFlagLoading = true;
    this.featureFlagService
      .initialize({ tenantId })
      .then(() =>
        this.subscription.add(
          this.featureFlagService
            // TODO: Replace with 'google-add-on-access' once available
            .getFlag('outlook-add-in-access', false)
            .pipe(
              switchMap(flagValue => {
                this.isAccessFlagLoading = false;
                if (flagValue) {
                  this.outlookAddInAccessEnabled = true;
                  pendo.initialize({ account: { id: tenantId } });
                  this.startAddOn();
                } else {
                  this.isUrlVerified = false;
                  this.outlookAddInAccessEnabled = false;
                  this.featureFlagErrorText = 'addOnFeatureFlagError';
                }
                return of(null);
              })
            )
            .subscribe()
        )
      )
      .catch(() => {
        this.isAccessFlagLoading = false;
        this.isUrlVerified = false;
        this.outlookAddInAccessEnabled = false;
      });
  }

  /**
   * Re-verifies the tenant URL and triggers feature flag check based on the tenant ID
   */
  onSuccessfulVerification(): void {
    const tenantId = localStorage.getItem('tenantId');
    const organization = localStorage.getItem('vanityName');

    if (tenantId && organization) {
      this.isAccessFlagLoading = true;
      this.runFeatureFlagCheck(tenantId);
    }
  }

  /**
   * Check the feature flag based on tenantId from localStorage.
   */
  checkFeatureFlag(): void {
    const tenantId = localStorage.getItem('tenantId');

    if (tenantId) {
      this.runFeatureFlagCheck(tenantId);
    } else {
      this.isAccessFlagLoading = false;
      this.isUrlVerified = false;
      this.outlookAddInAccessEnabled = null;
    }
  }

  handleDocumentStatusUpdate(event: CustomEvent): void {
    const index = this.attachments.findIndex(
      attachment => attachment.requestId === event.detail.requestId
    );
    if (index !== -1) {
      this.attachments[index].status = event.detail.status;
      if (
        index === this.currentAttachmentIndex &&
        this.attachments[this.currentAttachmentIndex].classificationStarted
      ) {
        this.buildFormFields();
      }
    }
  }

  handleDocumentStatusError(event: CustomEvent) {
    const { requestId, errorCode } = event.detail;
    const index = this.attachments.findIndex(doc => doc.requestId === requestId);

    if (index !== -1) {
      const doc = this.attachments[index];

      let errorMessage;
      switch (errorCode) {
        case 'documentInvalid':
          errorMessage = 'attachmentInvalidError';
          break;
        default:
          errorMessage = 'analyzeError';
          break;
      }

      this.showErrorToast(errorMessage, doc.name);
      this.cancelFileAutoClassification(index);

      if (
        index === this.currentAttachmentIndex &&
        this.attachments[this.currentAttachmentIndex].classificationStarted
      ) {
        this.buildFormFields();
      }
    }
  }

  initializeEventListeners(): void {
    window.addEventListener(
      'documentStatusUpdate',
      this.handleDocumentStatusUpdate.bind(this) as EventListener
    );
    window.addEventListener(
      'documentStatusError',
      this.handleDocumentStatusError.bind(this) as EventListener
    );
  }

  /**
   * Loads necessary data such as document types and areas
   */
  loadData(): void {
    this.authService.setAuthToken(this.authToken);

    this.subscription.add(
      this.tenantService
        .getTenantUrls(this.tenantId)
        .pipe(
          switchMap(tenantUrls => {
            this.contentService.setBaseUrl(tenantUrls.contentApiUrl);
            return this.contentService.getDocumentTypes();
          }),
          switchMap(docTypes => {
            this.documentTypes = docTypes;
            return this.contentService.getAreas();
          }),
          catchError(error => {
            console.error('Error in chained requests', error);
            this.showErrorToast('dataError');
            return of([]); // Return an observable to keep the stream alive
          })
        )
        .subscribe(areas => {
          this.areas = areas;
          Promise.all(this.documentIds.map(docId => this.getDocumentFromDocMgmtSdk(docId))).then(
            () => {
              this.attachments$.next(this.attachments);
              this.isLoading = false;
            }
          );
        })
    );
  }

  /**
   * Retrieves a document and adds it to the attachments array
   */
  getDocumentFromDocMgmtSdk(documentId: string): Promise<void> {
    return this.documentManagementSdk
      .getDocument(this.tenantId, documentId)
      .then((response: DocumentResponse) => {
        const newAttachment: Attachment = {
          id: response.id,
          name: response.fileName,
          contentType: response.type,
          size: 0,
          attachmentType: response.type,
          isInline: false,
          content: {
            content: '', // Will set after fetching content
            format: 'base64'
          },
          completed: false,
          classificationStarted: false
        };

        return this.docMngmtService
          .getDocument(response.contentUri)
          .toPromise()
          .then(arrayBuffer => {
            const binaryString = Array.from(new Uint8Array(arrayBuffer))
              .map(byte => String.fromCharCode(byte))
              .join('');
            const base64 = btoa(binaryString);
            newAttachment.content.content = base64;
            newAttachment.classificationStarted = true;

            this.attachments.push(newAttachment);
          });
      })
      .catch((error: any) => {
        console.error('Document get Error:', error);
        this.showErrorToast('getDocumentError');
      });
  }

  /**
   * Initializes the document form
   */
  initializeDocumentForm(): void {
    this.documentForm = this.fb.group({
      area: [''],
      documentTypeCode: ['']
    });
  }

  /**
   * Analyzes the attachments
   */
  analyzeAttachments(): Promise<void> {
    return Promise.all(this.attachments.map(att => this.analyzeDocument(att)))
      .then(() => {})
      .catch(error => {
        console.error('Error processing attachments', error);
      });
  }

  /**
   * Analyzes a single attachment
   */
  async analyzeDocument(attachment: Attachment): Promise<void> {
    if (attachment.content.format === 'base64') {
      const file = base64ToFile(
        attachment.content.content,
        attachment.name,
        attachment.contentType
      );
      this.autoclassifierSdk
        .extractDocumentFromUpload(file, true)
        .then((response: ExtractionResponse) => {
          const index = this.attachments.findIndex(att => att.id === attachment.id);
          this.attachments[index].requestId = response.requestId;
        })
        .catch((error: ErrorResponse) => {
          console.error('Error analyzing document:', error);
          this.showErrorToast('analyzeError', attachment.name);
        });
    } else {
      console.warn('Cannot handle an unsupported attachment format.');
    }
  }

  /**
   * Selects an attachment and builds form fields for that attachment
   */
  selectAttachment(index: number): void {
    if (index >= 0 && index < this.attachments.length) {
      this.formStates.set(this.currentAttachmentIndex, this.documentForm.value);

      this.isUnsupportedFile = false;
      this.currentAttachmentIndex = index;

      if (
        this.attachments[this.currentAttachmentIndex].classificationStarted ||
        !this.isAutoClassificationEnabled
      ) {
        this.buildFormFields();
      } else {
        this.initializeDocumentForm();
      }
    }
  }

  /**
   * Builds and prepopulates form fields for the currently selected attachment
   */
  buildFormFields(): void {
    this.currentMostConfidentDocument = this.getMostConfidentDocument(
      this.attachments[this.currentAttachmentIndex]
    );

    const savedState = this.formStates.get(this.currentAttachmentIndex);

    if (savedState && savedState.area) {
      this.initializeFormControls(savedState.area, savedState.documentTypeCode);
      this.documentForm.patchValue(savedState);
    } else {
      this.buildFormFieldsForNewAttachment();
    }
  }

  /**
   * Initializes the form controls for the document form
   */
  private initializeFormControls(area: string, documentTypeCode: string): void {
    const formControls: { [key: string]: FormControl } = {
      area: new FormControl(area),
      documentTypeCode: new FormControl(documentTypeCode)
    };
    this.filterDocumentTypes(area);
    this.currentDocumentType = this.filteredDocumentTypes.find(
      type => type.code === documentTypeCode
    );

    if (this.currentDocumentType) {
      this.addKeyFieldsToFormControls(formControls);
    }

    this.documentForm = this.fb.group(formControls);
  }

  /**
   * Builds form fields for a new attachment
   */
  private buildFormFieldsForNewAttachment(): void {
    const formControls: { [key: string]: FormControl } = {
      area: new FormControl(''),
      documentTypeCode: new FormControl('')
    };

    this.currentDocumentType = undefined;
    const attachment = this.attachments[this.currentAttachmentIndex];

    if (
      attachment.status &&
      Array.isArray(attachment.status.documents) &&
      attachment.status.documents.length > 0
    ) {
      this.setMappedKeyFields(attachment, formControls);
    } else {
      this.initializeFormControls('', '');
    }
  }

  /**
   * Sets mapped key fields
   */
  setMappedKeyFields(attachment: Attachment, formControls: { [key: string]: FormControl }): void {
    const document = this.getMostConfidentDocument(attachment);

    if (document) {
      const keyFields = this.extractKeyFields(document);
      this.mapKeyFields(document.classifyInfo?.documentType as string, keyFields).then(response => {
        if (response && response.mapItems.length > 0) {
          const mapItem = response.mapItems[0];

          attachment.mappedItems = response.mapItems;

          const docTypeCode = mapItem.docTypeCode;
          this.currentDocumentType = this.getDocumentTypeByCode(docTypeCode);
          this.populateFormControls(formControls, docTypeCode, mapItem.keyFields);

          this.documentForm = this.fb.group(formControls);

          this.handleLookupFields(mapItem.keyFields);
        }
      });
    }
  }

  /**
   * Extracts key fields from a document
   */
  private extractKeyFields(document: any): KeyField[] {
    return document.extractInfo?.keyFields.map((field: KeyField) => ({
      ...field,
      value: field.value === null ? '' : field.value
    })) as KeyField[];
  }

  /**
   * Retrieves the most confident document from an attachment
   */
  private getMostConfidentDocument(attachment: Attachment): any {
    return attachment.status?.documents.reduce((prev, current) => {
      if (prev.classifyInfo && current.classifyInfo) {
        return prev.classifyInfo.confidence > current.classifyInfo.confidence ? prev : current;
      }
      return prev;
    });
  }

  /**
   * Retrieves the document type by its code
   */
  private getDocumentTypeByCode(docTypeCode: string | undefined): DocumentType | undefined {
    return this.documentTypes.find(type => type.code === docTypeCode);
  }

  /**
   * Populates the form controls with the selected area and document type code
   */
  private populateFormControls(
    formControls: { [key: string]: FormControl },
    docTypeCode: string | undefined,
    mappedKeyFields: MappingKeyField[]
  ): void {
    if (this.currentDocumentType) {
      const areaCode = this.currentDocumentType.areaCode;
      this.filterDocumentTypes(areaCode);
      (formControls['area'] as FormControl).setValue(areaCode);
      (formControls['documentTypeCode'] as FormControl).setValue(docTypeCode);

      this.addKeyFieldsToFormControls(formControls);

      mappedKeyFields.forEach(mappedField => {
        const formControl = formControls[mappedField.key];
        if (formControl) {
          if (
            this.currentDocumentType?.keyfields.find(kf => kf.code === mappedField.key)
              ?.dataType === 'Date'
          ) {
            const date = new Date(mappedField.value);
            if (!isNaN(date.getDate())) {
              formControl.setValue(new Date(mappedField.value));
            }
          } else {
            formControl.setValue(mappedField.value);
          }
        }
      });

      this.documentForm.patchValue(this.documentForm.getRawValue(), { emitEvent: true });
    }
  }

  /**
   * Adds key fields to the form controls
   */
  private addKeyFieldsToFormControls(formControls: { [key: string]: FormControl }): void {
    this.currentDocumentType?.keyfields.forEach(field => {
      formControls[field.code] = new FormControl('');
      this.applyFieldRules(field, formControls);
    });
  }

  /**
   * Handles lookup fields in the form, performing lookups as necessary
   */
  private handleLookupFields(mappedKeyFields: MappingKeyField[]): void {
    if (this.currentDocumentType?.keyfields.some(keyfield => keyfield.dataType === 'Lookup')) {
      this.setLookup(mappedKeyFields);
    }
  }

  /**
   * Applies validation and default rules to a form control
   */
  applyFieldRules(field: DocumentTypeKeyField, formControls: { [key: string]: FormControl }) {
    if (field.default) {
      if (field.dataType === 'Date' && field.default === 'CurrentDate') {
        formControls[field.code].setValue(new Date());
      } else {
        formControls[field.code].setValue(field.default);
      }
    }

    if (field.required) {
      formControls[field.code].setValidators([Validators.required]);
    }

    if (field.maxLength) {
      formControls[field.code].setValidators([Validators.maxLength(field.maxLength)]);
    }

    if (field.textPattern) {
      formControls[field.code].setValidators([Validators.pattern(field.textPattern)]);
    }

    if (field.minValue !== undefined) {
      formControls[field.code].setValidators([Validators.min(field.minValue)]);
    }
  }

  /**
   * Filters the document types list by the currently selected area
   */
  filterDocumentTypes(areaCode: string): void {
    this.filteredDocumentTypes = this.documentTypes.filter(type => type.areaCode === areaCode);
  }

  /**
   * Performs a lookup on key fields that are of type 'Lookup'
   */
  setLookup(mappedKeyFields: MappingKeyField[] | undefined): void {
    this.currentDocumentType?.keyfields
      .filter(keyfield => keyfield.dataType === 'Lookup')
      .forEach(keyfield => {
        if (keyfield.lookupType?.namingFields.some(namingField => namingField.required)) {
          let lookupSearchTerm = '';
          keyfield.lookupType.namingFields
            .filter(namingField => namingField.required)
            .forEach(namingField => {
              const matchingField = mappedKeyFields?.find(
                mappedField => mappedField.key === `${keyfield.code}.${namingField.fieldCode}`
              );
              if (matchingField) {
                lookupSearchTerm += `${matchingField.value} `;
              }
            });

          lookupSearchTerm = lookupSearchTerm.trim();
          if (
            lookupSearchTerm &&
            this.attachments[this.currentAttachmentIndex].classificationStarted
          ) {
            //TODO: add service function calls for everything
            this.keyFieldsService.lookupUsers(
              keyfield.code,
              keyfield.lookupType.code,
              lookupSearchTerm,
              true
            );
          }
        }
      });
  }

  /**
   * Maps key fields based on the classified document type and provided key fields
   */
  mapKeyFields(
    classifiedDocType: string,
    keyFields: KeyField[],
    documentType?: string
  ): Promise<MapResponse> {
    const mappingKeyFields: MappingKeyField[] = keyFields
      .filter(kf => kf.value !== undefined && kf.value !== null && kf.value !== '')
      .map(kf => ({ key: kf.key, value: kf.value }));

    return this.docTypeMappingSdk
      .mapKeyFields(this.tenantId, {
        modelDocType: classifiedDocType,
        keyFields: mappingKeyFields,
        docTypeCode: documentType
      })
      .then((response: MapResponse) => response)
      .catch((error: ErrorResponse) => {
        console.error('Error mapping key fields: ', error);
        return undefined;
      });
  }

  /**
   * Starts the classification process for the currently selected attachment
   */
  startAttachmentClassification(): void {
    this.attachments[this.currentAttachmentIndex].classificationStarted = true;
    if (this.attachments[this.currentAttachmentIndex].status) {
      this.buildFormFields();
    }
  }

  /**
   * Uploads the attachment with type, area, and key field information to Softdocs Etrieve
   */
  uploadDocument(): void {
    if (this.documentForm?.valid) {
      const formData = buildKeyFields(
        this.documentForm.value,
        this.currentDocumentType as DocumentType
      );

      const multiValueFields = this.currentDocumentType?.keyfields.filter(kf => kf.multivalue);

      if (multiValueFields) {
        multiValueFields.forEach(multiValueField => {
          const multiValueFieldKey = `${multiValueField.code}-${this.currentDocumentType?.code}-${this.attachments[this.currentAttachmentIndex]?.id}`;

          if (this.keyFieldsComponent.multiValueFields[multiValueFieldKey]) {
            this.keyFieldsComponent.multiValueFields[multiValueFieldKey].forEach(value => {
              formData.push({
                fieldCode: multiValueField.code,
                value: value
              });
            });
          }
        });
      }

      this.contentService
        .uploadDocument(
          this.attachments[this.currentAttachmentIndex],
          this.documentForm.controls['area'].value as string,
          this.documentForm.controls['documentTypeCode'].value as string,
          formData
        )
        .subscribe({
          error: error => {
            console.error('Failed to upload document', error);
            this.showErrorToast('uploadError');
            this.keyFieldsComponent.onUploadComplete();
          },
          complete: () => {
            this.showUploadDoneToast();
            this.keyFieldsComponent.onUploadComplete();
            this.attachments[this.currentAttachmentIndex].completed = true;
            if (this.attachments.findIndex(att => !att.completed) !== -1) {
              this.selectAttachment(this.attachments.findIndex(att => !att.completed));
            } else {
              this.currentAttachmentIndex = -1;
            }
          }
        });
    } else {
      console.error('Form is not valid, cannot upload document.');
      this.keyFieldsComponent.onUploadComplete();
      this.showErrorToast('validationError');
    }
  }

  /**
   * Cancels the file filing process for the current attachment
   */
  cancelFileFiling(): void {
    this.documentManagementSdk
      .deleteDocument(this.tenantId, this.attachments[this.currentAttachmentIndex].id)
      .then(() => {
        console.log('Document deleted;', this.attachments[this.currentAttachmentIndex].id);
        this.attachments.splice(this.currentAttachmentIndex, 1);
        if (this.attachments?.findIndex(att => !att.completed) !== -1) {
          this.selectAttachment(this.attachments.findIndex(att => !att.completed));
        }
      });
  }

  /**
   * Retrieves the attachments for the file selector
   */
  getFileSelectorAttachments(): FileSelectorAttachment[] {
    return this.attachments as FileSelectorAttachment[];
  }

  /**
   * Shows a toast message indicating that the file upload is complete
   */
  showUploadDoneToast(): void {
    this.messageService.add({
      severity: 'success',
      summary: 'uploadToastTitleAddIn',
      detail: 'uploadToastMessageAddIn',
      key: 'uploadDoneToast',
      life: 10000
    });
  }

  /**
   * Shows an error toast message
   */
  showErrorToast(toastMessage: string, toastData?: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: toastMessage,
      key: 'errorToast',
      data: toastData ? toastData : '',
      life: 10000
    });
  }

  /**
   * Sets the current document status to an empty object, cancelling the auto-classification process
   */
  cancelFileAutoClassification(index: number): void {
    this.attachments[index].status = {
      documents: []
    };
  }

  /**
   * Handles the keydown event for both macOS and Windows platforms
   * @param {KeyboardEvent} event - The keydown event
   */
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.activeKeys.add(event.key.toLowerCase());

    const isMac = this.isMacPlatform();

    // CMD + SHIFT + A + I
    const isMacShortcutPressed =
      isMac &&
      event.metaKey &&
      event.shiftKey &&
      this.activeKeys.has('a') &&
      this.activeKeys.has('i');

    // CTRL + SHIFT + A + I
    const isWindowsShortcutPressed =
      !isMac &&
      event.ctrlKey &&
      event.shiftKey &&
      this.activeKeys.has('a') &&
      this.activeKeys.has('i');

    if (isMacShortcutPressed || isWindowsShortcutPressed) {
      event.preventDefault();
      this.openDialog();
      this.activeKeys.clear();
    }
  }

  /**
   * Handles the keyup event by removing the key from the activeKeys set
   * @param {KeyboardEvent} event - The keyup event
   */
  @HostListener('window:keyup', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    this.activeKeys.delete(e.key.toLowerCase());
  }

  /**
   * Checks if the platform is macOS
   * @returns {boolean} - Returns true if the platform is macOS, otherwise false
   */
  isMacPlatform(): boolean {
    // Use navigator.userAgentData if available (future-proof)
    if ((navigator as any).userAgentData) {
      return (navigator as any).userAgentData.platform === 'macOS';
    }

    // Fallback to user agent string check if userAgentData is not available
    return /Mac|iPhone|iPod|iPad/.test(navigator.userAgent);
  }

  /**
   * Opens the classification results dialog.
   */
  openDialog() {
    if (!this.showClassificationResults) {
      this.currentMostConfidentDocument = this.getMostConfidentDocument(
        this.attachments[this.currentAttachmentIndex]
      );
    } else {
      this.currentMostConfidentDocument = null;
    }
    this.showClassificationResults = !this.showClassificationResults;
  }

  /**
   * Closes the classification results dialog.
   */
  closeClassificationResults(): void {
    this.showClassificationResults = false;
  }

  /**
   * Handles form changes, such as area or document type selection
   */
  formOnChange(event: any): void {
    const target = event.target;

    if (target && target.id === 'areaSelector') {
      const selectedArea = target.value;
      this.documentForm.controls['area'].setValue(selectedArea);

      this.filterDocumentTypes(selectedArea);

      const foundType = this.filteredDocumentTypes.find(
        type => type.code === this.currentDocumentType?.code
      );

      if (!foundType) {
        this.initializeFormControls(selectedArea, '');
        this.currentDocumentType = undefined;
      }
    }

    if (target && target.id === 'documentTypeSelector') {
      this.formStates.set(this.currentAttachmentIndex, this.documentForm.value);

      const selectedTypeCode = target.value;

      if (!selectedTypeCode) {
        this.initializeFormControls(this.documentForm.controls['area'].value, '');
        this.currentDocumentType = undefined;
      } else {
        this.currentDocumentType = this.filteredDocumentTypes.find(
          type => type.code === selectedTypeCode
        );

        if (this.currentDocumentType) {
          const currentAttachment = this.attachments[this.currentAttachmentIndex];
          const savedMapItems = currentAttachment.mappedItems;

          if (savedMapItems) {
            const mapItem = savedMapItems.find(item => item.docTypeCode === selectedTypeCode);

            if (mapItem) {
              const controlsAsFormControl = this.documentForm.controls as {
                [key: string]: FormControl;
              };

              this.populateFormControls(controlsAsFormControl, selectedTypeCode, mapItem.keyFields);

              this.handleLookupFields(mapItem.keyFields);
            } else {
              this.buildFormFields();
            }
          } else {
            this.buildFormFields();
          }
        } else {
          this.currentDocumentType = undefined;
          this.documentForm.controls['documentTypeCode'].reset();
        }
      }
    }
  }

  async sendTenantInfoToAddon(tenantId: string, organization: string) {
    const addonScriptUrl = environment.urls.addonScript;

    const body = new HttpParams()
      .set('tenantId', tenantId)
      .set('organization', organization)
      .set('token', this.userToken)
      .toString();

    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

    try {
      const response = await this.http
        .post(addonScriptUrl, body, { headers, responseType: 'text' })
        .toPromise();

      console.log('Successfully sent tenant info to the add-on:', response);
    } catch (error) {
      console.error('Error sending tenant info to add-on:', error);
    }
  }

  /**
   * Handles unsupported file messages
   */
  showUnsupportedFileMessage(isUnsupportedFile: any): void {
    if (isUnsupportedFile.detail) {
      this.isUnsupportedFile = true;
    }
  }

  /**
   * Opens a new browser window with the content URL
   */
  goToContent(): void {
    if (localStorage.getItem('contentURL')) {
      window.open(localStorage.getItem('contentURL')?.toString(), '_blank', 'location=yes');
    }
  }

  /**
   * Closes the dialog window and signals to close
   */
  close(): void {
    const refToken = this.getRefTokenFromUrl();
    if (refToken) {
      const body = new URLSearchParams();
      body.set('action', 'cleanup');
      body.set('ref', refToken);

      fetch(environment.urls.addonScript, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: body.toString()
      })
        .then(response => response.text())
        .then(text => {
          console.log('Cleanup response:', text);
        })
        .catch(err => console.error('Cleanup error:', err))
        .finally(() => window.close());
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    // Remove the event listeners when the component is destroyed
    window.removeEventListener(
      'documentStatusUpdate',
      this.handleDocumentStatusUpdate.bind(this) as EventListener
    );
    window.removeEventListener(
      'documentStatusError',
      this.handleDocumentStatusError.bind(this) as EventListener
    );
  }
}
