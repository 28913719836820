export const toastTheme = {
    colorScheme: {
      light: {
        closeButton: {
          color: '{surface.900}'
        }
      }
    },
    css: ({ dt: any }: any) => `
    .p-toast-message-content {
      align-items: center;
      justify-content: space-between;
      margin-right: 16px;
    }

    .p-toast-close-icon {
      color: #ffffff;
      margin-left: auto;
    }
  }

  `
  };