<div class="batch-settings">
  <span class="batch-settings-title">
    {{ 'batchSettings' | translate }}
  </span>
  <form
    class="batch-form"
    [formGroup]="batchFormGroup"
  >
    <label for="batchId">
      {{ 'batchId' | translate }}
      <input
        type="text"
        id="batchId"
        pInputText
        [placeholder]="'batchId' | translate"
        formControlName="batchId"
        pSize="large"
        [disabled]="true"
      />
    </label>
    <label for="batchName">
      {{ 'batchName' | translate }}
      <input
        pInputText
        type="text"
        id="batchName"
        [placeholder]="'batchName' | translate"
        formControlName="name"
        pSize="large"
      />
    </label>
      <label class="required-label" for="departmentSelect">
        {{ 'department' | translate }}
        <span class="required-text">*</span>
      </label>
      <div class="select-container">
        <p-select 
          formControlName="department" 
          [options]="departments" 
          optionLabel="name" 
          optionValue="code" 
          [filter]="true" 
          filterBy="name"
          placeholder="{{ 'selectDepartmenr' | translate }}" 
          id="departmentSelect"
          size="large"
          aria-required="true">
        </p-select>
      </div>
      <span
        *ngIf="
          batchFormGroup.controls['department']?.errors &&
          batchFormGroup.controls['department']?.touched
        "
        class="required-text"
      >
        {{ 'departmentRequired' | translate }}
      </span>

    <span class="optional-settings">{{ 'optionalSettings' | translate }}</span>

    <label class="default-area" for="defaultArea">
      {{ 'defaultArea' | translate }}
      <div class="select-container">
        <p-select 
          id="defaultArea"
          formControlName="defaultArea"
          [options]="areas"
          optionLabel="name"
          optionValue="code"
          [filter]="true" 
          filterBy="name"
          [showClear]="batchFormGroup.get('defaultArea')?.value !== ''"
          placeholder="{{ 'defaultAreaPlaceholder' | translate }}"
          size="large">
        </p-select>
      </div>
    </label>
    @if (batchFormGroup.get('defaultArea')?.value !== '') {
      <label for="defaultDocumentType">
        {{ 'defaultDocumentType' | translate }}
        <div class="select-container">
          <p-select 
            id="defaultDocumentType"
            formControlName="defaultDocumentType"
            [options]="filteredDocumentTypes"
            optionLabel="name"
            optionValue="code"
            placeholder="{{ 'Select Document Type' | translate }}"
            [filter]="true" 
            filterBy="name"
            [showClear]="batchFormGroup.get('defaultDocumentType')?.value !== ''"
            [disabled]="!batchFormGroup.get('defaultArea')?.value"
            size="large">
          </p-select>
        </div>
      </label>
    }
  </form>
</div>
