import { ActivateAccountStep } from './interfaces';
import { createProxy } from '../../toolkit/proxy';
export const initialState = {
  step: ActivateAccountStep.activating,
  loading: false,
  submitting: false,
  resentEmail: false,
  activationStrategy: {
    loading: false
  }
};
export default (overrideState => createProxy(initialState, overrideState));