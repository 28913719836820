import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["roleId"],
  _excluded2 = ["roleId"],
  _excluded3 = ["permissionId"];
import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class RolesApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.getRoles = async () => {
      return this.get(urls.identity.roles.v1);
    };
    this.addRole = async body => {
      return this.post(urls.identity.roles.v1, body);
    };
    this.deleteRole = async ({
      roleId
    }) => {
      return this.delete(`${urls.identity.roles.v1}/${roleId}`);
    };
    this.updateRole = async _ref => {
      let {
          roleId
        } = _ref,
        body = _objectWithoutPropertiesLoose(_ref, _excluded);
      return this.patch(`${urls.identity.roles.v1}/${roleId}`, body);
    };
    this.attachPermissionsToRole = async _ref2 => {
      let {
          roleId
        } = _ref2,
        body = _objectWithoutPropertiesLoose(_ref2, _excluded2);
      return this.put(`${urls.identity.roles.v1}/${roleId}/permissions`, body);
    };
    this.getPermissions = async () => {
      return this.get(urls.identity.permissions.v1);
    };
    this.attachPermissionToRoles = async _ref3 => {
      let {
          permissionId
        } = _ref3,
        body = _objectWithoutPropertiesLoose(_ref3, _excluded3);
      return this.put(`${urls.identity.permissions.v1}/${permissionId}/roles`, body);
    };
    this.getPermissionCategories = async () => {
      return this.get(`${urls.identity.permissions.v1}/categories`);
    };
    this.addRoleV2 = async body => {
      return this.post(urls.identity.roles.v2, body);
    };
  }

  /**
   * Gets tenant related roles
   */
}
export default new RolesApi('default');