import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, Component, ChangeDetectionStrategy, ViewEncapsulation, NgModule } from '@angular/core';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
    .p-fluid{
        width:100%
    }
`;
const classes = {
  root: 'p-fluid'
};
class FluidStyle extends BaseStyle {
  name = 'fluid';
  classes = classes;
  theme = theme;
  static ɵfac = /*@__PURE__*/(() => {
    let ɵFluidStyle_BaseFactory;
    return function FluidStyle_Factory(__ngFactoryType__) {
      return (ɵFluidStyle_BaseFactory || (ɵFluidStyle_BaseFactory = i0.ɵɵgetInheritedFactory(FluidStyle)))(__ngFactoryType__ || FluidStyle);
    };
  })();
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: FluidStyle,
    factory: FluidStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FluidStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * Fluid is a layout component to make descendant components span full width of their container.
 *
 * [Live Demo](https://www.primeng.org/fluid/)
 *
 * @module fluidstyle
 *
 */
var FluidClasses;
(function (FluidClasses) {
  /**
   * Class name of the root element
   */
  FluidClasses["root"] = "p-fluid";
})(FluidClasses || (FluidClasses = {}));
const _c0 = ["*"];
/**
 * Fluid is a layout component to make descendant components span full width of their container.
 * @group Components
 */
class Fluid extends BaseComponent {
  _componentStyle = inject(FluidStyle);
  static ɵfac = /*@__PURE__*/(() => {
    let ɵFluid_BaseFactory;
    return function Fluid_Factory(__ngFactoryType__) {
      return (ɵFluid_BaseFactory || (ɵFluid_BaseFactory = i0.ɵɵgetInheritedFactory(Fluid)))(__ngFactoryType__ || Fluid);
    };
  })();
  static ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
    type: Fluid,
    selectors: [["p-fluid"]],
    hostVars: 2,
    hostBindings: function Fluid_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassProp("p-fluid", true);
      }
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([FluidStyle]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function Fluid_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    dependencies: [CommonModule],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Fluid, [{
    type: Component,
    args: [{
      selector: 'p-fluid',
      template: ` <ng-content></ng-content> `,
      standalone: true,
      imports: [CommonModule],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [FluidStyle],
      host: {
        '[class.p-fluid]': 'true'
      }
    }]
  }], null, null);
})();
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassDebugInfo(Fluid, {
    className: "Fluid"
  });
})();
class FluidModule {
  static ɵfac = function FluidModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || FluidModule)();
  };
  static ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
    type: FluidModule
  });
  static ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
    imports: [Fluid]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FluidModule, [{
    type: NgModule,
    args: [{
      imports: [Fluid],
      exports: [Fluid]
    }]
  }], null, null);
})();
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(FluidModule, {
    imports: [Fluid],
    exports: [Fluid]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Fluid, FluidClasses, FluidModule, FluidStyle };
