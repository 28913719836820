import { Injectable } from '@angular/core';
import {
  BatchIdResponse,
  BatchResponse,
  CreateBatchRequest,
  ListBatchesParams,
  BatchListResponse,
  UpdateBatchRequest,
  BulkCreateBatchDocumentRequest,
  BatchDocumentListResponse,
  BatchDocumentResponse,
  UpdateBatchDocumentRequest,
  Privilege
} from '../../models/sdk-interfaces';
import { AutoclassifierSdkService } from '../autoclassifier-sdk/autoclassifier-sdk.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BatchService {
  constructor(
    private authService: AuthService,
    private autoclassifierSdkService: AutoclassifierSdkService
  ) {}

  /**
   * Create a Batch ID
   * @returns A promise resolving to BatchIdResponse
   */
  createBatchId(): Promise<BatchIdResponse> {
    const accessToken = this.authService.getAuthToken()!;
    return this.autoclassifierSdkService.batchSdk.createBatchId(accessToken);
  }

  /**
   * Create a new Batch
   * @param data Request body for creating a batch
   * @returns A promise resolving to BatchResponse
   */
  createBatch(data: CreateBatchRequest): Promise<BatchResponse> {
    const accessToken = this.authService.getAuthToken()!;
    return this.autoclassifierSdkService.batchSdk.createBatch(accessToken, data);
  }

  /**
   * Update an existing Batch
   * @param batchId Batch identifier
   * @param data Request body for updating a batch
   * @returns A promise resolving to BatchResponse
   */
  updateBatch(batchId: string, data: UpdateBatchRequest): Promise<BatchResponse> {
    const accessToken = this.authService.getAuthToken()!;
    return this.autoclassifierSdkService.batchSdk.updateBatch(accessToken, batchId, data);
  }

  /**
   * Get Batch details
   * @param batchId Batch identifier
   * @returns A promise resolving to BatchResponse
   */
  getBatch(batchId: string): Promise<BatchResponse> {
    const accessToken = this.authService.getAuthToken()!;
    return this.autoclassifierSdkService.batchSdk.getBatch(accessToken, batchId);
  }

  /**
   * List Batches with optional filters
   * @param params Optional parameters for listing batches
   * @returns A promise resolving to BatchListResponse
   */
  listBatches(params?: ListBatchesParams): Promise<BatchListResponse> {
    const accessToken = this.authService.getAuthToken()!;
    return this.autoclassifierSdkService.batchSdk.listBatches(accessToken, params);
  }

  /**
   * Delete a Batch
   * @param batchId Batch identifier
   * @returns A promise resolving to void
   */
  deleteBatch(batchId: string): Promise<void> {
    const accessToken = this.authService.getAuthToken()!;
    return this.autoclassifierSdkService.batchSdk.deleteBatch(accessToken, batchId);
  }

  /**
   * Bulk create documents in a batch
   * @param batchId Batch identifier
   * @param data List of documents to add to the batch
   * @returns A promise resolving to BatchDocumentListResponse
   */
  bulkCreateBatchDocuments(
    batchId: string,
    data: BulkCreateBatchDocumentRequest
  ): Promise<BatchDocumentListResponse> {
    const accessToken = this.authService.getAuthToken()!;
    return this.autoclassifierSdkService.batchSdk.bulkCreateBatchDocuments(
      accessToken,
      batchId,
      data
    );
  }

  /**
   * Get documents in a batch
   * @param batchId Batch identifier
   * @returns A promise resolving to BatchDocumentListResponse
   */
  getBatchDocuments(batchId: string): Promise<BatchDocumentListResponse> {
    const accessToken = this.authService.getAuthToken()!;
    return this.autoclassifierSdkService.batchSdk.getBatchDocuments(accessToken, batchId);
  }

  /**
   * Update a specific document in a batch
   * @param batchId Batch identifier
   * @param documentId Document identifier
   * @param data Request body for updating a batch document
   * @returns A promise resolving to BatchDocumentResponse
   */
  updateBatchDocument(
    batchId: string,
    documentId: string,
    data: UpdateBatchDocumentRequest
  ): Promise<BatchDocumentResponse> {
    const accessToken = this.authService.getAuthToken()!;
    return this.autoclassifierSdkService.batchSdk.updateBatchDocument(
      accessToken,
      batchId,
      documentId,
      data
    );
  }

  /**
   * Remove a document from a batch
   * @param batchId Batch identifier
   * @param documentId Document identifier
   * @returns A promise resolving to void
   */
  removeBatchDocument(batchId: string, documentId: string): Promise<void> {
    const accessToken = this.authService.getAuthToken()!;
    return this.autoclassifierSdkService.batchSdk.removeBatchDocument(
      accessToken,
      batchId,
      documentId
    );
  }

  /**
   * Checkout a Batch
   * @param batchId Batch identifier
   * @returns Updated batch details
   */
  checkoutBatch(batchId: string): Promise<BatchResponse> {
    const accessToken = this.authService.getAuthToken()!;
    return this.autoclassifierSdkService.batchSdk.checkoutBatch(accessToken, batchId);
  }

  /**
   * Checkin a Batch
   * @param batchId Batch identifier
   * @returns Updated batch details
   */
  checkinBatch(batchId: string): Promise<BatchResponse> {
    const accessToken = this.authService.getAuthToken()!;
    return this.autoclassifierSdkService.batchSdk.checkinBatch(accessToken, batchId);
  }

  /**
   * Get Batch Privileges
   * @returns User privileges
   */
  getBatchPrivileges(): Promise<Privilege[]> {
    const accessToken = this.authService.getAuthToken()!;
    return this.autoclassifierSdkService.batchSdk.getBatchPrivileges(accessToken);
  }
}
