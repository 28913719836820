import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, map } from 'rxjs';
import { UsersService } from '../users/user.service';
import { UserPrivilegesResponse, Department } from '../../models/sdk-interfaces';

@Injectable({
  providedIn: 'root'
})
export class UserPrivilegesService {
  private privilegesSubject = new BehaviorSubject<UserPrivilegesResponse | undefined>(undefined);

  constructor(private userService: UsersService) {}

  /**
   * Call to get user privileges
   * @returns Observable with mock privileges
   */
  getUserPrivileges(accessToken: string): Promise<UserPrivilegesResponse> {
    return this.userService.getCurrentUserPrivileges(accessToken);
  }

  /**
   * Sets the privileges and updates
   * @param privileges - Array of privileges
   */
  setPrivileges(privileges: UserPrivilegesResponse): void {
    this.privilegesSubject.next(privileges);
  }

  getCurrentPrivileges(): UserPrivilegesResponse | undefined {
    return this.privilegesSubject.value;
  }

  /**
   * Checks if the user can perform an action based on a privilege code.
   * @param privilegeCode - The code of the privilege to check.
   */
  canPerformAction(
    privilegeCode: string,
    department?: string,
    status?: string
  ): Observable<boolean> {
    return this.privilegesSubject.asObservable().pipe(
      map(privileges => {
        if (!privileges) {
          return false;
        }

        // Find the privilege by operationName
        const privilege = privileges.resourcePrivileges.find(
          p => p.operationName === privilegeCode
        );
        if (!privilege) {
          return false;
        }

        // If department is specified, find that department inside the privilege
        if (department) {
          const dep = privilege.context.departments.find(d => d.code === department);
          if (!dep) {
            // Department not in this privilege => deny
            return false;
          }

          // If status is specified, check if that status is allowed for this department
          if (status && dep.status && dep.status.length) {
            return dep.status?.includes(status) || false;
          } else {
            // If no specific status is required or if status list is empty, grant privilege
            return true;
          }
        }

        // If no department is specified at all, then having the privilege is enough
        return true;
      })
    );
  }

  getAllDepartments(): Observable<Department[]> {
    return this.privilegesSubject.asObservable().pipe(
      map(privileges => {
        if (!privileges) {
          return [];
        }

        const departmentArray: Department[] = [];

        privileges.resourcePrivileges.forEach(privilege => {
          privilege.context.departments.forEach(department => {
            if (!departmentArray.find(d => d.code === department.code)) {
              departmentArray.push(department);
            }
          });
        });

        return departmentArray;
      })
    );
  }

  getDepartmentsByPrivilege(privilegeCode: string): Observable<Department[]> {
    return this.privilegesSubject.asObservable().pipe(
      map(privileges => {
        if (!privileges) {
          return [];
        }

        const departmentArray: Department[] = [];

        const privilege = privileges.resourcePrivileges.find(
          p => p.operationName === privilegeCode
        );
        if (privilege) {
          privilege.context.departments.forEach(department => {
            if (!departmentArray.find(d => d.code === department.code)) {
              departmentArray.push(department);
            }
          });
        }

        return departmentArray;
      })
    );
  }
}
