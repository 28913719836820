import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener
} from '@angular/core';
import { BatchResponse, Department, ListBatchesParams } from '../../models/sdk-interfaces';
import { BatchService } from '../../services/batch/batch.service';
import { BatchStatus } from '../../constants';
import { UserPrivilegesService } from '../../services/auth-privileges/auth-privileges.service';
import { Subscription } from 'rxjs';
import { FronteggAuthService } from '@frontegg/angular';
import { ActivatedRoute } from '@angular/router';
import { Table } from 'primeng/table';

interface BatchColumn {
  field: string;
  header: string;
}

@Component({
  selector: 'app-batch-list',
  templateUrl: './batch-list.component.html',
  styleUrls: ['./batch-list.component.scss']
})
export class BatchListComponent implements OnInit {
  @Input() tenantId: string = '';
  @Output() openBatchEvent = new EventEmitter<string>();

  @ViewChild('tableContainer', { static: true }) tableContainer!: ElementRef;
  @ViewChild('dt') dt!: Table;

  private subscription = new Subscription();

  user?: any;
  batches: BatchResponse[] = [];
  cols: BatchColumn[] = [];
  isLoading: boolean = false;
  offset: number = 0;
  count: number = 500;
  hasMoreData: boolean = true;
  canListBatches: boolean = false;
  canManageCompletedBatches: boolean = false;
  isOpeningSettings: boolean = false;
  userDepartments: Department[] = [];
  allowedStatuses: string[] = [];
  activeBatchSettings: number = -1;

  filters: { [key: string]: string } = {};
  globalFilterValue: string = '';

  currentTab: string = 'available';

  baseCols: BatchColumn[] = [
    { field: 'batchId', header: 'id' },
    { field: 'name', header: 'batchName' },
    // { field: 'createdBy', header: 'batchCreator' },
    { field: 'createdAt', header: 'creationDate' },
    { field: 'status', header: 'status' },
    { field: 'filingProgress', header: 'filingProgress' }
  ];

  constructor(
    private fronteggAuthService: FronteggAuthService,
    private batchService: BatchService,
    public userPrivilegesService: UserPrivilegesService,
    private route: ActivatedRoute,
  ) {}

  getBatchUrl(batch: any): string {
    return `webcapture/batches/${batch.batchId}`;
  }

  getSortOrder(field: string): number {
    if (!this.dt || !this.dt.multiSortMeta) return 0;
  
    const sortMeta = this.dt.multiSortMeta.find((meta: any) => meta.field === field);
    if (sortMeta) {
      return sortMeta.order;
    }
    return 0;
  }

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') || '';
    const organization = localStorage.getItem('vanityName');

    if (this.tenantId && organization) {
      this.subscription.add(
        this.fronteggAuthService.authState$.subscribe(authState => {
          if (authState.user?.accessToken) {
            this.user = authState.user;
          }
        })
      );
    }

    this.userPrivilegesService.getDepartmentsByPrivilege('LIST_BATCHES').subscribe(departments => {
      this.userDepartments = departments;

      this.allowedStatuses = [];
      departments.forEach(dept => {
        if (dept.status && dept.status.length) {
          dept.status.forEach(status => {
            if (!this.allowedStatuses.includes(status)) {
              this.allowedStatuses.push(status);
            }
          });
        }
      });

      if (this.allowedStatuses.includes(BatchStatus.Available)) {
        this.currentTab = 'available';
      } else if (this.allowedStatuses.includes(BatchStatus.CheckedOut)) {
        this.currentTab = 'checked_out';
      } else if (this.allowedStatuses.includes(BatchStatus.Completed)) {
        this.currentTab = 'completed';
      }

      const tabParam = this.route.snapshot.queryParams['tab'];

      if (this.userDepartments.length > 0 && !tabParam) {
        this.loadData();
        this.cols = [...this.baseCols];
      } else {
        if (tabParam && this.allowedStatuses.includes(tabParam)) {
          this.currentTab = tabParam;
          this.filterByTab(tabParam);
        }
      }
    });
  }

  @HostListener('document:click')
  public onClick(): void {
    if (!this.isOpeningSettings) {
      this.closeAllSettings();
    }
  }

  filterByTab(tab: string): void {
    this.currentTab = tab;
    this.updateCols();

    let status: string | undefined;

    switch (tab) {
      case 'available':
        status = `${BatchStatus.Available}`;
        break;
      case 'completed':
        status = `${BatchStatus.Completed}`;
        break;
      case 'checked_out':
        status = `${BatchStatus.CheckedOut}`;
        break;
      default:
        status =
          BatchStatus[(tab.charAt(0).toUpperCase() + tab.slice(1)) as keyof typeof BatchStatus];
        break;
    }

    this.resetData(status);
  }

  /**
   * Updates the columns to display based on the current tab.
   */
  updateCols(): void {
    this.cols = [...this.baseCols];

    if (this.currentTab === 'checked_out') {
      this.cols.push(
        // { field: 'checkedOutBy', header: 'checkedOutBy' },
        { field: 'settings', header: '' }
      );
    }
  }

  resetData(status?: string): void {
    this.batches = [];
    this.offset = 0;
    this.hasMoreData = true;
    this.loadData(status);
  }

  loadData(status?: string): void {
    if (this.isLoading || !this.hasMoreData) {
      return;
    }

    this.isLoading = true;

    const params: ListBatchesParams = {
      offset: this.offset,
      count: this.count,
      status: status || this.getCurrentStatusFilter()
    };

    this.batchService
      .listBatches(params)
      .then(response => {
        const newBatches = response.items;

        if (newBatches.length < this.count) {
          this.hasMoreData = false; // No more data to load
        }

        this.batches = this.filterBatchesByDepartment(
          [...this.batches, ...newBatches],
          this.userDepartments
        );

        this.offset += this.count;
        this.isLoading = false;

        setTimeout(() => {
          const element = this.tableContainer.nativeElement;
          // If there is still more data AND
          //  the table is not scrollable => load more
          if (element.scrollHeight <= element.clientHeight && this.hasMoreData) {
            this.loadData(status); // recursively fetch the next chunk
          }
        });
      })
      .catch(error => {
        console.error('Error fetching batches:', error);
        this.isLoading = false;
      });
  }

  filterBatchesByDepartment(batches: BatchResponse[], departments: Department[]): BatchResponse[] {
    const privileges = this.userPrivilegesService.getCurrentPrivileges();
    if (!privileges) {
      return [];
    }

    const listBatchesPrivilege = privileges.resourcePrivileges.find(
      p => p.operationName === 'LIST_BATCHES'
    );
    if (!listBatchesPrivilege) {
      return [];
    }

    // Filter out only the batches that match the privilege’s department & status
    return batches.filter(batch => {
      const dep = listBatchesPrivilege.context.departments.find(d => d.code === batch.department);
      if (!dep) {
        return false;
      }

      if (dep.status && dep.status.length > 0) {
        return dep.status.includes(batch.status!);
      } else {
        return false;
      }
    });
  }

  getFieldValue(batch: BatchResponse, field: string): any {
    if (field in batch) {
      return (batch as any)[field];
    }
    return '';
  }

  getCurrentStatusFilter(): string | undefined {
    switch (this.currentTab) {
      case 'active':
        return `${BatchStatus.Available},${BatchStatus.CheckedOut}`;
      case 'all':
        return undefined;
      case 'checked_out':
        return `${BatchStatus.CheckedOut}`;
      default:
        return BatchStatus[
          (this.currentTab.charAt(0).toUpperCase() +
            this.currentTab.slice(1)) as keyof typeof BatchStatus
        ];
    }
  }

  getFilingProgress(batch: BatchResponse): string {
    const documentCount = batch.statistics?.documentCount || 0;
    const unprocessedDocumentCount = batch.statistics?.unprocessedDocumentCount || 0;
    const processedDocCount = documentCount - unprocessedDocumentCount;
    return `${processedDocCount}/${documentCount}`;
  }

  onScroll(): void {
    const element = this.tableContainer.nativeElement;
    const scrollPos = element.scrollTop + element.clientHeight;
    const threshold = 5;
    if (element.scrollHeight - scrollPos <= threshold) {
      this.loadData();
    }
  }

  openBatch(event: Event, batch: BatchResponse): void {
    event.preventDefault();
    if (this.canOpenBatch(batch)) {
      this.openBatchEvent.emit(batch.batchId);
    }
  }

  getStatusBadgeClass(status: string | undefined): string {
    if (!status) {
      return 'status-badge';
    }

    switch (status.toLowerCase()) {
      case 'available':
      case 'checked_out':
        return 'status-badge available';
      case 'draft':
        return 'status-badge draft';
      case 'deleted':
        return 'status-badge deleted';
      case 'completed':
        return 'status-badge completed';
      default:
        return 'status-badge';
    }
  }

  showAvailableList(): boolean {
    return this.allowedStatuses.includes(BatchStatus.Available);
  }

  showCheckedOutList(): boolean {
    return this.allowedStatuses.includes(BatchStatus.CheckedOut);
  }

  showCompletedList(): boolean {
    return this.allowedStatuses.includes(BatchStatus.Completed);
  }

  canOpenBatch(batch: BatchResponse): boolean {
    if (this.currentTab === 'checked_out') {
      return false;
    } else {
      let canView = false;
      this.userPrivilegesService
        .canPerformAction('VIEW_BATCH', batch.department, batch.status)
        .subscribe(canViewBatch => {
          canView = canViewBatch;
        });
      return canView;
    }
  }

  /**
   * Shows the settings menu for a batch.
   * @param batchRowIndex The index of the batch in the list.
   */
  showBatchSettings(batchRowIndex: number): void {
    this.isOpeningSettings = !document
      .getElementById(`batch-settings-${batchRowIndex}`)
      ?.classList.contains('show');
    this.activeBatchSettings = batchRowIndex;
  }

  /**
   * Closes all settings menus.
   */
  closeAllSettings(): void {
    if (!this.isOpeningSettings) {
      this.activeBatchSettings = -1;
      const settingsMenus = document.getElementsByClassName('batch-settings-menu');
      for (let i = 0; i < settingsMenus.length; i++) {
        settingsMenus[i].classList.remove('show');
      }
    } else {
      this.isOpeningSettings = false;
    }
  }

  /**
   * Checks in a batch.
   * @param batchId The ID of the batch to check in.
   */
  checkInBatch(batchId: string): void {
    this.batchService.checkinBatch(batchId).then(() => {
      this.resetData();
    });
  }
}
