<div class="header">
  <img
    class="header-logo"
    src="../../../assets/header-logo.svg"
    routerLink="/printer"
    alt="header-logo"
  />
  <span>{{ (isPrinter() ? 'printerHeader' : 'sendToEtrieve') | translate }}</span>
  <div
    class="nav-buttons"
    *ngIf="includeSettings"
  >
    <p-button 
      class="header-icon"
      (click)="toggleShowSettings()" 
      [ariaLabel]="'settings' | translate"
      [pTooltip]="'settings' | translate"
      [attr.aria-haspopup]="'true'"
      [attr.aria-expanded]="showSettings ? 'true' : 'false'"
      tooltipPosition="top"
      [ngClass]="{ 'active': showSettings }"
      variant="text"
      severity="contrast"
      icon="fa-solid fa-gear">
    </p-button>
    <div
      class="settings-menu"
      *ngIf="showSettings"
      (focusout)="checkNavButtonsFocus($event)"
      (keydown)="handleKeydown($event)"
      tabindex="-1"
      aria-labelledby="settings-button"
      [attr.aria-expanded]="showSettings ? 'true' : 'false'"
    >
      <ul class="settings-list" role="menu">
        <li class="settings-list-items" role="menuitem">
          <a
            href="#"
            (click)="changeContentUrl(); $event.preventDefault()"
            [attr.aria-label]="'contentUrl' | translate"
            class="settings-option"
            role="menuitem"
            [attr.tabindex]="showSettings ? 0 : -1"
          >
            <span>
              <i class="fa-solid fa-browser" aria-hidden="true"></i>
              {{ 'contentUrl' | translate }}
            </span>
          </a>
        </li>
      </ul>
    </div>
</div>
