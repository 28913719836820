<div class="container">
  <div class="key-fields-header">
    <div class="head-label">
      <span>{{ 'documentDetails' | translate }}</span>
    </div>
  </div>
  @if(!isDocumentCompleted) {
  <form
  [formGroup]="docFormGroup"
  (ngSubmit)="uploadDocument()"
  *ngIf="attachmentStatus || !isAutoClassificationEnabled"
  class="key-fields-form"
  (keydown.enter)="$event.preventDefault()"
  >
  <div class="key-fields-container">
    <div class="input-container area">
      <label class="input-label" for="areaSelector">{{ 'area' | translate }}</label>
      <p-select
        class="input-field"
        formControlName="area"
        id="areaSelector"
        [options]="docAreas"
        optionLabel="name"
        optionValue="code"
        [filter]="true" 
        filterBy="name"
        [appendTo]="'body'"
        placeholder="{{ 'defaultAreaPlaceholder' | translate }}"
        (onChange)="onAreaChange($event)"
      ></p-select>  
    </div>
    @if(docFormGroup.get('area')?.value) {
      <div class="input-container type">
        <label class="input-label" for="documentTypeSelector">{{ 'documentType' | translate }}</label>
        <p-select
          class="input-field"
          [options]="filteredDocumentTypes" 
          optionLabel="name" 
          optionValue="code"
          [filter]="true" 
          filterBy="name"
          formControlName="documentTypeCode"
          id="documentTypeSelector"
          [appendTo]="'body'"
          placeholder="Select a document type"
          (onChange)="onDocumentTypeChange($event)"
        >
        </p-select>
      </div>
    }   
 
      <div
        class="input-container"
        *ngFor="let keyField of currentDocumentType?.keyfields"
      >
        <label [for]="keyField.code" class="input-label">{{ keyField.name | translate }}</label>
        <p-autocomplete
          #autoComplete
          #Lookup
          *ngIf="keyField.dataType === 'Lookup'"
          [formControlName]="keyField.code"
          [ngClass]="{ 'input-required': hasErrors(keyField.code) }"
          [suggestions]="getLookupSuggestions(keyField.code)"
          (completeMethod)="searchLookup($event, keyField)"
          field="name"
          [minLength]="2"
          dropdownMode="current"
          [forceSelection]="true"
          (click)="autoComplete.show()"
        />
        <p-datepicker
          *ngIf="keyField.dataType === 'Date'"
          formControlName="{{ keyField.code }}"
          [ngClass]="{ 'input-required': hasErrors(keyField.code) }"
          placeholder="mm/dd/yyyy"
          [showIcon]="true"
          (keydown)="validateDate($event)">
        </p-datepicker>
        <p-inputNumber
          *ngIf="keyField.dataType === 'Number'"
          type="number"
          [formControlName]="keyField.code"
          [ngClass]="{ 'input-required': hasErrors(keyField.code) }"
        />
        <select
          *ngIf="keyField.dataType === 'Text' && keyField.constraint"
          [formControlName]="keyField.code"
        >
          <option
            *ngFor="let constraintOption of keyField.constraint"
            [value]="constraintOption"
          >
            {{ constraintOption }}
          </option>
        </select>
        <p-inputNumber
          *ngIf="keyField.dataType === 'Money'"
          type="text"
          [formControlName]="keyField.code"
          (blur)="transformToCurrency($event)"
          [ngClass]="{ 'input-required': hasErrors(keyField.code) }"
          mode="currency"
          currency="USD"
        />
        <input
          *ngIf="
            (keyField.dataType === 'Text' || keyField.dataType === 'Decimal') &&
            !keyField.multiline &&
            !keyField.multivalue &&
            !keyField.constraint
          "
          type="text"
          pInputText
          [formControlName]="keyField.code"
          [ngClass]="{ 'input-required': hasErrors(keyField.code) }"
          maxFractionDigits="20"
        />
        <div
          *ngIf="keyField.multivalue"
          class="multiple-value-container"
        >
          <input
            *ngIf="keyField.dataType === 'Text'"
            (keydown.enter)="addMultipleValue(keyField.code)"
            [formControlName]="keyField.code"
          />
          <div
            *ngFor="
              let value of multiValueFields[
                keyField.code + '-' + currentDocumentType?.code + '-' + documentId
              ];
              let i = index
            "
            class="multiple-option-container"
          >
            <p-button
              icon="fa-regular fa-xmark"
              (click)="removeMultipleValue(keyField.code, i)"
              severity="danger"
              variant="text"
              size="small">
            </p-button>
            <span class="value">
              {{ value }}
            </span>
          </div>
        </div>
        <textarea
          *ngIf="keyField.dataType === 'Text' && keyField.multiline"
          pTextarea 
          [formControlName]="keyField.code"
          [ngClass]="{ 'input-required': hasErrors(keyField.code) }">
        </textarea>
        <span
          class="error-label"
          *ngIf="hasErrors(keyField.code)"
          >{{ errorText(keyField.code) }}</span
        >
      </div>
    </div>
    <div *ngIf="!disableFileByPrivilege" class="button-container">
      <p-button
        *ngIf="showDeleteDocumentButton"
        class="delete-document-button"
        
        icon="fa-solid fa-trash"
        (click)="deleteDocument()"
        [attr.aria-label]="'deleteDocument' | translate"
        severity="danger"
        text="true"
        size="large"
      ></p-button>

      <p-button
        type="submit"
        [disabled]="
          !docFormGroup.controls['area'].value ||
          !docFormGroup.valid ||
          isUploading ||
          disableFileByPrivilege
        "
        [attr.aria-label]="'send' | translate"
        label="{{ 'send' | translate }}"
        severity="secondary"
        size="large"
      ></p-button>
    </div>
  </form>
  @if(!disableFileByPrivilege && !attachmentStatus && isAutoClassificationEnabled) {
    <div class="spinner-container">
      <ngx-spinner
        color="#006CFA"
        bdColor="rgba(255,255,255, 0.3)"
        [fullScreen]="false"
        [showSpinner]="!attachmentStatus && !isUploading && isAutoClassificationEnabled"
        type="ball-spin-clockwise"
        size="small"
      ></ngx-spinner>
      <span class="spinner-label">
        {{ 'classificationInProgress' | translate }}
      </span>
      <div class="cancel-classification-container">
        <p-button
          [label]="'cancel' | translate" 
          (click)="cancelFileAutoClassification()"
          ariaLabel="'cancel' | translate" 
          variant="outlined"
          severity="secondary"
          class="cancel-autoclassify-button"
        >
        </p-button>
      </div>
  </div> 
  }
} @else {
  <div class="centered-button-container">
    <p class="success-message"> {{'documentUploadedMessage' | translate}}</p>
      <a [href]="dynamicUrl" target="_blank" rel="noopener noreferrer" class="p-button content-url-button">
        {{"goToDocument" | translate}}
      </a>
  </div>
}
</div>
