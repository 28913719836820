// External Libraries
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  HostListener,
  EventEmitter,
  Output,
  Signal,
  DestroyRef,
  inject
} from '@angular/core';

import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BehaviorSubject,
  Subscription,
  catchError,
  concatMap,
  from,
  combineLatest,
  lastValueFrom,
  map,
  of,
  switchMap,
  toArray,
  delay
} from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmationService, MessageService } from 'primeng/api';

// Project Services
import { BatchService } from '../../../services/batch/batch.service';
import {
  Area,
  ContentApiService,
  DocumentType,
  KeyField as DocumentTypeKeyField
} from '../../../services/content-api/content-api.service';
import { DocumentManagementService } from '../../../services/document-management/document-management.service';
import { FeatureFlagService } from '../../../services/feature-flag/feature-flag.service';
import { TenantApiService } from '../../../services/tenant-api/tenant-api.service';
import { SignOutService } from '../../../services/sign-out-state/sign-out-state.service';
import { UserPrivilegesService } from '../../../services/auth-privileges/auth-privileges.service';

// Project Models
import { MappingKeyField, MapResponse } from '../../../models/docTypeMapping';
import { DocumentResponse } from '../../../models/documentResponse';
import { ErrorResponse } from '../../../models/errorResponse';
import {
  BatchDocumentRequest,
  BatchDocumentResponse,
  BatchResponse,
  BulkCreateBatchDocumentRequest,
  Department,
  UpdateBatchDocumentRequest
} from '../../../models/sdk-interfaces';
import { KeyField, StatusResponse } from '../../../models/statusResponse';

// Project Components
import { Attachment } from '../../../models/attachment';
import { FileSelectorAttachment } from '../../../components/file-selector/file-selector.component';
import { KeyFieldsComponent } from '../../../components/key-fields/key-fields.component';

// Utilities
import { buildKeyFields } from '../../../utils/documentUtils';

import { ActivatedRoute, Router } from '@angular/router';
import { FronteggAuthService } from '@frontegg/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { AutoclassifierSdkService } from '../../../services/autoclassifier-sdk/autoclassifier-sdk.service';
import { SelectChangeEvent } from 'primeng/select';
import { KeyFieldsService } from '../../../services/key-fields/key-fields.service';

interface Document {
  contentUri: string;
  fileName: string;
  fileId: string;
  fileContent: string;
  contentType: string;
  requestId?: string;
  status?: StatusResponse;
  completed: boolean;
  classificationStarted: boolean;
  canceled?: boolean;
  mappedItems?: MapResponse['mapItems'];
  ecmDocId?: number;
}

@Component({
  selector: 'app-open-batch',
  templateUrl: './open-batch.component.html',
  styleUrls: ['./open-batch.component.scss']
})
export class OpenBatchComponent implements OnInit, OnDestroy {
  @ViewChild(KeyFieldsComponent) keyFieldsComponent!: KeyFieldsComponent;
  @Output() changeEvent = new EventEmitter<any>();

  private activeKeys: Set<string> = new Set();
  private subscription = new Subscription();
  private signOutSubscription = new Subscription();

  documentsReceived$ = new BehaviorSubject<boolean>(false);

  user?: any;
  batchId: string = '';
  currentAttachmentIndex: number = -1;
  formStates: Map<number, any> = new Map();
  documentForm!: FormGroup;
  batch: BatchResponse = {} as BatchResponse;
  batchDocuments: BatchDocumentResponse[] = [];
  documents: Document[] = [];
  tenantId: string = '';
  loadingFilingFiles: boolean = false;
  fillingDocsIds: string[] = [];
  areas: Area[] = [];
  settingsAreas: Area[] = [];
  settingsDepartments: Signal<Department[]> = toSignal(
    this.userPrivilegesService
      .getDepartmentsByPrivilege('CHANGE_BATCH_ACCESS')
      .pipe(
        map(departments => 
          departments.sort((a, b) => a.name.localeCompare(b.name))
        )
      ),
    { initialValue: [] }
  );  
  documentTypes: DocumentType[] = [];
  filteredDocumentTypes: DocumentType[] = [];
  batchEditFilteredDocumentTypes: DocumentType[] = [];
  currentDocumentType: DocumentType | undefined;
  showEditBatchModal: boolean = false;
  editBatchFormGroup!: FormGroup;
  showAddDocumentsModal: boolean = false;
  showMergeDocuments: boolean = false;
  statusCheckInterval: any;
  isClosingBatch: boolean = false;
  isDeletingBatch: boolean = false;
  enableBatchOptions: boolean = false;
  showUnsupportedFileMessage: boolean = false;
  currentMostConfidentDocument: any;
  showClassificationResults: boolean = false;
  batchLoading: boolean = false;
  isFetchingDocuments: boolean = false;

  constructor(
    private fronteggAuthService: FronteggAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private contentService: ContentApiService,
    private tenantService: TenantApiService,
    private batchService: BatchService,
    private featureFlagService: FeatureFlagService,
    private docMngmtService: DocumentManagementService,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private signOutService: SignOutService,
    private messageService: MessageService,
    private autoclassifierSdkService: AutoclassifierSdkService,
    public userPrivilegesService: UserPrivilegesService,
    public keyFieldsService: KeyFieldsService
  ) {
    this.batchId = this.route.snapshot.paramMap.get('batchId') ?? '';
  }
  destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') || '';
    const organization = localStorage.getItem('vanityName');
    this.keyFieldsService.setTenantId(this.tenantId);

    if (this.tenantId && organization) {
      this.subscription.add(
        this.fronteggAuthService.authState$.subscribe(authState => {
          if (authState.user?.accessToken) {
            this.user = authState.user;
          }
        })
      );

      this.keyFieldsService.currentDocumentType$.subscribe(documentType => {
        this.currentDocumentType = documentType;
      });
      this.keyFieldsService.documentForm$.subscribe(form => {
        this.documentForm = form || this.fb.group({}); //form should never be null really
      });
      
      this.keyFieldsService.formStates$.subscribe(formStates => {
        this.formStates = formStates;
      }
      );
    }

    this.batch.batchId = this.batchId;
    this.openBatch(this.batchId);
    this.signOutService.isFilingContent = true;


    this.signOutSubscription = this.signOutService.signOut$.subscribe(signOut => {
      if (signOut) {
        this.canDeactivate();
      }
    });
  }

  ngOnDestroy(): void {
    this.endStatusCheckInterval();
    this.subscription.unsubscribe();
    this.signOutSubscription.unsubscribe();
    this.isFetchingDocuments = false;
  }

  openBatch(batchId: string): void 
  {
    this.batchLoading = true;
    //adding
    this.formStates.clear();
    this.documents = [];
    this.keyFieldsService.clearDocuments(); 

    this.batchService.getBatch(batchId).then(response => 
    {
        this.loadingFilingFiles = true;
        const batch = response;
        this.batch = batch;
      
        // if get batch is successful, get batch documents
        this.batchService.getBatchDocuments(this.batch.batchId!).then(response => 
        {
            // Ensure documents are fetched before proceeding
            this.fetchDocumentsFromManagementSdk(this.fillingDocsIds).then(() => 
            {
                this.batchDocuments = response.items;

                // make list of document ids
                this.fillingDocsIds = response.items.map(item => item.documentId);
    
                if (this.batch.status !== 'completed' && this.batch.status !== 'checked_out') 
                {
                    // check out the batch
                    this.checkOutBatch(this.batch.batchId!).then(() => 
                    {
                        // Now compute the enableBatchOptions flag using privilege checks.
                        this.runPrivilegeChecks();
                    });
                } 
                else 
                {
                    // For completed batches, skip checkout and run privilege checks immediately.
                    this.runPrivilegeChecks();
                }

                this.initializeDocumentForm();
                this.loadData();
                this.batchLoading = false;
            });
        });
    });
  }


  /**
   * Runs the privilege checks to compute enableBatchOptions.
   */
  private runPrivilegeChecks(): void {
    combineLatest([
      this.userPrivilegesService.canPerformAction(
        'EDIT_BATCH',
        this.batch.department,
        this.batch.status
      ),
      this.userPrivilegesService.canPerformAction(
        'CHANGE_BATCH_ACCESS',
        this.batch.department,
        this.batch.status
      ),
      this.userPrivilegesService.canPerformAction(
        'MANAGE_BATCH_DOCUMENTS',
        this.batch.department,
        this.batch.status
      ),
      this.userPrivilegesService.canPerformAction(
        'DELETE_BATCH',
        this.batch.department,
        this.batch.status
      )
    ])
      .pipe(
        map(([canEdit, canChange, canManage, canDelete]) => {
          // For add documents, only enable if the batch isn’t completed.
          const showAddDocuments = this.batch.status !== 'completed' && canManage;
          return canEdit || canChange || showAddDocuments || canDelete;
        })
      )
      .subscribe(result => {
        this.enableBatchOptions = result; 
        this.cdr.detectChanges(); 
      });
  }

  /**
   * Checks if the batch is completed
   * @returns {boolean} - Returns true if the batch is completed, otherwise false
   */
  isBatchCompleted(): boolean {
    return this.batch.status === 'completed';
  }

  checkInBatch(): Promise<void> {
    return this.batchService
      .checkinBatch(this.batch.batchId!)
      .then(() => {
        console.log('Batch checked in');
        if (this.isClosingBatch) {
          this.router.navigate(['/webcapture', 'batches']);
        }
      })
      .catch(error => {
        console.error('Error checking in batch:', error);
        throw error;
      });
  }

  closeBatch(): void {
    this.isClosingBatch = true;
    if (this.isBatchCompleted()) {
      this.router.navigate(['/webcapture', 'batches'], { queryParams: { tab: 'completed' } });
    } else {
      this.checkInBatch();
    }
  }

  openEditBatchModal(): void {
    this.showEditBatchModal = true;
    this.editBatchFormGroup = this.fb.group({
      name: [''],
      department: [''],
      defaultArea: [''],
      defaultDocumentType: ['']
    });

    if (this.isBatchCompleted()) {
      this.editBatchFormGroup.disable();
    } else {
      this.userPrivilegesService
        .canPerformAction('EDIT_BATCH', this.batch.department)
        .subscribe(canEdit => {
          if (!canEdit) {
            this.editBatchFormGroup.controls['name'].disable();
            this.editBatchFormGroup.controls['defaultArea'].disable();
            this.editBatchFormGroup.controls['defaultDocumentType'].disable();
          }
        });

      this.userPrivilegesService
        .canPerformAction('CHANGE_BATCH_ACCESS', this.batch.department, this.batch.status)
        .subscribe(canChange => {
          if (!canChange) {
            this.editBatchFormGroup.controls['department'].disable();
          }
        });
    }

    if (this.batch.defaultArea) {
      this.batchEditFilteredDocumentTypes.push(
        ...this.documentTypes.filter(
          documentType => documentType.areaCode === this.batch.defaultArea
        )
      );
    }

    this.editBatchFormGroup.get('defaultArea')?.valueChanges.subscribe(area => {
      this.batchEditFilteredDocumentTypes = this.documentTypes.filter(
        documentType => documentType.areaCode === area
      );
    });

    this.editBatchFormGroup.patchValue({
      name: this.batch.name,
      department: this.batch.department ? this.batch.department : '',
      defaultArea: this.batch.defaultArea ? this.batch.defaultArea : '',
      defaultDocumentType: this.batch.defaultDocumentType ? this.batch.defaultDocumentType : ''
    });
  }

  closeEditBatchModal(): void {
    this.batchEditFilteredDocumentTypes = [];
    this.showEditBatchModal = false;
  }

  saveBatchSettings(): void {
    this.batchService
      .updateBatch(this.batch.batchId!, {
        name: this.editBatchFormGroup.controls['name'].value,
        version: this.batch.version,
        defaultDocumentType: this.editBatchFormGroup.controls['defaultDocumentType'].value,
        department: this.editBatchFormGroup.controls['department'].value,
        defaultArea: this.editBatchFormGroup.controls['defaultArea'].value
      })
      .then(response => {
        console.log('Batch updated:', response);
        this.batch = response;

        this.closeEditBatchModal();
      })
      .catch(error => {
        console.error('Error updating batch:', error);
        this.showErrorToast('updateBatchError');
      });
  }

  openAddDocumentsModal(): void {
    this.showAddDocumentsModal = true;
  }

  openMergeDocuments() {
    this.showMergeDocuments = true;
  }

  closeMergeDocuments() {
    this.showMergeDocuments = false;
  }

  checkOutBatch(batchId: string): Promise<BatchResponse> {
    return this.batchService
      .checkoutBatch(batchId)
      .then(response => {
        this.batch = response;
        return response;
      })
      .catch(error => {
        console.error('Error checking out batch:', error);
        throw error;
      });
  }

  confirmDeleteBatch(): void {
    this.confirmationService.confirm({
      message: '',
      key: 'deleteBatchConfirmDialog',
      accept: () => {
        this.isDeletingBatch = true;
        this.deleteBatch();
      }
    });
  }

  deleteBatch(): void {
    this.endStatusCheckInterval();
    this.batchService
      .deleteBatch(this.batch.batchId!)
      .then(() => {
        console.log('Batch deleted');
        this.documents.forEach(doc => {
          this.autoclassifierSdkService.documentManagementSdk
            .deleteDocument(this.tenantId, doc.fileId)
            .then(() => {
              console.log('Document deleted:', doc.fileId);
            });
        });
        this.router.navigate(['/webcapture', 'batches']);
      })
      .catch(error => {
        console.error('Error deleting batch:', error);
      });
  }

  confirmCloseBatch() {
    this.confirmationService.confirm({
      message: 'closeBatchWarning',
      key: 'closeBatchDialog',
      accept: () => {
        this.closeBatch();
      }
    });
  }

    // TODO: Complete actions for canceling merge
  // confirmCancelMerge() {
  //   this.confirmationService.confirm({
  //     message: 'closeMergeWarning',
  //     key: 'closeMergeDialog',
  //     accept: () => {
  //       this.closeMerge();
  //     }
  //   });
  // }

  /**
   * Initializes the document form with default values
   */
  initializeDocumentForm() {
    console.log("initializeDocumentForm called in open-batch component");
    this.documentForm = this.fb.group({
      area: [''],
      documentTypeCode: ['']
    });
  }

  /**
   * Initializes data to be used by the component
   */
  loadData(): void {
    this.featureFlagService.identify({
      key: this.user.customClaims.principal_id,
      name: this.user.name,
      tenantId: this.tenantId
    });

    this.subscription.add(
      this.tenantService
        .getTenantUrls(this.tenantId)
        .pipe(
          tap(tenantUrls => {
            this.contentService.setBaseUrl(tenantUrls.contentApiUrl);
          }),
          // Create an observable that emits after 750ms to avoid rate limit errors for large document types
          // We are going to fix large document types in the future, but for now this is a workaround
          delay(750),
          switchMap(() => {
            console.log("750ms delay completed, now calling getDocumentTypes()");
            return this.contentService.getDocumentTypes();
          }),

        //set areas and doctypes before getdocumentfromdocmgmtsdk is called
        switchMap(docTypes => {
          this.documentTypes = docTypes.sort((a, b) => a.name.localeCompare(b.name));
          console.log("setting documentttypes");
          this.keyFieldsService.setDocumentTypes(docTypes);
          return this.contentService.getAreas();
        }),
        tap(areas => {
          //don't think this needs to be a subscription, area changes are handled elsewhere. just initializing here
          this.settingsAreas = areas.sort((a, b) => a.name.localeCompare(b.name))
          this.areas = areas 
         }),
        takeUntilDestroyed(this.destroyRef))
        .pipe(
          switchMap(() => from(this.fillingDocsIds)),
          concatMap((docId: string) => this.getDocumentFromDocMgmtSdk(docId)),
          toArray(),
          catchError(error => {
            console.error('Error in chained requests', error);
            this.showErrorToast('dataError');
            return of([]); // Return an observable to keep the stream alive
          }),        
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(areas => {
          console.log("just called getDocumentFromDocmgmtSdk in loadData in open-batch component");
          if (!this.documents.every(doc => doc.status)) {
            this.startBatchDocumentStatusCheck();
          }

          
          this.loadingFilingFiles = false;
          this.documentsReceived$.next(true);

          //TODO: investigate if this is needed and if it can be refactored
          // this.userPrivilegesService
          //   .getDepartmentsByPrivilege('CHANGE_BATCH_ACCESS')
          //   .subscribe(departments => {
          //     this.settingsDepartments = departments;
          //   });

        })
    );
  }

  async fetchDocumentsFromManagementSdk(
    documentIds: string[],
    concurrencyLimit: number = 4
  ): Promise<void> {
    this.isFetchingDocuments = true;
    const results = [];
    const queue = [...documentIds];

    const processNextAmount = async () => {
      while (queue.length > 0 && this.isFetchingDocuments) {
        const batch = queue.splice(0, concurrencyLimit);
        const promises = batch.map(id =>
          this.getDocumentFromDocMgmtSdk(id)
            .then(data => ({ id, data }))
            .catch(error => ({ id, error }))
        );
        const responses = await Promise.allSettled(promises);
        if (!this.statusCheckInterval) {
          this.startBatchDocumentStatusCheck();
        }

        results.push(...responses);
      }
      this.isFetchingDocuments = false;
    };

    await processNextAmount();
  }

  /**
   * Checks the status of documents in a batch
   */
  startBatchDocumentStatusCheck(): void {
    this.statusCheckInterval = setInterval(() => {
      const documentsWithoutStatus = this.documents.filter(doc => !doc.status);
      Promise.all(
        documentsWithoutStatus.map(doc => this.getDocumentMetadataFromDocMgmtSdk(doc.fileId))
      ).then(() => {});
    }, 4000); // Run every 4 seconds

    setTimeout(() => {
      clearInterval(this.statusCheckInterval);
      this.documents.map(doc => {
        if (!doc.status) {
          doc.status = {
            documents: []
          };
        }
      });
    }, 30000); // Stop the interval after 30 seconds
  }

  /**
   * Retrieves the document from the Document Management SDK
   * @param {string} documentId - The ID of the document to retrieve
   */
  getDocumentFromDocMgmtSdk(documentId: string): Promise<void> {
    return this.autoclassifierSdkService.documentManagementSdk
      .getDocument(this.tenantId, documentId, 'contentmetadata')
      .then((response: DocumentResponse) => {
        const newDocument: Document = {
          contentUri: response.contentUri,
          fileName: response.fileName,
          fileId: response.id,
          contentType: response.type,
          fileContent: '',
          ecmDocId: response.ecmDocId,
          completed: this.batchDocuments.some(
            doc => doc.documentId === response.id && doc.status === 'filed'
          )
            ? true
            : false,
          classificationStarted: true
        };

        if (response.contentMetadata?.data && response.contentMetadata?.documentType) {
          newDocument.status = response.contentMetadata.data;
        }

        this.documents.push(newDocument);
        this.keyFieldsService.addDocument(newDocument);

        if (!newDocument.completed || this.isBatchCompleted()) {
          if (this.currentAttachmentIndex === -1) {
            console.log("calling selectAttachment from getDocumentFromDocMgmtSdk in open-batch component");
            this.selectAttachment(this.documents.length - 1);
          }
        }
      })
      .catch((error: ErrorResponse) => {
        console.error('Document get Error:', error);
        this.showErrorToast('getDocumentError');
      });
  }

  getDocumentMetadataFromDocMgmtSdk(documentId: string): Promise<DocumentResponse> {
    return this.autoclassifierSdkService.documentManagementSdk
      .getDocument(this.tenantId, documentId, 'contentmetadata')
      .then((response: DocumentResponse) => {
        if (response.contentMetadata?.data) {
          const docIndex = this.documents.findIndex(doc => doc.fileId === documentId);
          this.documents[docIndex].status = response.contentMetadata.data;
          if (
            docIndex === this.currentAttachmentIndex &&
            this.documents[this.currentAttachmentIndex].classificationStarted
          ) {
            console.log("callingbuildFormFields from getDocumentMetadataFromDocMgmtSdk in open-batch component");
            console.log(this.formStates);
            this.keyFieldsService.buildFormFields();
          }
        }
      });
  }

  /**
   * Retrieves the attachments for the file selector
   * @returns {FileSelectorAttachment[]} - An array of file selector attachments
   */
  getFileSelectorAttachments(): FileSelectorAttachment[] {
    const attachments = this.documents.map(
      doc =>
        ({
          name: doc.fileName,
          id: doc.fileId,
          completed: doc.completed,
          status: doc.status as StatusResponse,
          classificationStarted: doc.classificationStarted,
          canceled: doc.canceled,
          fileContent: doc.fileContent
        }) as FileSelectorAttachment
    );
    return attachments;
  }

  /**
   * Selects an attachment and builds form fields for that attachment
   * @param {number} index - The index of the selected attachment
   */
  selectAttachment(index: number): void {
    console.log("selectAttachment called in open-batch component");
    const selectedAttachment = this.documents[index];
    console.log(selectedAttachment);
    console.log(index);
    this.spinner.show('fileClassificationSpinner-' + selectedAttachment.fileName);


    if (!selectedAttachment.fileContent) {
      console.log("!selectedAttachment.fileContent was tripped");
      lastValueFrom(this.docMngmtService.getDocument(selectedAttachment.contentUri)).then(
        arrayBuffer => {
          const binaryString = Array.from(new Uint8Array(arrayBuffer))
            .map(byte => String.fromCharCode(byte))
            .join('');
          const base64 = btoa(binaryString);
          selectedAttachment.fileContent = base64;
          this.spinner.hide('fileClassificationSpinner-' + selectedAttachment.fileName);
          this.cdr.detectChanges();
  

          if (!selectedAttachment.completed || this.isBatchCompleted()) {
            this.spinner.show('fileClassificationSpinner-' + selectedAttachment.fileName);

            if (this.currentAttachmentIndex === -1) {
              this.selectAttachment(this.documents.length - 1);
            }
          }
        }
      );
    }
  
    this.showUnsupportedFileMessage = false;
    if (index >= 0 && index < this.documents.length) {
      this.formStates.set(this.currentAttachmentIndex, this.documentForm.value);

  
      console.log("this.documentForm.value: ");
      console.log(this.documentForm.value);
      console.log("this.formStates:");
      console.log(this.formStates);
      
      this.currentAttachmentIndex = index;
      this.keyFieldsService.setCurrentAttachmentIndex(index); 
      
      this.keyFieldsComponent.buildDynamicUrl(this.documents[index].ecmDocId ?? -1);

      if (this.documents[this.currentAttachmentIndex].classificationStarted) {
        console.log("calling buildformfields from selectAttachment in open-batch component");
        this.keyFieldsService.buildFormFields();
      } else {
        console.log("calling initializeDocumentForm in selectAttachment");
        this.initializeDocumentForm(); // Initialize the form if no classification has started
      }
    }
  }



  /**
   * Sets mapped key fields
   */
  setMappedKeyFields(attachment: Document, formControls: { [key: string]: FormControl }): void {
    console.log("setMappedKeyFields called in open-batch component");
    const document = this.keyFieldsService.getMostConfidentDocument(attachment);

    if (!document) {
      // If there's no document, just initialize empty form.
      this.applyEmptyFormControls(formControls);
      return;
    }

    const keyFields = this.keyFieldsService.extractKeyFields(document);

    this.keyFieldsService.mapKeyFields(document.classifyInfo?.documentType as string, keyFields).then(response => {
      if (response && response.mapItems.length > 0) {
        // Try each mapItem until we find a doc type we can use
        let chosenItem: MapResponse['mapItems'][0] | undefined;

        for (const mapItem of response.mapItems) {
          const docType = this.keyFieldsService.getDocumentTypeByCode(mapItem.docTypeCode);

          if (!docType) {
            continue; // No matching doc type found; try next item
          }

          // If batch has specific areas, ensure docType.areaCode is allowed
          if (this.batch.defaultArea) {
            if (
              this.batch.defaultArea === docType.areaCode &&
              (this.batch.defaultDocumentType ?? docType.code) === docType.code
            ) {
              chosenItem = mapItem;
              this.currentDocumentType = docType;
              break;
            }
          } else {
            // No area restrictions, we can choose this doc type
            chosenItem = mapItem;
            this.currentDocumentType = docType;
            break;
          }
        }

        attachment.mappedItems = response.mapItems;

        if (chosenItem && this.currentDocumentType) {
          this.keyFieldsService.populateFormControls(formControls, chosenItem.docTypeCode, chosenItem.keyFields);
          //make sure deleting this doesnt break stuff
          //this.documentForm = this.fb.group(formControls);

          if (this.isBatchCompleted()) {
            this.documentForm.disable();
          }
          this.keyFieldsService.handleLookupFields(chosenItem.keyFields);
        } else {
          // No suitable doc type found among mapItems
          if (this.batch.defaultArea && this.batch.defaultDocumentType) {
            this.keyFieldsService.initializeFormControls(this.batch.defaultArea, this.batch.defaultDocumentType);
          } else {
            this.applyEmptyFormControls(formControls);
          }
        }
      } else {
        // No mapItems returned, fallback to empty form
        this.applyEmptyFormControls(formControls);
      }
    });
  }

  private applyEmptyFormControls(formControls: { [key: string]: FormControl }): void {
    console.log("applyEmptyFormControls called in open-batch component");
    formControls['area'].setValue(this.batch.defaultArea ?? '');
    formControls['documentTypeCode'].setValue(this.batch.defaultDocumentType ?? '');

    if (this.batch.defaultArea && this.batch.defaultDocumentType) {
      this.keyFieldsService.initializeFormControls(this.batch.defaultArea, this.batch.defaultDocumentType);
    }

    this.currentDocumentType = undefined;
    this.documentForm = this.fb.group(formControls);
    if (this.isBatchCompleted()) {
      this.documentForm.disable();
    }
  }


  /**
   * Handles the keydown event for both macOS and Windows platforms
   * @param {KeyboardEvent} event - The keydown event
   */
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.activeKeys.add(event.key.toLowerCase());

    const isMac = this.isMacPlatform();

    // CMD + SHIFT + A + I
    const isMacShortcutPressed =
      isMac &&
      event.metaKey &&
      event.shiftKey &&
      this.activeKeys.has('a') &&
      this.activeKeys.has('i');

    // CTRL + SHIFT + A + I
    const isWindowsShortcutPressed =
      !isMac &&
      event.ctrlKey &&
      event.shiftKey &&
      this.activeKeys.has('a') &&
      this.activeKeys.has('i');

    if (isMacShortcutPressed || isWindowsShortcutPressed) {
      event.preventDefault();
      this.openDialog();
      this.activeKeys.clear();
    }
  }

  /**
   * Handles the keyup event by removing the key from the activeKeys set
   * @param {KeyboardEvent} event - The keyup event
   */
  @HostListener('window:keyup', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    this.activeKeys.delete(e.key.toLowerCase());
  }

  /**
   * Checks if the platform is macOS
   * @returns {boolean} - Returns true if the platform is macOS, otherwise false
   */
  isMacPlatform(): boolean {
    // Use navigator.userAgentData if available (future-proof)
    if ((navigator as any).userAgentData) {
      return (navigator as any).userAgentData.platform === 'macOS';
    }

    // Fallback to user agent string check if userAgentData is not available
    return /Mac|iPhone|iPod|iPad/.test(navigator.userAgent);
  }

  /**
   * Opens the classification results dialog.
   */
  openDialog() {
    if (!this.showClassificationResults) {
      this.currentMostConfidentDocument = this.keyFieldsService.getMostConfidentDocument(
        this.documents[this.currentAttachmentIndex]
      );
    } else {
      this.currentMostConfidentDocument = null;
    }
    this.showClassificationResults = !this.showClassificationResults;
  }

  /**
   * Closes the classification results dialog.
   */
  closeClassificationResults(): void {
    this.showClassificationResults = false;
  }

  /**
   * Handles changes in the form, such as area or document type selection
   * @param {Event} event - The change event from the form
   */
  formOnChange(event: any): void {
    this.keyFieldsService.formOnChange(event);
  }





  /**
   * Uploads an attachment with type, area, and key field information to Softdocs Etrieve Content.
   */
  uploadDocument(): void {
    if (this.documentForm?.valid) {
      const formData = buildKeyFields(
        this.documentForm.value,
        this.currentDocumentType as DocumentType
      );

      const multiValueFields = this.currentDocumentType?.keyfields.filter(kf => kf.multivalue);

      if (multiValueFields) {
        multiValueFields.forEach(multiValueField => {
          const multiValueFieldKey = `${multiValueField.code}-${this.currentDocumentType?.code}-${this.documents[this.currentAttachmentIndex]?.fileId}`;

          if (this.keyFieldsComponent.multiValueFields[multiValueFieldKey]) {
            this.keyFieldsComponent.multiValueFields[multiValueFieldKey].forEach(value => {
              formData.push({
                fieldCode: multiValueField.code,
                value: value
              });
            });
          }
        });
      }

      const attachment: Attachment = {
        content: {
          content: this.documents[this.currentAttachmentIndex].fileContent,
          format: 'base64'
        },
        attachmentType: this.documents[this.currentAttachmentIndex].contentType,
        requestId: this.documents[this.currentAttachmentIndex].requestId,
        name: this.documents[this.currentAttachmentIndex].fileName,
        contentType: this.documents[this.currentAttachmentIndex].contentType,
        id: '',
        isInline: false,
        size: 0
      };

      this.contentService
        .uploadDocument(
          attachment,
          this.documentForm.controls['area'].value as string,
          this.documentForm.controls['documentTypeCode'].value as string,
          formData
        )
        .pipe(
          tap(response => {
            console.log("Upload response received:", response);
          }),
          switchMap(response => {
            const ecmDocId = response.metadataResponse.id;
            console.log("document uploaded, ecmDocId: " + ecmDocId);
            const currentDocId = this.documents[this.currentAttachmentIndex].fileId;
            const request = {
              EcmDocId: ecmDocId
            }
            return from (
              this.autoclassifierSdkService.documentManagementSdk.updateDocument(this.tenantId, currentDocId, request)
            ).pipe(
              tap(() => {
                // Update the ecmDocId property in the documents array after successful update
                this.documents[this.currentAttachmentIndex].ecmDocId = ecmDocId;
                console.log(`Updated document at index ${this.currentAttachmentIndex} with ecmDocId: ${ecmDocId}`);
                
                // If you have a keyFieldsComponent reference and want to build dynamic URL
                // if (this.keyFieldsComponent && typeof this.keyFieldsComponent.buildDynamicUrl === 'function') {
                //   this.keyFieldsComponent.buildDynamicUrl(ecmDocId);
                //   console.log(`Dynamic URL updated for document with ecmDocId: ${ecmDocId}`);
                // }
              }),
              map(() => response),
              catchError(error => {
                console.error('Error updating document:', error);
                throw error;
              })
            );
          })
        )
        .subscribe({
          error: error => {
            console.error('Failed to upload document', error);
            this.showErrorToast('uploadError');
            this.keyFieldsComponent.onUploadComplete();
          },
          complete: () => {
            this.showUploadDoneToast();
            this.keyFieldsComponent.onUploadComplete();
            this.documents[this.currentAttachmentIndex].completed = true;
            this.keyFieldsService.patchDocuments(this.documents[this.currentAttachmentIndex], this.currentAttachmentIndex);

            const currentDocument = this.batchDocuments.find(
              doc => doc.documentId === this.documents[this.currentAttachmentIndex].fileId
            );
            const updateBatchDocumentData: UpdateBatchDocumentRequest = {
              version: currentDocument?.version || 1,
              source: currentDocument?.source,
              status: 'filed'
            };
            this.batchService
              .updateBatchDocument(
                this.batch.batchId!,
                this.documents[this.currentAttachmentIndex].fileId,
                updateBatchDocumentData
              )
              .then(response => {
                this.batch.version++;

                if (this.documents.findIndex(doc => !doc.completed) !== -1) {
                  this.selectAttachment(this.documents.findIndex(doc => !doc.completed));
                } else {
                  this.completeBatch();
                  this.isClosingBatch = true;
                  this.router.navigate(['/webcapture', 'batches']);
                }
              });
          }
        });
    } else {
      console.error('Form is not valid, cannot upload document.');
      this.keyFieldsComponent.onUploadComplete();
      this.showErrorToast('validationError');
    }
  }


  deleteDocument(): void {
    this.confirmationService.confirm({
      message: '',
      key: 'deleteBatchFileConfirmDialog',
      accept: () => {
        this.deleteDocumentFromBatch();
      }
    });
  }

  deleteDocumentFromBatch(): void {
    this.batchService
      .removeBatchDocument(this.batch.batchId!, this.documents[this.currentAttachmentIndex].fileId)
      .then(() => {
        this.autoclassifierSdkService.documentManagementSdk
          .deleteDocument(this.tenantId, this.documents[this.currentAttachmentIndex].fileId)
          .then(() => {
            console.log('Document deleted:', this.documents[this.currentAttachmentIndex].fileId);
            this.documents[this.currentAttachmentIndex].canceled = true;
            this.keyFieldsService.patchDocuments(this.documents[this.currentAttachmentIndex], this.currentAttachmentIndex);
            if (this.documents.findIndex(doc => !doc.completed && !doc.canceled) !== -1) {
              this.selectAttachment(
                this.documents.findIndex(doc => !doc.completed && !doc.canceled)
              );
            }
          });
      })
      .catch(error => {
        console.error('Error removing document from batch:', error);
      });
  }

  completeBatch(): void {
    this.batchService
      .updateBatch(this.batch.batchId!, {
        status: 'completed',
        version: this.batch.version
      })
      .then(response => {
        console.log('Batch completed:', response);
      });
  }

  addDocumentsToBatch(documentIds: string[]): void {
    const bulkCreateRequest: BulkCreateBatchDocumentRequest = {
      documents: []
    };

    documentIds.forEach(fileId => {
      const batchDocument: BatchDocumentRequest = {
        documentId: fileId,
        source: 'document'
      };
      bulkCreateRequest.documents.push(batchDocument);
    });

    this.batchService
      .bulkCreateBatchDocuments(this.batch.batchId!, bulkCreateRequest)
      .then(response => {
        console.log('Documents added to batch:', response);
        this.showAddDocumentsModal = false;

        Promise.all(
          bulkCreateRequest.documents.map(document =>
            this.getDocumentFromDocMgmtSdk(document.documentId)
          )
        ).then(() => {
          this.documentsReceived$.next(true);
          if (!this.statusCheckInterval) {
            this.startBatchDocumentStatusCheck();
          }
        });
      })
      .catch(error => {
        console.error('Error adding documents to batch:', error);
      });
  }

  /**
   * Shows a toast message indicating that the file upload is complete
   */
  showUploadDoneToast(): void {
    this.messageService.add({
      severity: 'success',
      summary: 'uploadToastTitleWebCapture',
      detail: 'uploadToastMessageWebCapture',
      key: 'uploadDoneToast',
      life: 10000
    });
  }

  /**
   * Shows an error toast message.
   * @param {string} toastMessage - The error message to display.
   * @param {string} [toastData] - Additional data to display in the toast.
   */
  showErrorToast(toastMessage: string, toastData?: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: toastMessage,
      key: 'errorToast',
      data: toastData ? toastData : '',
      life: 10000
    });
  }

  /**
   * Sets the current document status to an empty object, cancelling the auto-classification process
   */
  cancelFileAutoClassification(index: number): void {
    this.documents[index].status = {
      documents: []
    };
  }

  /**
   * Focuses the close button in the dialog
   */
  onDialogShow(): void {
    const closeButton = document.querySelector('.p-dialog-header-close') as HTMLElement;
    closeButton?.focus();
  }

  canDeactivate(): Promise<boolean> {
    if (this.isClosingBatch || this.isDeletingBatch) {
      return Promise.resolve(true);
    } else {
      return new Promise(resolve => {
        this.confirmationService.confirm({
          message: 'unsavedDataWarning',
          key: 'tabChangeDialog',
          accept: () => {
            if (this.isBatchCompleted()) {
              resolve(true);
            } else {
              this.checkInBatch()
                .then(() => {
                  if (this.signOutService.getSignOutState()) {
                    this.signOutService.signOut();
                  }
                  resolve(true);
                })
                .catch(() => {
                  resolve(false);
                });
            }
          },
          reject: () => {
            if (this.signOutService.getSignOutState()) {
              this.signOutService.setSignOutState(false);
            }
            resolve(false);
          }
        });
      });
    }
  }

    onDefaultAreaChange(event: SelectChangeEvent): void {
      const selectedAreaCode = event.value;
      this.batchEditFilteredDocumentTypes = this.documentTypes
      .filter((docType) => docType.areaCode === selectedAreaCode)
      .sort((a, b) => a.name.localeCompare(b.name));
      this.currentDocumentType = undefined;
      this.editBatchFormGroup.controls['defaultDocumentType'].setValue("");
      this.changeEvent.emit({ defaultArea: selectedAreaCode, filteredDocumentTypes: this.filteredDocumentTypes });
  
      this.cdr.detectChanges();
    }

    onDefaultAreaClear(): void {
      this.currentDocumentType = undefined;
      this.editBatchFormGroup.controls['defaultArea'].setValue("");
      this.editBatchFormGroup.controls['defaultDocumentType'].setValue("");
      this.cdr.detectChanges();

    }

    onDefaultDocumentTypeChange(event: SelectChangeEvent): void {
      const selectedDocumentType = event.value;
      this.changeEvent.emit({ defaultDocumentType: selectedDocumentType });
      this.cdr.detectChanges();
    }

    onDefaultDocumentTypeClear(): void {
      this.editBatchFormGroup.controls['defaultDocumentType'].setValue("");
      this.cdr.detectChanges();
    }
  
  /**
   * Ends the status check interval and sets the isFetchingDocuments flag to false
   */
  endStatusCheckInterval(): void {
    if (this.statusCheckInterval) {
      clearInterval(this.statusCheckInterval);
      this.statusCheckInterval = null;
    }
    this.isFetchingDocuments = false;
  }
}
