<div class="container">
  <div class="file-seletion-header">
    <div class="head-label">
      <span>{{ batch.name ? batch.name : (attachmentsTitle | translate) }}</span>
       <!-- TODO: Remove feature flag once its released-->
      @if (!enableBatchOptions && mergeDocumentsFlag() ) {
        <div class="document-options">
          <p-menu 
          #menu 
          [model]="documentOptions" 
          [popup]="true">
          </p-menu>
          <p-button
            (click)="menu.toggle($event)"
            [ariaLabel]= " 'documentListOptions' | translate "
            aria-haspopup="true"
            icon="fa-regular fa-ellipsis"
            [rounded]="true"
            severity="contrast"
            variant="text">
          </p-button>
        </div>
      }
      @if (enableBatchOptions && mergeDocumentsFlag()) {
        <div class="document-options">
            <p-menu
              #menu 
              [model]="batchOptions" 
              [popup]="true">
            </p-menu>
            <p-button
              (click)="menu.toggle($event)"
              [ariaLabel]= " 'documentListOptions' | translate "
              aria-haspopup="true"
              icon="fa-regular fa-ellipsis"
              [rounded]="true"
              severity="contrast"
              variant="text">
            </p-button>
        </div>
      }
      <!-- TODO: Remove once feature flag is removed -->
      @if (enableBatchOptions && !mergeDocumentsFlag()) {
        <div class="document-options">
            <p-menu
              #menu 
              [model]="noMergeOption" 
              [popup]="true">
            </p-menu>
            <p-button
              (click)="menu.toggle($event)"
              [ariaLabel]= " 'documentListOptions' | translate "
              aria-haspopup="true"
              icon="fa-regular fa-ellipsis"
              [rounded]="true"
              severity="contrast"
              variant="text">
            </p-button>
        </div>
      }
    </div>
  </div>
  <div class="files-container">
    <div *ngIf="attachments.length === 0 && isAddIn()">
      <span class="no-attachments-label">{{ 'noAttachments' | translate }}</span>
    </div>
    <div *ngIf="attachments.length === 0 && !isLoadingFiles && isWebCapture() && !batchLoading">
      <span class="no-attachments-label">{{ 'noBatchDocs' | translate }}</span>
    </div>
    <div
      *ngIf="
        (isPrinter() && attachments.length === 0) || (isLoadingFiles && attachments.length === 0)
      "
      class="files-spinner-container"
    >
      <ngx-spinner
        color="#006CFA"
        bdColor="rgba(255,255,255, 0.3)"
        [fullScreen]="false"
        type="ball-spin-clockwise"
        size="small"
      ></ngx-spinner>
    </div>
    
    <div
    class="file-option-container"
    *ngFor="let file of attachments; let i = index; trackBy: trackByIndex"
    >
      <div
        *ngIf="file?.canceled !== true"
        class="file-option"
        (click)="selectAttachment(i)"
        [ngClass]="{ 'active-file': currentAttachmentIndex === i }"
        tabindex="0"
        role="button"
        [attr.aria-label]="file.name + (file.completed ? ' (Completed)' : '')"
        [attr.aria-selected]="currentAttachmentIndex === i"
        (keyup.enter)="selectAttachment(i)"
        (keydown.space)="selectAttachment(i)"
      >
        <span 
          [pTooltip]="file.name"
          tooltipPosition="right"
          showDelay="500" hideDelay="300"
          [tooltipDisabled]="!isTextOverflowing(labelRef)"
          #labelRef>
          {{ file.name }}
        </span>

        <!-- Completion Checkmark  -->
        <div class="check-container">
          <i
            *ngIf="file.completed"
            class="fa-solid fa-circle-check check"
            aria-hidden="true"
          ></i>
          <span *ngIf="file.completed" class="sr-only">Completed</span>
        </div>

        <!-- Loading Spinner -->
        <div
          class="spinner-container"
          [class.completed-file-spinner-container]="file.completed"
          *ngIf="!file.status && file.classificationStarted && isAutoClassificationEnabled"
          aria-live="polite"
          aria-busy="true"
        >
          <ngx-spinner
            color="#006CFA"
            bdColor="rgba(255,255,255, 0.3)"
            [fullScreen]="false"
            type="ball-spin-clockwise"
            size="small"
            [name]="'fileClassificationSpinner-' + file.name"
          ></ngx-spinner>
        </div>
    </div>
  </div>
</div>
    @if (enableBatchOptions) {
      <div class="close-batch-container">
        <p-button
          
          label="{{ 'closeBatch' | translate }}"

          class="close-batch-button"
          (click)="closeBatch()"
          [attr.aria-label]="'closeBatch' | translate"
          variant="text">
        </p-button>
      </div>
    }
</div>
