export const checkboxTheme = {
    width: '{size.pixel.18}',
    height: '{size.pixel.18}',
    colorScheme: {
      light: {
        checked: {
          hover: {
            background: '{sd.primary.hover}',
            border: {
              color: '{override.primary.color}'
            }
          },
          background: '{sd.primary.color}'
        },
        // disabled: {
        //   background: '{sd.backgrounds.readonly}',
        //   color: '{sd.form.action.background.color}'
        // },
        // icon: {
        //   disabledColor: '{sd.form.action.background.color}'
        // }
      }
    }
  };
  
  
  