import { dt } from '@primeng/themes';

export const inputNumberTheme = {
  button: {
    border: {
      radius: 0
    }
  },
  colorScheme: {
    light: {
      button: {
        color: '{primary.contrast.color}',
        background: '{sd.backgrounds.secondary.color}',
        hover: {
          color: '{primary.contrast.color}',
          background: '{sd.secondary.hovercolor.darker}'
        }
      }
    }
  },
  // The default style does not align properly, so this shifts it around a bit
  css: ({ dt: any }: any) => `
    .p-inputnumber-stacked .p-inputnumber-increment-button {
      border-start-end-radius: ${dt('sd.border.radius.medium')}; !important
    }
    .p-inputnumber-stacked .p-inputnumber-decrement-button {
      border-end-end-radius: ${dt('sd.border.radius.medium')}; !important
    }

    .p-inputnumber-stacked .p-inputnumber-button-group {
      height: 100% !important;
      insert-inline-end: 0 !important;
      right: 0 !important;
      top: 0 !important;
    }
  `
};
