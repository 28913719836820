// External Libraries
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  BehaviorSubject,
  Subscription,
  catchError,
  combineLatest,
  lastValueFrom,
  of,
  switchMap
} from 'rxjs';
import { ConfirmationService } from 'primeng/api';

// FontAwesome
import {
  faFile,
  faFileCirclePlus,
  faQuestionCircle,
  faGear,
  faInfoCircle,
  faSignOut
} from '@fortawesome/free-solid-svg-icons';

// Frontegg Services
import { FronteggAppService, FronteggAuthService, ContextHolder } from '@frontegg/angular';

// Project Services
import { AuthService } from '../../services/auth/auth.service';
import { BatchService } from '../../services/batch/batch.service';
import {
  Area,
  ContentApiService,
  DocumentType,
  KeyField as DocumentTypeKeyField
} from '../../services/content-api/content-api.service';
import { DocumentManagementService } from '../../services/document-management/document-management.service';
import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';
import { TenantApiService } from '../../services/tenant-api/tenant-api.service';

// Project Models
import { MappingKeyField, MapResponse } from '../../models/docTypeMapping';
import { DocumentResponse } from '../../models/documentResponse';
import { ErrorResponse } from '../../models/errorResponse';
import { ExtractionResponse } from '../../models/extractionResponse';
import {
  BatchDocumentRequest,
  BatchDocumentResponse,
  BatchListResponse,
  BatchResponse,
  BulkCreateBatchDocumentRequest,
  ListBatchesParams,
  UpdateBatchDocumentRequest
} from '../../models/sdk-interfaces';
import { KeyField, StatusResponse } from '../../models/statusResponse';

// Project Components
import { Attachment } from '../../models/attachment';
import { FileSelectorAttachment } from '../../components/file-selector/file-selector.component';
import { KeyFieldsComponent } from '../../components/key-fields/key-fields.component';

// Utilities
import { base64ToFile, buildKeyFields } from '../../utils/documentUtils';

// Environment Variables
import { environment } from '../../environments/environment';

interface Document {
  contentUri: string;
  fileName: string;
  fileId: string;
  fileContent: string;
  contentType: string;
  requestId?: string;
  status?: StatusResponse;
  completed: boolean;
  classificationStarted: boolean;
  canceled?: boolean;
}

declare const Softdocs: any;
declare const pendo: any;

@Component({
  selector: 'app-web-capture',
  templateUrl: './web-capture.component.html',
  styleUrls: ['./web-capture.component.scss']
})
export class WebCaptureComponent implements OnInit, OnDestroy {
  @ViewChild(KeyFieldsComponent) keyFieldsComponent!: KeyFieldsComponent;

  private subscription = new Subscription();

  autoclassifierSdk = new Softdocs.DocumentAnalysisSdk(environment.urls.autoclassifierApi);
  documentManagementSdk = new Softdocs.DocumentManagementSdk(environment.urls.autoclassifierApi);
  docTypeMappingSdk = new Softdocs.DocTypeMappingSdk(environment.urls.autoclassifierApi);

  documentsReceived$ = new BehaviorSubject<boolean>(false);
  autoclassificationFlag$ = new BehaviorSubject<boolean>(false);

  isAutoClassificationEnabled: boolean = false;
  isAutoclassificationFlagLoading: boolean = true;
  isAccessEnabled: boolean | null = null;
  isAccessFlagLoading: boolean = true;
  featureFlagErrorText: string = '';

  title = 'webCaptureTitle';
  isLoading = true;
  loadingSubscription: Subscription;
  user?: any;
  tenantId: string = '';
  isUrlVerified: boolean = false;
  adHocFilingDocIds: string[] = [];
  documents: Document[] = [];
  currentAttachmentIndex: number = -1;
  formStates: Map<number, any> = new Map();
  documentForm!: FormGroup;
  areas: Area[] = [];
  settingsAreas: Area[] = [];
  filteredDocumentTypes: DocumentType[] = [];
  batchEditFilteredDocumentTypes: DocumentType[] = [];
  currentDocumentType: DocumentType | undefined;
  documentTypes: DocumentType[] = [];
  currentTab: string = 'adHocUpload';
  isCreatingBatch: boolean = false;
  isFilingBatch: boolean = false;
  isFilingAdHoc: boolean = false;
  loadingFilingFiles: boolean = false;
  batchIdToCreate: string = '';
  batches: BatchResponse[] = [];
  currentOpenBatch: BatchResponse = {} as BatchResponse;
  currentOpenBatchDocuments: BatchDocumentResponse[] = [];
  showEditBatchModal: boolean = false;
  editBatchFormGroup!: FormGroup;
  showAddDocumentsModal: boolean = false;
  showUnsupportedFileMessage: boolean = false;
  showSettings: boolean = false;
  isSettingsOpening: boolean = false;
  showAboutModal: boolean = false;
  hasUploadedFilesAtCreation: boolean = false;
  hasVerifiedURL: boolean = false;
  statusCheckInterval: any;

  faFile = faFile;
  faFileCirclePlus = faFileCirclePlus;
  faQuestionCircle = faQuestionCircle;
  faGear = faGear;
  faInfoCircle = faInfoCircle;
  faSignOut = faSignOut;

  currentPage: number = 1;
  itemsPerPage: number = 12;
  hasMorePages: boolean = true;

  constructor(
    private fronteggAuthService: FronteggAuthService,
    private fronteggAppService: FronteggAppService,
    private docMngmtService: DocumentManagementService,
    private contentService: ContentApiService,
    private tenantService: TenantApiService,
    private authService: AuthService,
    private fb: FormBuilder,
    private featureFlagService: FeatureFlagService,
    private batchService: BatchService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef
  ) {
    this.loadingSubscription = fronteggAppService.isLoading$.subscribe(
      isLoading => (this.isLoading = isLoading)
    );
  }

  ngOnInit(): void {
    this.checkFeatureFlag();

    this.subscription.add(
      combineLatest([this.documentsReceived$, this.autoclassificationFlag$]).subscribe(
        ([documentsReceived, autoclassificationEnabled]) => {
          if (documentsReceived && autoclassificationEnabled) {
            this.analyzeDocuments();
          }
        }
      )
    );
  }

  /**
   * Run the feature flag check for the provided tenantId
   * @param tenantId the provided tenantId
   */
  runFeatureFlagCheck(tenantId: string): void {
    this.isAccessFlagLoading = true;
    this.featureFlagService
      .initialize({ tenantId })
      .then(() =>
        this.subscription.add(
          this.featureFlagService
            .getFlag('web-capture-access', false) // TODO: Replace with correct flag key
            .pipe(
              switchMap(flagValue => {
                this.isAccessFlagLoading = false;
                if (flagValue) {
                  this.isAccessEnabled = true;
                  pendo.initialize({ account: { id: tenantId } });
                  this.startFronteggAuth();
                } else {
                  this.isAccessEnabled = true;
                  pendo.initialize({ account: { id: tenantId } });
                  this.startFronteggAuth();
                  // TODO: Uncomment the lines below and remove the lines above once feature flag is put in place
                  // this.isUrlVerified = false;
                  // this.isAccessEnabled = false;
                  // this.featureFlagErrorText = 'webCaptureFeatureFlagError'; // TODO: Add error to locale files
                }
                return of(null);
              })
            )
            .subscribe()
        )
      )
      .catch(() => {
        this.isAccessFlagLoading = false;
        this.isUrlVerified = false;
        this.isAccessEnabled = false;
      });
  }

  /**
   * Re-verifies the tenant URL and triggers feature flag check based on the tenant ID
   */
  onSuccessfulVerification(): void {
    const tenantId = localStorage.getItem('tenantId');
    this.hasVerifiedURL = true;
    if (tenantId) {
      this.isAccessFlagLoading = true;
      this.runFeatureFlagCheck(tenantId);
    }
  }

  /**
   * Check the feature flag based on tenantId from localStorage.
   */
  checkFeatureFlag(): void {
    const tenantId = localStorage.getItem('tenantId');

    if (tenantId) {
      this.runFeatureFlagCheck(tenantId);
    } else {
      this.isAccessFlagLoading = false;
      this.isUrlVerified = false;
      this.isAccessEnabled = null;
    }
  }

  /**
   * Starts Frontegg Authentication flow
   */
  startFronteggAuth() {
    this.isUrlVerified = true;

    const organization = localStorage.getItem('vanityName');
    this.tenantId = localStorage.getItem('tenantId') || '';

    if (this.tenantId && organization) {
      this.subscription.add(
        this.fronteggAuthService.authState$.subscribe(authState => {
          if (!authState.isAuthenticated && !this.isLoading) {
            if (!localStorage.getItem('contentURL') && !this.hasVerifiedURL) {
              localStorage.removeItem('tenantId');
            }
            this.loginWithRedirect(this.tenantId, organization);
          } else {
            if (authState.user?.accessToken) {
              this.user = authState.user;
              console.log('User:', this.user);
              this.authService.setAuthToken(this.user?.accessToken);
            }
          }
        })
      );
    } else {
      this.isUrlVerified = false;
    }
  }

  /**
   * Redirects the user to the login page with the specified tenant id and organization
   * @param tenantId - the id of the tenant to log in to
   * @param organization - the organization name associated with the tenant
   */
  loginWithRedirect(tenantId: string, organization: string): void {
    this.fronteggAuthService.loginWithRedirect({
      tenantId: tenantId,
      organization: organization
    });
  }

  /**
   * Signs out the user of the application and redirects them back to the log in page
   */
  signOut(): void {
    const baseUrl = ContextHolder.getContext().baseUrl;
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
  }

  goToTab(tab: string) {
    if (this.isFilingAdHoc || this.isFilingBatch) {
      this.confirmationService.confirm({
        message: 'unsavedDataWarning',
        key: 'tabChangeDialog',
        accept: () => {
          if (this.isFilingBatch) {
            this.closeBatch();
          } else {
            this.restartAdHocFiling();
          }
          this.currentTab = tab;
        }
      });
    } else if (this.isCreatingBatch && this.hasUploadedFilesAtCreation) {
      this.confirmationService.confirm({
        message: 'unsavedDataWarning',
        key: 'tabChangeDialog',
        accept: () => {
          this.cancelBatchCreation();
          this.currentTab = tab;
        }
      });
    } else {
      this.currentTab = tab;
    }

    if (this.currentTab === 'batchUpload' && this.batches.length === 0) {
      this.fetchBatches(0, this.itemsPerPage);
    }
  }

  confirmCloseBatch() {
    this.confirmationService.confirm({
      message: 'closeBatchWarning',
      key: 'closeBatchDialog',
      accept: () => {
        this.closeBatch();
      }
    });
  }

  /**
   * Initializes the document form with default values
   */
  initializeDocumentForm() {
    this.documentForm = this.fb.group({
      area: [''],
      documentTypeCode: ['']
    });
  }

  /**
   * Event listener to handle changes to an attachment's classification request
   * @param event - The documentStatusUpdate event
   */
  @HostListener('window:documentStatusUpdate', ['$event'])
  handleDocumentStatusUpdate(event: CustomEvent) {
    const index = this.documents.findIndex(
      document => document.requestId === event.detail.requestId
    );
    if (index !== -1 && !this.documents[index].status) {
      this.documents[index].status = event.detail.status;
      if (
        index === this.currentAttachmentIndex &&
        this.documents[this.currentAttachmentIndex].classificationStarted
      ) {
        console.log('Document status updated:', event.detail.status);
        this.buildFormFields();
      }
    }
  }

  @HostListener('document:click')
  public onClick(): void {
    if (!this.isSettingsOpening) {
      this.showSettings = false;
    } else {
      this.isSettingsOpening = false;
    }
  }

  /**
   * Checks if the settings menu should be hidden after a focus event.
   */
  checkNavButtonsFocus(event: FocusEvent): void {
    if (!(event.relatedTarget as HTMLInputElement)?.classList.contains('settings-option')) {
      this.showSettings = false;
    }
  }

  // /**
  //  * Handles the successful verification of the URL
  //  */
  // onSuccessfulVerification(): void {
  //   const tenantId = localStorage.getItem('tenantId');
  //   if (tenantId) {
  //     // this.isUrlVerified = true;
  //     this.startFronteggAuth();
  //   }
  // }

  startAdHocFiling(documentIds: string[]) {
    this.adHocFilingDocIds = documentIds;
    this.loadingFilingFiles = true;
    this.isFilingAdHoc = true;

    this.loadData();

    this.subscription.add(
      this.documentsReceived$.subscribe(documentsReceived => {
        if (documentsReceived) {
          this.analyzeDocuments();
        }
      })
    );
    this.initializeDocumentForm();
  }

  /**
   * Retrieves the attachments for the file selector
   * @returns {FileSelectorAttachment[]} - An array of file selector attachments
   */
  getFileSelectorAttachments(): FileSelectorAttachment[] {
    const attachments = this.documents.map(
      doc =>
        ({
          name: doc.fileName,
          completed: doc.completed,
          status: doc.status as StatusResponse,
          classificationStarted: doc.classificationStarted,
          canceled: doc.canceled
        }) as FileSelectorAttachment
    );
    return attachments;
  }

  analyzeDocuments(): void {
    if (this.documents.length === 0 || !this.isAutoClassificationEnabled) {
      return;
    }

    Promise.all(this.documents.map(doc => this.analyzeDocument(doc)))
      .then(() => {})
      .catch(error => {
        console.error('Error analyzing documents', error);
      });
  }

  /**
   * Retrieves the document from the Document Management SDK
   * @param {string} documentId - The ID of the document to retrieve
   */
  getDocumentFromDocMgmtSdk(documentId: string): Promise<void> {
    return this.documentManagementSdk
      .getDocument(this.tenantId, documentId, 'contentmetadata')
      .then((response: DocumentResponse) => {
        const newDocument: Document = {
          contentUri: response.contentUri,
          fileName: response.fileName,
          fileId: response.id,
          contentType: response.type,
          fileContent: '',
          completed: this.currentOpenBatchDocuments.some(
            doc => doc.documentId === response.id && doc.status === 'filed'
          )
            ? true
            : false,
          classificationStarted: true
        };

        if (response.contentMetadata?.data && response.contentMetadata?.documentType) {
          newDocument.status = response.contentMetadata.data;
        }

        return lastValueFrom(this.docMngmtService.getDocument(newDocument.contentUri)).then(
          arrayBuffer => {
            const binaryString = Array.from(new Uint8Array(arrayBuffer))
              .map(byte => String.fromCharCode(byte))
              .join('');
            const base64 = btoa(binaryString);
            newDocument.fileContent = base64;
            this.documents.push(newDocument);
            this.cdr.detectChanges();
            if (!newDocument.completed && this.currentAttachmentIndex === -1) {
              this.selectAttachment(this.documents.length - 1);
            }
          }
        );
      })
      .catch((error: any) => {
        console.error('Document get Error:', error);
      });
  }

  getDocumentMetadataFromDocMgmtSdk(documentId: string): Promise<DocumentResponse> {
    return this.documentManagementSdk
      .getDocument(this.tenantId, documentId, 'contentmetadata')
      .then((response: DocumentResponse) => {
        if (response.contentMetadata?.data) {
          const docIndex = this.documents.findIndex(doc => doc.fileId === documentId);
          this.documents[docIndex].status = response.contentMetadata.data;
          if (
            docIndex === this.currentAttachmentIndex &&
            this.documents[this.currentAttachmentIndex].classificationStarted
          ) {
            this.buildFormFields();
          }
        }
      });
  }

  /**
   * Initializes data to be used by the component
   */
  loadData(): void {
    this.featureFlagService.identify({
      key: this.user.customClaims.principal_id,
      name: this.user.name,
      tenantId: this.tenantId
    });

    this.subscription.add(
      this.featureFlagService
        .getFlag('autoclassification-for-web-capture', false) // TODO: Replace with correct flag key
        .pipe(
          switchMap(flagValue => {
            this.isAutoclassificationFlagLoading = false;

            this.isAutoClassificationEnabled = this.isFilingBatch ? false : (flagValue as boolean);

            console.log(
              'autoclassification-for-web-capture flag value:',
              this.isAutoClassificationEnabled
            );

            this.autoclassificationFlag$.next(this.isAutoClassificationEnabled);

            return this.tenantService.getTenantUrls(this.tenantId);
          }),
          switchMap(tenantUrls => {
            this.contentService.setBaseUrl(tenantUrls.contentApiUrl);
            return this.contentService.getDocumentTypes();
          }),
          switchMap(docTypes => {
            this.documentTypes = docTypes;
            return this.contentService.getAreas();
          }),
          catchError(error => {
            console.error('Error in chained requests', error);
            return of([]); // Return an observable to keep the stream alive
          })
        )
        .subscribe(areas => {
          this.settingsAreas = areas;
          if (this.currentOpenBatch.areas?.length) {
            this.areas = [];
            this.currentOpenBatch.areas?.forEach(area => {
              const foundArea = areas.find(a => a.code === area);
              if (foundArea) {
                this.areas.push(foundArea);
              }
            });
          } else {
            this.areas = areas;
          }
          Promise.all(
            this.adHocFilingDocIds.map(docId => this.getDocumentFromDocMgmtSdk(docId))
          ).then(() => {
            if (this.isFilingBatch && !this.documents.every(doc => doc.status)) {
              this.startBatchDocumentStatusCheck();
            }
            this.loadingFilingFiles = false;
            this.documentsReceived$.next(true);
          });
        })
    );
  }

  /**
   * Checks the status of documents in a batch
   * If all documents have a status, the interval is cleared
   */
  startBatchDocumentStatusCheck(): void {
    this.statusCheckInterval = setInterval(() => {
      const documentsWithoutStatus = this.documents.filter(doc => !doc.status);
      Promise.all(
        documentsWithoutStatus.map(doc => this.getDocumentMetadataFromDocMgmtSdk(doc.fileId))
      ).then(() => {
        // Check if all documents have a status
        const allDocumentsHaveStatus = this.documents.every(doc => doc.status);
        if (allDocumentsHaveStatus) {
          clearInterval(this.statusCheckInterval);
        }
      });
    }, 4000); // Run every 4 seconds
  }

  /**
   * Selects an attachment and builds form fields for that attachment
   * @param {number} index - The index of the selected attachment
   */
  selectAttachment(index: number): void {
    this.showUnsupportedFileMessage = false;
    if (index >= 0 && index < this.documents.length) {
      this.formStates.set(this.currentAttachmentIndex, this.documentForm.value);
      this.currentAttachmentIndex = index;

      if (
        this.documents[this.currentAttachmentIndex].classificationStarted ||
        !this.isAutoClassificationEnabled
      ) {
        this.buildFormFields();
      } else {
        this.initializeDocumentForm(); // Initialize the form if no classification has started
      }
    }
  }

  /**
   * Performs a lookup on key fields that are of type 'Lookup'
   * @param {MappingKeyField[]} mappedKeyFields - The mapped key field data to use for the lookup
   */
  setLookup(mappedKeyFields: MappingKeyField[]): void {
    this.currentDocumentType?.keyfields
      .filter(keyfield => keyfield.dataType === 'Lookup')
      .forEach(keyfield => {
        if (keyfield.lookupType?.namingFields.some(namingField => namingField.required)) {
          let lookupSearchTerm = '';
          keyfield.lookupType.namingFields
            .filter(namingField => namingField.required)
            .forEach(namingField => {
              const matchingField = mappedKeyFields?.find(
                mappedField => mappedField.key === `${keyfield.code}.${namingField.fieldCode}`
              );
              if (matchingField) {
                lookupSearchTerm += `${matchingField.value} `;
              }
            });

          lookupSearchTerm = lookupSearchTerm.trim();
          if (
            lookupSearchTerm &&
            this.documents[this.currentAttachmentIndex].classificationStarted
          ) {
            this.keyFieldsComponent.lookupUsers(
              keyfield.code,
              keyfield.lookupType.code,
              lookupSearchTerm,
              true
            );
          }
        }
      });
  }

  /**
   * Handles changes in the form, such as area or document type selection
   * @param {Event} event - The change event from the form
   */
  formOnChange(event: any): void {
    const target = event.target;

    if (target && target.id === 'areaSelector') {
      const selectedArea = target.value;
      this.documentForm.controls['area'].setValue(selectedArea);

      this.filterDocumentTypes(selectedArea);

      const foundType = this.filteredDocumentTypes.find(
        type => type.code === this.currentDocumentType?.code
      );

      if (foundType) {
        this.currentDocumentType = foundType;
        this.documentForm.controls['documentTypeCode'].setValue(this.currentDocumentType.code);
      } else {
        this.currentDocumentType = undefined;
        this.documentForm.controls['documentTypeCode'].reset();
      }
    }

    if (target && target.id === 'documentTypeSelector') {
      this.formStates.set(this.currentAttachmentIndex, this.documentForm.value);

      const selectedTypeCode = target.value;
      this.currentDocumentType = this.filteredDocumentTypes.find(
        type => type.code === selectedTypeCode
      );

      if (this.currentDocumentType) {
        this.buildFormFields();
      } else {
        this.currentDocumentType = undefined;
        this.documentForm.controls['documentTypeCode'].reset();
      }
    }
  }

  /**
   * Filters the document types list by the currently selected area
   * @param {string} areaCode - The code of the selected area
   */
  filterDocumentTypes(areaCode: string): void {
    this.filteredDocumentTypes = this.documentTypes.filter(type => type.areaCode === areaCode);
  }

  /**
   * Builds and prepopulates form fields for the current document
   */
  buildFormFields(): void {
    const savedState = this.formStates.get(this.currentAttachmentIndex);

    if (savedState && savedState.area && this.areas.some(area => area.code === savedState.area)) {
      this.initializeFormControls(savedState.area, savedState.documentTypeCode);
      this.documentForm.setValue(savedState);
    } else {
      this.buildFormFieldsForNewDocument();
    }
  }

  /**
   * Extracts key fields from a document
   * @param {any} document - The document to extract key fields from
   * @returns {KeyField[]} - An array of extracted key fields
   */
  private extractKeyFields(document: any): KeyField[] {
    return document.extractInfo?.keyFields.map((field: KeyField) => ({
      ...field,
      value: field.value === null ? '' : field.value
    })) as KeyField[];
  }

  /**
   * Gets the document with the highest confidence from the list of documents in an attachment
   * @param {PrinterDocument} attachment - The attachment containing the documents
   * @returns {any} - The document with the highest confidence
   */
  private getMostConfidentDocument(attachment: Document): any {
    return attachment.status?.documents.reduce((prev, current) => {
      if (prev.classifyInfo && current.classifyInfo) {
        return prev.classifyInfo.confidence > current.classifyInfo.confidence ? prev : current;
      }
      return prev;
    });
  }

  /**
   * Retrieves a document type by its code
   * @param {string | undefined} docTypeCode - The document type code
   * @returns {DocumentType | undefined} - The matching document type, if found
   */
  private getDocumentTypeByCode(docTypeCode: string | undefined): DocumentType | undefined {
    return this.documentTypes.find(type => type.code === docTypeCode);
  }

  /**
   * Populates form controls with values based on the selected document type and key fields
   * @param {Object} formControls - The form controls to populate
   * @param {string | undefined} docTypeCode - The document type code
   * @param {MappingKeyField[]} mappedKeyFields - The key fields to populate the form controls with
   */
  private populateFormControls(
    formControls: { [key: string]: FormControl },
    docTypeCode: string | undefined,
    mappedKeyFields: MappingKeyField[]
  ): void {
    if (this.currentDocumentType) {
      const areaCode = this.currentDocumentType.areaCode;
      this.filterDocumentTypes(areaCode);
      formControls['area'].setValue(areaCode);
      formControls['documentTypeCode'].setValue(docTypeCode);

      this.addKeyFieldsToFormControls(formControls);

      mappedKeyFields.forEach(mappedField => {
        const formControl = formControls[mappedField.key];
        if (formControl) {
          if (
            this.currentDocumentType?.keyfields.find(kf => kf.code === mappedField.key)
              ?.dataType === 'Date'
          ) {
            const date = new Date(mappedField.value);
            if (!isNaN(date.getDate())) {
              formControl.setValue(new Date(mappedField.value));
            }
          } else {
            formControl.setValue(mappedField.value);
          }
        }
      });
    }
  }

  /**
   * Initializes form controls with area and document type
   * @param {string} area - The selected area
   * @param {string} documentTypeCode - The selected document type code
   */
  private initializeFormControls(area: string, documentTypeCode: string): void {
    const formControls: { [key: string]: FormControl } = {
      area: new FormControl(area),
      documentTypeCode: new FormControl(documentTypeCode)
    };
    this.filterDocumentTypes(area);
    this.currentDocumentType = this.filteredDocumentTypes.find(
      type => type.code === documentTypeCode
    );

    if (this.currentDocumentType) {
      this.addKeyFieldsToFormControls(formControls);
    }

    this.documentForm = this.fb.group(formControls);
  }

  /**
   * Builds form fields for a new document when there is no saved state
   */
  private buildFormFieldsForNewDocument(): void {
    const formControls: { [key: string]: FormControl } = {
      area: new FormControl(''),
      documentTypeCode: new FormControl('')
    };

    const attachment = this.documents[this.currentAttachmentIndex];
    if (
      attachment.status &&
      Array.isArray(attachment.status.documents) &&
      attachment.status.documents.length > 0 &&
      attachment.status.documents.find(doc => doc.classifyInfo?.documentType)
    ) {
      const document = this.getMostConfidentDocument(attachment);

      if (document) {
        const keyFields = this.extractKeyFields(document);
        this.mapKeyFields(document.classifyInfo?.documentType as string, keyFields).then(
          response => {
            if (response && response.mapItems.length > 0) {
              const mapItem = response.mapItems[0];

              const docTypeCode = mapItem.docTypeCode;
              const documentTypeToSet = this.getDocumentTypeByCode(docTypeCode);
              if (this.areas.some(area => area.code === documentTypeToSet?.areaCode)) {
                this.currentDocumentType = documentTypeToSet;
                this.populateFormControls(formControls, docTypeCode, mapItem.keyFields);

                this.documentForm = this.fb.group(formControls);

                this.handleLookupFields(mapItem.keyFields);
              } else {
                this.documentForm = this.fb.group(formControls);
              }
            } else {
              this.documentForm = this.fb.group(formControls);
            }
          }
        );
      }
    } else {
      // Create empty form controls if no status is available
      let docTypeCode = '';
      let areaCode = '';
      if (this.currentOpenBatch.areas?.length === 1) {
        areaCode = this.currentOpenBatch.areas[0];
      }

      if (this.currentOpenBatch.defaultDocumentType) {
        docTypeCode = this.currentOpenBatch.defaultDocumentType;
      }

      this.initializeFormControls(areaCode, docTypeCode);
    }
  }

  /**
   * Adds key fields to the form controls
   * @param {Object} formControls - The form controls to add key fields to
   */
  private addKeyFieldsToFormControls(formControls: { [key: string]: FormControl }): void {
    this.currentDocumentType?.keyfields.forEach(field => {
      formControls[field.code] = new FormControl('');
      this.applyFieldRules(field, formControls);
    });
  }

  /**
   * Handles lookup fields in the form, performing lookups as necessary
   * @param {MappingKeyField[]} mappedKeyFields - The key fields for lookup
   */
  private handleLookupFields(mappedKeyFields: MappingKeyField[]): void {
    if (this.currentDocumentType?.keyfields.some(keyfield => keyfield.dataType === 'Lookup')) {
      this.setLookup(mappedKeyFields);
    }
  }

  /**
   * Applies validation and default rules to a form control
   * @param {DocumentTypeKeyField} field - The field metadata
   * @param {Object} formControls - The form controls to which rules should be applied
   */
  applyFieldRules(field: DocumentTypeKeyField, formControls: { [key: string]: FormControl }) {
    if (field.default) {
      if (field.dataType === 'Date' && field.default === 'CurrentDate') {
        formControls[field.code].setValue(new Date());
      } else {
        formControls[field.code].setValue(field.default);
      }
    }

    if (field.required) {
      formControls[field.code].setValidators([Validators.required]);
    }

    if (field.maxLength) {
      formControls[field.code].setValidators([Validators.maxLength(field.maxLength)]);
    }

    if (field.textPattern) {
      formControls[field.code].setValidators([Validators.pattern(field.textPattern)]);
    }

    if (field.minValue) {
      formControls[field.code].setValidators([Validators.min(field.minValue)]);
    }
  }

  /**
   * Maps key fields based on the classified document type and provided key fields
   * @param {string} classifiedDocType - The classified document type
   * @param {KeyField[]} keyFields - The key fields to map
   * @param {string} [documentType] - Optional document type to map to
   * @returns {Promise<MapResponse>} - A promise of the map response
   */
  mapKeyFields(
    classifiedDocType: string,
    keyFields: KeyField[],
    documentType?: string
  ): Promise<MapResponse> {
    const mappingKeyFields: MappingKeyField[] = keyFields
      .filter(kf => kf.value !== undefined && kf.value !== null && kf.value !== '')
      .map(kf => ({ key: kf.key, value: kf.value }));

    return this.docTypeMappingSdk
      .mapKeyFields(this.tenantId, {
        modelDocType: classifiedDocType,
        keyFields: mappingKeyFields,
        docTypeCode: documentType
      })
      .then((response: MapResponse) => {
        return response;
      })
      .catch((error: ErrorResponse) => {
        console.error('Error mapping key fields: ', error);
        return undefined;
      });
  }

  /**
   * Analyzes a document using the autoclassifier SDK
   * @param {PrinterDocument} document - The document to analyze
   * @returns {Promise<void>} - A promise that resolves when the analysis is complete
   */
  async analyzeDocument(document: Document): Promise<void> {
    const file = base64ToFile(document.fileContent, document.fileName, 'application/pdf');
    this.autoclassifierSdk
      .extractDocumentFromUpload(file, true)
      .then((response: ExtractionResponse) => {
        document.requestId = response.requestId;
      })
      .catch((error: ErrorResponse) => {
        console.error('Error analyzing document:', error);
      });
  }

  /**
   * Uploads an attachment with type, area, and key field information to Softdocs Etrieve Content.
   */
  uploadDocument(): void {
    if (this.documentForm?.valid) {
      const formData = buildKeyFields(
        this.documentForm.value,
        this.currentDocumentType as DocumentType
      );

      const multiValueFields = this.currentDocumentType?.keyfields.filter(kf => kf.multivalue);

      if (multiValueFields) {
        multiValueFields.forEach(multiValueField => {
          const multValueFieldCode = multiValueField.code + '-' + this.currentDocumentType?.code;
          if (this.keyFieldsComponent.multiValueFields[multValueFieldCode]) {
            this.keyFieldsComponent.multiValueFields[multValueFieldCode].forEach(value => {
              formData.push({
                fieldCode: multiValueField.code,
                value: value
              });
            });
          }
        });
      }

      const attachment: Attachment = {
        content: {
          content: this.documents[this.currentAttachmentIndex].fileContent,
          format: 'base64'
        },
        attachmentType: this.documents[this.currentAttachmentIndex].contentType,
        requestId: this.documents[this.currentAttachmentIndex].requestId,
        name: this.documents[this.currentAttachmentIndex].fileName,
        contentType: this.documents[this.currentAttachmentIndex].contentType,
        id: '',
        isInline: false,
        size: 0
      };

      this.contentService
        .uploadDocument(
          attachment,
          this.documentForm.controls['area'].value as string,
          this.documentForm.controls['documentTypeCode'].value as string,
          formData
        )
        .subscribe({
          error: error => {
            console.error('Failed to upload document', error);
            this.keyFieldsComponent.onUploadComplete();
          },
          complete: () => {
            this.keyFieldsComponent.onUploadComplete();
            this.documents[this.currentAttachmentIndex].completed = true;
            if (this.isFilingBatch) {
              const currentDocument = this.currentOpenBatchDocuments.find(
                doc => doc.documentId === this.documents[this.currentAttachmentIndex].fileId
              );
              const updateBatchDocumentData: UpdateBatchDocumentRequest = {
                version: currentDocument?.version || 1,
                source: currentDocument?.source,
                status: 'filed'
              };
              this.batchService
                .updateBatchDocument(
                  this.tenantId,
                  this.user.customClaims.principal_id,
                  this.currentOpenBatch.batchId!,
                  this.documents[this.currentAttachmentIndex].fileId,
                  updateBatchDocumentData
                )
                .then(response => {
                  console.log('Document filed:', response);
                  this.currentOpenBatch.version++;

                  if (this.documents.findIndex(doc => !doc.completed) !== -1) {
                    this.selectAttachment(this.documents.findIndex(doc => !doc.completed));
                  } else {
                    this.completeBatch();
                    this.restartAdHocFiling();
                  }
                });
            } else {
              if (this.documents.findIndex(doc => !doc.completed) !== -1) {
                this.selectAttachment(this.documents.findIndex(doc => !doc.completed));
              } else {
                this.restartAdHocFiling();
              }
            }
          }
        });
    } else {
      console.error('Form is not valid, cannot upload document.');
      this.keyFieldsComponent.onUploadComplete();
    }
  }

  /**
   * Cancels the file filing process for the current attachment
   */
  cancelFileFiling(): void {
    this.documents[this.currentAttachmentIndex].canceled = true;
    if (this.documents?.findIndex(doc => !doc.completed && !doc.canceled) !== -1) {
      this.selectAttachment(this.documents.findIndex(doc => !doc.completed && !doc.canceled));
    } else {
      this.restartAdHocFiling();
    }
  }

  restartAdHocFiling(): void {
    this.documents = [];
    this.documentsReceived$.next(false);
    this.adHocFilingDocIds = [];
    this.isFilingBatch = false;
    this.isFilingAdHoc = false;
    this.currentDocumentType = undefined;
    this.formStates.clear();
    this.currentAttachmentIndex = -1;
    this.currentOpenBatch = {} as BatchResponse;
  }

  startBatchCreation() {
    this.isCreatingBatch = true;
  }

  cancelBatchCreation() {
    this.hasUploadedFilesAtCreation = false;
    this.isCreatingBatch = false;
    this.batchIdToCreate = '';
  }

  /**
   * Fetches the list of batches and assigns them to the `batches` array.
   */
  fetchBatches(offset: number = 0, count: number = 10): void {
    const tenantId = this.tenantId;

    if (tenantId) {
      const params: ListBatchesParams = {
        offset,
        count
      };

      this.batchService
        .listBatches(tenantId, params)
        .then((response: BatchListResponse) => {
          this.batches = response.items;

          // Determine if more batches are available for the next page
          if (response.items.length < count) {
            this.hasMorePages = false; // No more batches if fewer than `count` are returned
          } else {
            this.hasMorePages = true;
          }
        })
        .catch(error => {
          console.error('Error fetching batches:', error);
        });
    }
  }

  associateFilesToBatch(documentIds: string[]) {
    console.log('document IDs to associate', documentIds);
  }

  nextPage(): void {
    if (this.hasMorePages) {
      this.currentPage++;
      const offset = (this.currentPage - 1) * this.itemsPerPage;
      this.fetchBatches(offset, this.itemsPerPage);
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      const offset = (this.currentPage - 1) * this.itemsPerPage;
      this.fetchBatches(offset, this.itemsPerPage);
    }
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  openBatch(batchId: string): void {
    this.batchService.getBatch(this.tenantId, batchId).then(response => {
      console.log('Batch Openend:', response);
      this.loadingFilingFiles = true;
      const batch = response;
      this.currentOpenBatch = batch;
      this.batchService
        .getBatchDocuments(this.tenantId, this.currentOpenBatch.batchId!)
        .then(response => {
          console.log('Batch documents:', response);
          this.currentOpenBatchDocuments = response.items;
          response.items.forEach(item => {
            this.adHocFilingDocIds.push(item.documentId);
          });
        });

      this.checkOutBatch(this.currentOpenBatch.batchId!);

      this.isFilingBatch = true;

      this.loadData();

      this.subscription.add(
        this.documentsReceived$.subscribe(documentsReceived => {
          if (documentsReceived) {
            this.analyzeDocuments();
          }
        })
      );
      this.initializeDocumentForm();
    });
  }

  checkOutBatch(batchId: string): void {
    this.batchService
      .checkoutBatch(this.tenantId, this.user.customClaims.principal_id, batchId)
      .then(response => {
        this.currentOpenBatch = response;
      })
      .catch(error => {
        console.error('Errot checking out batch:', error);
      });
  }

  confirmDeleteBatch(): void {
    this.confirmationService.confirm({
      message: '',
      key: 'deleteBatchConfirmDialog',
      accept: () => {
        this.deleteBatch();
      }
    });
  }

  deleteBatch(): void {
    this.batchService
      .deleteBatch(
        this.tenantId,
        this.user.customClaims.principal_id,
        this.currentOpenBatch.batchId!
      )
      .then(() => {
        console.log('Batch deleted');
        this.restartAdHocFiling();
      })
      .catch(error => {
        console.error('Error deleting batch:', error);
      });
  }

  checkInBatch(): void {
    this.batchService
      .checkinBatch(
        this.tenantId,
        this.user.customClaims.principal_id,
        this.currentOpenBatch.batchId!
      )
      .then(() => {
        console.log('Batch checked in');
        this.restartAdHocFiling();
      })
      .catch(error => {
        console.error('Error checking in batch:', error);
      });
  }

  closeBatch(): void {
    if (this.currentOpenBatch.status === 'completed') {
      this.restartAdHocFiling();
    } else {
      this.checkInBatch();
    }
  }

  openEditBatchModal(): void {
    this.showEditBatchModal = true;
    this.editBatchFormGroup = this.fb.group({
      name: [''],
      defaultDocumentType: [''],
      areas: ['']
    });

    const editBatchAreas: Area[] = [];
    this.currentOpenBatch.areas?.forEach(area => {
      const foundArea = this.areas.find(a => a.code === area);
      if (foundArea) {
        editBatchAreas.push(foundArea);
      }
    });

    this.editBatchFormGroup.get('areas')?.patchValue(editBatchAreas);

    editBatchAreas.forEach((area: Area) => {
      this.batchEditFilteredDocumentTypes.push(
        ...this.documentTypes.filter(documentType => documentType.areaCode === area.code)
      );
    });

    this.editBatchFormGroup.get('areas')?.valueChanges.subscribe(areas => {
      this.batchEditFilteredDocumentTypes = [];
      areas.forEach((area: Area) => {
        this.batchEditFilteredDocumentTypes.push(
          ...this.documentTypes.filter(documentType => documentType.areaCode === area.code)
        );
      });
    });

    this.editBatchFormGroup.patchValue({
      name: this.currentOpenBatch.name,
      defaultDocumentType: this.currentOpenBatch.defaultDocumentType
        ? this.currentOpenBatch.defaultDocumentType
        : ''
    });
  }

  openAddDocumentsModal(): void {
    this.showAddDocumentsModal = true;
  }

  addDocumentsToBatch(documentIds: string[]): void {
    const bulkCreateRequest: BulkCreateBatchDocumentRequest = {
      documents: []
    };

    documentIds.forEach(fileId => {
      const batchDocument: BatchDocumentRequest = {
        documentId: fileId,
        source: 'document'
      };
      bulkCreateRequest.documents.push(batchDocument);
    });

    this.batchService
      .bulkCreateBatchDocuments(
        this.tenantId,
        this.user.customClaims.principal_id,
        this.currentOpenBatch.batchId!,
        bulkCreateRequest
      )
      .then(response => {
        console.log('Documents added to batch:', response);
        this.showAddDocumentsModal = false;

        Promise.all(
          bulkCreateRequest.documents.map(document =>
            this.getDocumentFromDocMgmtSdk(document.documentId)
          )
        ).then(() => {
          this.documentsReceived$.next(true);
        });
      })
      .catch(error => {
        console.error('Error adding documents to batch:', error);
      });
  }

  deleteDocument(): void {
    if (this.isFilingBatch) {
      this.confirmationService.confirm({
        message: '',
        key: 'deleteBatchFileConfirmDialog',
        accept: () => {
          this.deleteDocumentFromBatch();
        }
      });
    } else {
      this.confirmationService.confirm({
        message: '',
        key: 'deleteAdHocFileConfirmDialog',
        accept: () => {
          this.deleteAdHocDocument();
        }
      });
    }
  }

  deleteDocumentFromBatch(): void {
    this.batchService
      .removeBatchDocument(
        this.tenantId,
        this.user.customClaims.principal_id,
        this.currentOpenBatch.batchId!,
        this.documents[this.currentAttachmentIndex].fileId
      )
      .then(() => {
        console.log('Document removed from batch');
        this.documents[this.currentAttachmentIndex].canceled = true;
        if (this.documents.findIndex(doc => !doc.completed && !doc.canceled) !== -1) {
          this.selectAttachment(this.documents.findIndex(doc => !doc.completed && !doc.canceled));
        } else {
          this.restartAdHocFiling();
        }
      })
      .catch(error => {
        console.error('Error removing document from batch:', error);
      });
  }

  deleteAdHocDocument(): void {
    this.documents[this.currentAttachmentIndex].canceled = true;
    if (this.documents.findIndex(doc => !doc.completed && !doc.canceled) !== -1) {
      this.selectAttachment(this.documents.findIndex(doc => !doc.completed && !doc.canceled));
    } else {
      this.restartAdHocFiling();
    }
  }

  closeEditBatchModal(): void {
    this.showEditBatchModal = false;
  }

  saveBatchSettings(): void {
    const areasToAdd: string[] = this.editBatchFormGroup.controls['areas'].value.map(
      (area: Area) => area.code
    );
    const areasToRemove: string[] | undefined = this.currentOpenBatch.areas?.filter(
      area => !areasToAdd.includes(area)
    );

    this.batchService
      .updateBatch(
        this.tenantId,
        this.user.customClaims.principal_id,
        this.currentOpenBatch.batchId!,
        {
          name: this.editBatchFormGroup.controls['name'].value,
          version: this.currentOpenBatch.version++,
          defaultDocumentType: this.editBatchFormGroup.controls['defaultDocumentType'].value,
          status: 'available',
          areasToRemove: areasToRemove,
          areasToAdd: areasToAdd
        }
      )
      .then(response => {
        console.log('Batch updated:', response);
        this.currentOpenBatch = response;

        if (this.currentOpenBatch.areas?.length) {
          this.areas = [];
          this.currentOpenBatch.areas?.forEach(area => {
            const foundArea = this.settingsAreas.find(a => a.code === area);
            if (foundArea) {
              this.areas.push(foundArea);
            }
          });
        } else {
          this.areas = this.settingsAreas;
        }

        if (!this.currentOpenBatch.areas?.includes(this.documentForm.controls['area'].value)) {
          this.filterDocumentTypes('');
          this.documentForm.controls['area'].setValue('');
          this.documentForm.controls['documentTypeCode'].reset();
          this.currentDocumentType = undefined;
          this.buildFormFieldsForNewDocument();
        }

        this.closeEditBatchModal();
      });
  }

  completeBatch(): void {
    this.batchService
      .updateBatch(
        this.tenantId,
        this.user.customClaims.principal_id,
        this.currentOpenBatch.batchId!,
        {
          status: 'completed',
          version: this.currentOpenBatch.version
        }
      )
      .then(response => {
        console.log('Batch completed:', response);
      });
  }

  toggleShowSettings(): void {
    this.isSettingsOpening = !this.showSettings ? true : false;
    this.showSettings = !this.showSettings;
  }

  /**
   * Removes the tenant ID and vanity name from local storage and reloads the page to reset the Content URL.
   */
  changeContentUrl(): void {
    localStorage.removeItem('tenantId');
    localStorage.removeItem('vanityName');
    location.reload();
  }

  /**
   * Sets the current document status to an empty object, cancelling the auto-classification process
   */
  cancelFileAutoClassification(): void {
    this.documents[this.currentAttachmentIndex].status = {
      documents: []
    };
  }
}
