export const tabTheme = {
    tab: {
      color: '{surface.700}',
      fontWeight: 700,
      borderColor: '{surface.300}',
      hover: {
        borderColor: '{surface.700}'
      }
    },
    tablist: {
        borderWidth: '0 0 1px 0',
        borderStyle: 'solid',
        borderColor: '{surface.300}'
    },
    extend: {
      sd: {
        vertical: {
          // defines relationship between the tab and the tab panel
          container: {
            display: 'flex',
            flexDirection: 'row'
          },
          tablist: {
            display: 'flex',
            flexDirection: 'column',
            // borderWidth: '0 0 1px 0',
            // borderStyle: 'solid',
            // borderColor: '{sd.divider.darker}'
          },
          tab: {
            fontWeight: '400',
            padding: '14px 12px',
            borderRightRadius: '1px',
            active: {
              fontWeight: '700',
              borderColor: '{primary.500}',
              backgroundColor: '{surface.300}',
              borderWidth: '3px'
            }
          }
        }
      }
    },
    colorScheme: {
      light: {
        active: {
          bar: {
            height: '.125rem',
            background: '{override.secondary.color}'
          }
        }
      }
    }
  };
  