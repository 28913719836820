import { dt } from '@primeng/themes';

export const dataTableTheme = {
  bodyCell: {
    borderColor: '{surface.300}',
    borderWidth: '1px'
  },
  css: ({ dt: any }: any): string => `
  .p-datatable .p-datatable-thead > tr > th:first-of-type {
    border-top-left-radius: ${dt('sd.border.radius.small')};
  }
  .p-datatable .p-datatable-thead > tr > th:last-of-type {
    border-top-right-radius: ${dt('sd.border.radius.small')};
  }
  `,
  headerCell: {
    borderColor: '{surface.300}',
    borderWidth: '1px',
    background: '{surface.200}',
    color: '{surface.900}',
  },
};

