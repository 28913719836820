<div class="batch-upload-container">
  <!-- Batch Creation Section -->
  <div class="create-batch-container">
    <div class="create-batch-header">
      <div class="header-text">
        <h2 class="create-batch-title">{{ 'documentBatching' | translate }}</h2>
        <span class="create-batch-description">{{ 'createBatchDescription' | translate }}</span>
      </div>
      <p-button 
        *ngIf="canPerformAction('CREATE_BATCH') | async" 
        icon="fa-solid fa-rectangle-history-circle-plus"
        label="{{ 'createBatch' | translate }}"
        (click)="startBatchCreation()"
        [attr.aria-label]="'createBatch' | translate"
        size="large"
        severity="secondary">
      </p-button>
    </div>

    <!-- Batch List Component -->
    <div class="batch-list-container">
      <app-batch-list
        [tenantId]="tenantId"
        (openBatchEvent)="openBatch($event)"
      ></app-batch-list>
    </div>
  </div>
</div>
