import { dt } from '@primeng/themes';

export const multiselectTheme = {
  padding: {
    y: '10px',
    x: '10px'
  },
  colorScheme: {
    light: {
      option: {
        focus: {
          background: '{sd.backgrounds.options.hover}'
        },
        group: {
          color: '{sd.text.primary}'
        }
      }
    }
  },

  css: ({ dt: any }: any) => `
    .p-multiselect-header {
      background: ${dt('sd.backgrounds.panel')};
    }
  `
};
