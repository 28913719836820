export const buttonTheme = {

    label: {
        font: {
            weight: '700'
        }
    },
    outlined: {
        primary: {
            border: {
                color: '{sd.primary.normal}'
            }
        }
    },
    colorScheme: {
        light: {
            sd: {
                form: {
                    background: {
                        color: '{sd.form.action.background.color}',
                        hover: '{sd.form.action.background.hover}'
                    }
                }
            },
            text: {
                plain: {
                    color: '{sd.primary.color}',
                    hover: {
                        background: '{sd.backgrounds.hover}',
                        color: '{sd.primary.hover}',
                    }
                },
                primary: {
                    hover: {
                        background: '{sd.backgrounds.hover}'
                    },
                    color: '#06055F'
                },
                secondary: {
                    hover: {
                        background: '{sd.secondary.hovercolor.lighter}'
                    },
                    color: '{sd.secondary.color}'
                },
                // temporary in use for nav header buttons
                contrast: {
                    color: '{sd.text.primary}',
                    hover: {
                        color: '{sd.primary.hover}',
                    },
                },
            },
            outlined: {
                primary: {
                    border: {
                    color: '{sd.primary.normal}'
                    }
                },
                secondary: {
                    border: {
                        color: '{sd.secondary.color}'
                    },
                    color: '{sd.secondary.color}',
                    hover: {
                        background: '{sd.backgrounds.secondary.hover}',
                    },
                }
            },
            primary: {
                background: '{button.idle.background}',
                hover: {
                    background: '{button.hover.background}',
                    border: {
                        color: '{button.hover.background}'
                    }
                },
                active: {
                    background: '{button.hover.background}'
                }
            },
            secondary: {
                background: '{sd.backgrounds.secondary.color}',
                color: '{primary.contrast.color}',
                hover: {
                    background: '{sd.secondary.hovercolor.darker}',
                    color: '{primary.contrast.color}',
                },
                active: {
                    background: '{sd.secondary.hovercolor.darker}'
                }
            },
            
        }
    },
};