<ngx-dropzone
  class="dropzone"
  [ngClass]="{ 'no-files-error': showNoFilesError }"
  (change)="onSelect($event)"
  [aria-label]="'dragAndDrop' | translate"
  [disabled]="shouldDisableDropzone()"
>
<!-- TODO: Add/Update the description text with file types supported PDF JPG TIF PNG BMP HEIF DOCX XLSX PPTX -->
  <ngx-dropzone-label>
    <i class="upload-icon fa-regular fa-cloud-arrow-up"></i>
    <p class="drag-and-drop-text">{{ 'dragAndDrop' | translate }}</p>
    <!-- <p class="description-text">{{ 'dragAndDropDescription' | translate }}</p> -->
  </ngx-dropzone-label>
</ngx-dropzone>
<div class="separator">
  <div class="separator-line"></div>
  <span class="separator-text">{{ 'or' | translate }}</span>
  <div class="separator-line"></div>
</div>
<div class="buttons-container">
  <div class="dropzone-button">
    <ngx-dropzone
      [ngClass]="{ 'no-files-error': showNoFilesError }"
      (change)="onSelect($event)"
      [aria-label]="'selectFiles' | translate"
      [disabled]="shouldDisableDropzone()"
    >
      <ngx-dropzone-label>
        <i class="file-select fa-solid fa-folder"></i>
        <p>{{ 'selectFiles' | translate }}</p>
      </ngx-dropzone-label>
    </ngx-dropzone>
  </div>
</div>
