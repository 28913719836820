import { ChangeDetectorRef, Component, Input, OnInit, Output, EventEmitter, HostListener, ViewChild, ElementRef } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Attachment } from '../../models/attachment';
import { StatusResponse } from '../../models/statusResponse';
import { Router } from '@angular/router';
import { UserPrivilegesService } from '../../services/auth-privileges/auth-privileges.service';
import { MenuItem } from 'primeng/api';
import { BatchResponse } from '../../models/sdk-interfaces';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';

export interface FileSelectorAttachment {
  name: string;
  id: string;
  completed: boolean;
  status: StatusResponse;
  classificationStarted: boolean;
  canceled?: boolean;
  fileContent?: string;
  selected?: boolean;
}

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss']
})
export class FileSelectorComponent implements OnInit  {
  @Input() attachments: FileSelectorAttachment[] = [];
  @Input() currentAttachmentIndex: number = 0;
  @Input() batch: BatchResponse = {} as BatchResponse;
  @Input() isBatchCompleted: boolean = false;
  @Input() enableBatchOptions: boolean = false;
  @Input() showDocumentOptions: boolean = false;
  @Input() isLoadingFiles: boolean = false;
  @Input() batchLoading: boolean = false;
  @Input() isAutoClassificationEnabled: boolean = false;

  @Output() selectAttachmentEvent = new EventEmitter<number>();
  @Output() selectedAttachmentsEvent = new EventEmitter<Attachment[]>();
  @Output() openEditBatchEvent = new EventEmitter();
  @Output() deleteBatchEvent = new EventEmitter();
  @Output() closeBatchEvent = new EventEmitter();
  @Output() openAddDocumentsEvent = new EventEmitter();
  @Output() openMergeDocumentsEvent = new EventEmitter();


  classificationStarted: boolean = false;
  selectedAttachments: Attachment[] = [];
  selectedAll: boolean = false;
  attachmentsTitle = this.isAddIn() || this.isAddOn() ? 'attachments' : 'documents';
  showBatchOptions: boolean = false;
  isBatchOptionsOpening: boolean = false;
  isDocumentOptionsOpening: boolean = false;
  batchOptions: MenuItem[] = [];
  noMergeOption: MenuItem[] = [];
  documentOptions: MenuItem[] = [];
  private subscription = new Subscription();

  constructor(
    private router: Router,
    public userPrivilegesService: UserPrivilegesService,
    private translateService: TranslateService,
    private featureFlagService: FeatureFlagService,
    private cdr: ChangeDetectorRef // Inject ChangeDetectorRef
  ) {}

  @HostListener('document:click')
  public onClick(): void {
    if (!this.isBatchOptionsOpening) {
      this.showBatchOptions = false;
    } else {
      this.isBatchOptionsOpening = false;
    }
  }

  // TODO: Make active when finished for feature flag activation
  /** Release Flag: Merge Functionality */
  mergeDocumentsFlag = toSignal(
    this.featureFlagService.getFlag('capture-apps-merge-documents', false)
  );
  
  // TODO: REMOVE - Temporarily set to true for testing
  //Turn this on and comment out the feature flag logic above for testing purposes
  // mergeDocumentsFlag = () => true;

  @ViewChild('labelRef', { static: false }) labelRef!: ElementRef;

  isTextOverflowing(element: HTMLElement | null): boolean {
    if (!element) {
      return false;
    }
    return element.offsetWidth < element.scrollWidth;
  }


  ngOnInit() {
    this.documentOptions = [
      {
        label: this.translateService.instant('mergeDocuments'),
        icon: 'fa-solid fa-merge',
        visible: true,
        command: () => this.openMergeDocuments()
      },
    ];
    this.batchOptions = [
      {
        label: this.translateService.instant('editBatch'),
        icon: 'fa-solid fa-pen-to-square',
        visible: true,
        command: () => this.openEditBatch()
      },
      {
        label: this.translateService.instant('addDocuments'),
        icon: 'fa-solid fa-plus',
        visible: false,
        command: () => this.openAddDocuments()
      },
      {
        label: this.translateService.instant('mergeDocuments'),
        icon: 'fa-solid fa-merge',
        visible: true,
        command: () => this.openMergeDocuments()
      },
      {
        label: this.translateService.instant('deleteBatch'),
        icon: 'fa-solid fa-trash',
        class: 'delete-icon',
        visible: false,
        command: () => this.deleteBatch()
      },
    ];

    this.noMergeOption = [
      {
        label: this.translateService.instant('editBatch'),
        icon: 'fa-solid fa-pen-to-square',
        visible: true,
        command: () => this.openEditBatch()
      },
      {
        label: this.translateService.instant('addDocuments'),
        icon: 'fa-solid fa-plus',
        visible: false,
        command: () => this.openAddDocuments()
      },
      {
        label: this.translateService.instant('deleteBatch'),
        icon: 'fa-solid fa-trash',
        class: 'delete-icon',
        visible: false,
        command: () => this.deleteBatch()
      },
    ];

    this.canPerformAction('MANAGE_BATCH_DOCUMENTS').subscribe((canManage: boolean) => {
      this.batchOptions[1].visible = !this.isBatchCompleted && canManage;
    });
  
    this.canPerformAction('DELETE_BATCH').subscribe((canDelete: boolean) => {
      this.batchOptions[3].visible = canDelete;
    });

  }

  /**
   * Checks if the batch options should be hidden after a focus event.
   */
  checkBatchOptionsFocus(event: FocusEvent): void {
    if (!(event.relatedTarget as HTMLInputElement)?.classList.contains('batch-option')) {
      this.showBatchOptions = false;
    }
  }

  trackByIndex(index: number): number {
    return index;
  }

  get headerLabel(): string {
    return this.classificationStarted ? 'fileSelectorHeader1' : 'fileSelectorHeader2';
  }

  isAddIn(): boolean {
    return this.router.url.includes('/outlookaddin');
  }

  isAddOn(): boolean {
    return this.router.url.includes('/googleaddon');
  }

  isPrinter(): boolean {
    return this.router.url.includes('/printer');
  }

  isWebCapture(): boolean {
    return this.router.url.includes('/webcapture');
  }

  selectAttachment(index: number): void {
    this.selectAttachmentEvent.emit(index);
  }

  closeDialog(): void {
    // Signal to Outlook to close the dialog window
    Office.context.ui.messageParent(JSON.stringify({ status: 'closeDialog' }));
  }

  openEditBatch(): void {
    this.openEditBatchEvent.emit();
  }

  openAddDocuments(): void {
    this.openAddDocumentsEvent.emit();
  }

  openMergeDocuments(): void {
    this.openMergeDocumentsEvent.emit();
  }

  deleteBatch(): void {
    this.deleteBatchEvent.emit();
  }

  closeBatch(): void {
    this.closeBatchEvent.emit();
  }
  canPerformAction(actionCode: string): Observable<boolean> {
    return this.userPrivilegesService.canPerformAction(actionCode);
  }
}