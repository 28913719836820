import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, debounceTime, switchMap } from 'rxjs';
import { ContentApiService, Area, DocumentType, KeyField } from '../../services/content-api/content-api.service';
import { UserPrivilegesService } from '../../services/auth-privileges/auth-privileges.service';
import { StatusResponse } from '../../models/statusResponse';
import { SelectChangeEvent } from 'primeng/select';
import { KeyFieldsService } from '../../services/key-fields/key-fields.service';
import { TenantApiService } from '../../services/tenant-api/tenant-api.service';

interface LookupResponse {
  name: string;
  lookupTypeCode: string;
  fieldValues: { fieldCode: string; value: string }[];
}

@Component({
  selector: 'app-key-fields',
  templateUrl: './key-fields.component.html',
  styleUrls: ['./key-fields.component.scss'],
})
export class KeyFieldsComponent implements OnInit {
  @Output() changeEvent = new EventEmitter<any>();
  @Output() uploadDocumentEvent = new EventEmitter();
  @Output() cancelFileFilingEvent = new EventEmitter();
  @Output() deleteDocumentEvent = new EventEmitter();
  @Output() cancelFileAutoClassificationEvent = new EventEmitter();

  @Input() docAreas: Area[] = [];
  @Input() filteredDocumentTypes: DocumentType[] = [];
  @Input() currentDocumentType: DocumentType | undefined;
  @Input() docFormGroup!: FormGroup;
  @Input() attachmentStatus: StatusResponse | undefined;
  @Input() showCancelButton: boolean = false;
  @Input() showDeleteDocumentButton: boolean = false;
  @Input() isAutoClassificationEnabled: boolean = false;
  @Input() disableFileByPrivilege: boolean = false;
  @Input() documentId: string | undefined;
  @Input() isDocumentCompleted: boolean = false;
  @Input() ecmDocId: number | undefined;

  isUploading = false;
  multiValueFields: { [key: string]: string[] } = {};
  lookUpchanged: Subject<KeyField> = new Subject<KeyField>();
  lookupUsersResponse: LookupResponse[] = [];
  lookupSuggestions: { keyFieldCode: string; lookupSuggestion: LookupResponse[] }[] = [];
  allDocumentTypes: DocumentType[] = [];
  tenantId: string = '';
  public dynamicUrl: string = '';

  constructor(
    private currencyPipe: CurrencyPipe,
    private spinner: NgxSpinnerService,
    private contentService: ContentApiService,
    private userPrivilegesService: UserPrivilegesService,
    private cdr: ChangeDetectorRef,
    public keyFieldsService: KeyFieldsService,
    private tenantService: TenantApiService,
  ) {
    this.lookUpchanged.pipe(debounceTime(500)).subscribe(keyField => {
      const inputValue = this.docFormGroup.controls[keyField.code].value;
      // Only perform look up search if there are more than 2 characters typed
      if (inputValue.length >= 2) {
        this.keyFieldsService.lookupUsers(
          keyField.code,
          keyField.lookupType?.code ?? '',
          this.docFormGroup.controls[keyField.code].value
        );
      }
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.tenantId = localStorage.getItem('tenantId') || '';
    this.dynamicUrl = localStorage.getItem('contentURL') || '';
    console.log(this.dynamicUrl);
    console.log("this.attachmentStatus");
    console.log(this.attachmentStatus);
    this.docFormGroup = new FormGroup({
      area: new FormControl('', Validators.required),
      documentTypeCode: new FormControl('', Validators.required),
    });
    
    this.tenantService.getTenantUrls(this.tenantId).pipe(
      switchMap(tenantUrls => {
        this.contentService.setBaseUrl(tenantUrls.contentApiUrl);
        console.log("Base URL set in key-fields component");
        return this.contentService.getDocumentTypes();
      })
    ).subscribe(
      (docTypes) => {
        this.allDocumentTypes = docTypes;
        console.log("getting doctypes in keyfields component");
        console.log(docTypes);
        this.filteredDocumentTypes = [...this.allDocumentTypes.sort((a, b) => a.name.localeCompare(b.name))];
      },
      (error) => {
        console.error("Error fetching document types:", error);
      }
    );

    this.keyFieldsService.currentDocumentType$.subscribe(documentType => {
      this.currentDocumentType = documentType;
    });

    this.keyFieldsService.filteredDocumentTypes$.subscribe(documentTypes => {
      this.filteredDocumentTypes = documentTypes;
    });

    this.keyFieldsService.lookupSuggestions$.subscribe(lookupSuggestions => {
      this.lookupSuggestions = lookupSuggestions;
    });

    // Initialize form controls based on currentDocumentType
    this.initializeFormControls();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['docAreas'] && this.docAreas) {
      this.docAreas = [...this.docAreas].sort((a, b) => a.name.localeCompare(b.name));
    }
    console.log("this.attachmentStatus on changes in keyfields component.ts:");
    console.log(this.attachmentStatus);

    if (changes['documentId'] || changes['currentDocumentType']) {
      this.updateMultiValueValidators();
      this.initializeFormControls();
    }
  }

  initializeFormControls(): void {
    if (this.currentDocumentType?.keyfields) {
      this.currentDocumentType.keyfields.forEach(keyField => {
        if (!this.docFormGroup.contains(keyField.code)) {
          const control = new FormControl('');
          if (keyField.required) {
            control.setValidators(Validators.required);
          }
          this.docFormGroup.addControl(keyField.code, control);
        }
      });
    }
  }

  private updateMultiValueValidators(): void {
    if (!this.currentDocumentType?.keyfields) {
      return;
    }
    this.currentDocumentType.keyfields.forEach(keyField => {
      if (keyField.multivalue) {
        const control = this.docFormGroup.controls[keyField.code];
        // Only attach the validator if the field is marked as required.
        if (keyField.required) {
          control.setValidators([this.multiValueRequiredValidator(keyField.code, true)]);
        } else {
          control.clearValidators();
        }
        control.updateValueAndValidity();
      }
    });
  }

  private multiValueRequiredValidator(fieldCode: string, isRequired: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // If the field is not required, always pass.
      if (!isRequired) {
        return null;
      }

      const multiValueFieldKey = `${fieldCode}-${this.currentDocumentType?.code}-${this.documentId}`;

      const entries = this.multiValueFields[multiValueFieldKey] || [];

      if ((!control.value || control.value.trim() === '') && entries.length === 0) {
        return { required: true };
      }

      return null;
    };
  }

  onAreaChange(event: SelectChangeEvent): void {
    const selectedAreaCode = event.value;

    // Remove each multiple value from the current document
    this.removeAllMultipleValues(); 

    this.docFormGroup.get('documentTypeCode')?.reset();
    this.currentDocumentType = undefined;

    this.changeEvent.emit({ area: selectedAreaCode, filteredDocumentTypes: this.filteredDocumentTypes });

    this.cdr.detectChanges();
  }

  onDocumentTypeChange(event: SelectChangeEvent): void {
    const selectedDocTypeCode = event.value; 
    // Remove each multiple value from the current document
    this.removeAllMultipleValues();

    this.currentDocumentType = this.filteredDocumentTypes.find(
      (docType) => docType.code === selectedDocTypeCode
    );

    this.changeEvent.emit({ documentType: this.currentDocumentType });
    this.initializeFormControls();
  }

  uploadDocument() {
    this.isUploading = true;
    this.spinner.show('documentUploadSpinner');
    Object.keys(this.docFormGroup.controls).forEach(control => {
      if (this.currentDocumentType?.keyfields.some(keyField => keyField.code === control)) {
        const keyField = this.currentDocumentType.keyfields.find(
          keyField => keyField.code === control
        );
        if (keyField?.multivalue) {
          if (this.docFormGroup.controls[control].value !== '') {
            this.addMultipleValue(control);
          }
        }
      }
    });
    this.uploadDocumentEvent.emit(event);
  }

  /**
   * Cancels the document filing process
   * Emits the `cancelFileFilingEvent` with the event data
   * @param event The cancel event
   */
  cancelFileFiling(event: any) {
    this.cancelFileFilingEvent.emit(event);
  }

  /**
   * Completes the upload process by hiding the loading spinner and resetting the upload state
   */
  onUploadComplete() {
    this.isUploading = false;
    this.spinner.hide();
  }

  /**
   * Checks if a specific rule exists within a key field's rules
   * @param keyField The key field to check
   * @param ruleCode The code of the rule to check for
   * @returns {boolean} True if the rule exists, false otherwise
   */
  checkIfRuleExists(keyField: KeyField, ruleCode: string): boolean {
    if (!keyField.rules) {
      return false;
    }

    return keyField.rules.some(rule => rule.code === ruleCode);
  }

  /**
   * Adds a value to a multi-value field
   * @param keyFieldCode The code of the key field to which the value should be added
   */
  addMultipleValue(keyFieldCode: string) {
    const control = this.docFormGroup.controls[keyFieldCode];
    const keyFieldValue = control.value;
    const multiValueFieldKey = `${keyFieldCode}-${this.currentDocumentType?.code}-${this.documentId}`;

    if (keyFieldValue !== '') {
      if (!this.multiValueFields[multiValueFieldKey]) {
        this.multiValueFields[multiValueFieldKey] = [keyFieldValue];
      } else {
        this.multiValueFields[multiValueFieldKey].push(keyFieldValue);
      }
    }

    control.setValue('');
    control.updateValueAndValidity();
  }

  /**
   * Removes a value from a multi-value field by its index
   * @param keyFieldCode The code of the key field from which the value should be removed
   * @param index The index of the value to be removed
   */
  removeMultipleValue(keyFieldCode: string, index: number) {
    const control = this.docFormGroup.controls[keyFieldCode];
    const multiValueFieldKey = `${keyFieldCode}-${this.currentDocumentType?.code}-${this.documentId}`;
    this.multiValueFields[multiValueFieldKey].splice(index, 1);
    control.updateValueAndValidity();
  }


    /**
   * Removes a value from a multi-value field by its index
   * @param keyFieldCode The code of the key field from which the value should be removed
   * @param index The index of the value to be removed
   */
    removeAllMultipleValues() {
    // Remove each multiple value from the current document
    if (this.currentDocumentType) {
      this.currentDocumentType.keyfields.forEach(keyField => {
        if (keyField.multivalue) {
          const control = this.docFormGroup.controls[keyField.code];
        const multiValueFieldKey = `${keyField.code}-${this.currentDocumentType?.code}-${this.documentId}`;
        if (!this.multiValueFields[multiValueFieldKey]) {
          return;
        }
        for (let index = this.multiValueFields[multiValueFieldKey].length - 1; index >= 0; index--) {
          this.multiValueFields[multiValueFieldKey].splice(index, 1);
        }

        control.updateValueAndValidity();
        }
      });
    }
    }
  

  /**
   * Checks if a specific key field control has any validation errors
   * @param keyFieldCode The code of the key field to check
   * @returns {boolean} True if there are errors, false otherwise
   */
  hasErrors(keyFieldCode: string) {
    return this.docFormGroup.controls[keyFieldCode]?.errors;
  }

  /**
   * Retrieves the error message text for a specific key field control based on its validation errors
   * @param keyFieldCode The code of the key field to retrieve the error message for
   * @returns {string} The error message text
   */
  errorText(keyFieldCode: string): string {
    if (this.docFormGroup.controls[keyFieldCode]?.errors?.['required']) {
      return 'Required';
    } else if (this.docFormGroup.controls[keyFieldCode]?.errors?.['maxlength']) {
      return 'Maximum length exceeded';
    } else if (this.docFormGroup.controls[keyFieldCode]?.errors?.['pattern']) {
      return 'Invalid format';
    } else if (this.docFormGroup.controls[keyFieldCode]?.errors?.['min']) {
      return 'Minimum value is ' + this.docFormGroup.controls[keyFieldCode]?.errors?.['min'].min;
    }
    return '';
  }

  /**
   * Transforms a value to a currency format using the CurrencyPipe
   * @param element The input element containing the value to be transformed
   */
  transformToCurrency(element: any) {
    element.target.value = this.currencyPipe.transform(element.target.value, '$');
  }

  /**
   * Retrieves lookup suggestions for a specific key field
   * @param keyFieldCode The code of the key field to retrieve lookup suggestions for
   * @returns {LookupResponse[]} An array of lookup suggestions
   */
  getLookupSuggestions(keyFieldCode: string): LookupResponse[] {
    let lookupSuggestion = this.lookupSuggestions.find(
      suggestion => suggestion.keyFieldCode === keyFieldCode
    )?.lookupSuggestion;

    if (!lookupSuggestion) {
      lookupSuggestion = [];
    }

    return lookupSuggestion;
  }

  /**
   * Searches for lookup suggestions based on a query
   * @param event The search event containing the query
   * @param keyField The key field to search for
   */
  searchLookup(event: any, keyField: KeyField) {
    const query = event.query || '';
    if (query.length >= 2) {
      this.keyFieldsService.lookupUsers(keyField.code, keyField.lookupType?.code ?? '', query);
    } else {
      this.keyFieldsService.clearLookupSuggestions();
    }
  }


  /**
   * Handles the date input validation and formatting for Date type key fields
   */
  // TODO: replace deprecated e.keyCode with e.key for better web compatability
  validateDate(event: Event) {
    const e = <KeyboardEvent>event;
    if (
      [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }

    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }

    const input = event.target as HTMLInputElement;

    let trimmed = input.value.replace(/\s+/g, '');

    // Ensure the input is only digits and slashes
    trimmed = trimmed.replace(/[^0-9/]/g, '');

    // Format the input as mm/dd/yyyy
    if (trimmed.length >= 2 && trimmed[2] !== '/') {
      trimmed = `${trimmed.slice(0, 2)}/${trimmed.slice(2)}`;
    }
    if (trimmed.length >= 5 && trimmed[5] !== '/') {
      trimmed = `${trimmed.slice(0, 5)}/${trimmed.slice(5)}`;
    }
    if (trimmed.length > 10) {
      trimmed = trimmed.slice(0, 10);
    }

    const newDate = new Date(trimmed + e.key);

    // Prevent the user from entering invalid dates
    if (trimmed.length === 0 && e.key !== '0' && e.key !== '1') {
      e.preventDefault();
    } else if (trimmed.length === 3 && e.key === '0') {
      input.value = trimmed;
    } else if (newDate.toString() === 'Invalid Date') {
      e.preventDefault();
    } else {
      input.value = trimmed;
    }
  }

  /**
   * Deletes a document
   * Emits the `deleteDocumentEvent` with the event data
   */
  deleteDocument() {
    this.deleteDocumentEvent.emit();
  }

  /**
   * Emits the `cancelFileAutoClassificationEvent`
   */
  cancelFileAutoClassification(): void {
    this.cancelFileAutoClassificationEvent.emit();
  }

  canPerformAction(actionCode: string): Observable<boolean> {
    return this.userPrivilegesService.canPerformAction(actionCode);
  }

  /**
   * Builds a dynamic URL to the document in the content management system
   * @param ecmDocId The ECM document ID (-1 if not available)
   * @returns The constructed URL
   */
  buildDynamicUrl(ecmDocId: number): void {
    const baseContentUrl = localStorage.getItem('contentURL') || '';
    
    if (!baseContentUrl) {
      console.error('Content URL not found in localStorage');
      return;
    }
    
    if (ecmDocId !== -1) {
      // Validate that ecmDocId is actually a number
      if (typeof ecmDocId !== 'number' || isNaN(ecmDocId) || !Number.isInteger(ecmDocId) || ecmDocId < 0) {
        console.error('Invalid ecmDocId provided:', ecmDocId);
        this.dynamicUrl = baseContentUrl;
        return;
      }

      // Convert to string and encode to ensure it's safe for URL inclusion
      const sanitizedEcmDocId = encodeURIComponent(ecmDocId.toString());

      // If we have a valid ECM document ID, append it to the URL
      this.dynamicUrl = `${baseContentUrl}/#advancedSearch=(-6!1!${sanitizedEcmDocId})`;
      console.log(`Built dynamic URL with ecmDocId ${sanitizedEcmDocId}: ${this.dynamicUrl}`);
    } else {
      // If ecmDocId is -1, just use the base content URL
      this.dynamicUrl = baseContentUrl;
      console.log(`Using base content URL: ${this.dynamicUrl}`);
    }
    
  }

}