export const datepickerTheme = {
    css: ({ dt: any }: any) => `
    .form {
      .p-datepicker-dropdown {
        background: var(--p-datepicker-sd-form-button-background);
        color: var(--p-datepicker-sd-form-button-hover-color);
      }
  
      .p-datepicker-dropdown:not(:disabled):hover {
        background: var(--p-datepicker-sd-form-button-hover-background);
        color: var(--p-datepicker-sd-form-button-hover-color);
      }
    }
    .p-datepicker-dropdown:disabled {
      opacity: var(--p-disabled-opacity);
      cursor: default;
    }
    `,
    colorScheme: {
      light: {
        sd: {
          form: {
            button: {
              hover: {
                background: '{sd.form.action.background.hover}',
                color: '{selector.icon}'
              },
              background: '{sd.form.action.background.color}',
              color: '{selector.icon}'
            }
          }
        },
        dropdown: {
          hover: {
            background: '{sd.secondary.hovercolor.darker}',
            color: '{selector.icon}'
          },
          background: '{sd.backgrounds.secondary.color}',
          color: '{selector.icon}'
        }
      }
    }
  };
  