//Darker common/default blue
export const sdPrimaryBlue = {
  50: '#E0E5F6', // Primary Button Hover (light)
  100: '#E7ECFF', // Secondary Hover (light)
  200: '#a7eaff', // Interpolated
  300: '#92BAEE', // Secondary (disabled)
  400: '#8282AF', // Primary(disabled)
  500: '#006CFA', // Interpolated
  600: '#006CFA', // Secondary
  700: '#0061E0', // Secondary Hover (dark)
  800: '#06055F', // Primary Button
  900: '#092673', // Primary
  950: '#001F47' // Darkest (Primary Button Hover)
};

//Bluetiful Action Blue
export const sdSecondaryBlue = {
  50: '#E5F0FF', // Secondary Hover (light)
  100: '#d4f2ff', // Interpolated
  200: '#a7eaff', // Interpolated
  300: '#6be2ff', // Interpolated
  400: '#26ccff', // Interpolated
  500: '#14a8ff', // Interpolated
  600: '#0087ff', // Interpolated
  700: '#006CFA', // Secondary
  800: '#0061E0', // (dark hover)
  900: '#084da0', // Interpolated
  950: '#0a2f61' // Interpolated
};

export const neutral = {
  50: '#FFFFFF',
  100: '#F8F9FA',
  200: '#F0F2F5',//Primary background
  300: '#E5E8EC',
  400: '#CED4DA',
  500: '#9FA7AF',
  600: '#6A6F73',//Border
  700: '#4F4F4F',//Header Text 
  800: '#333333',
  900: '#333333',//Default Text
  950: '#000000'
};

export const customColors = {
  teal: {
    50: '#eefffd', // light in the style guide
    100: '#c5fffc',
    200: '#8bfff9',
    300: '#4afef7',
    400: '#15ecea',
    500: '#00cfd0',
    600: '#00a5a8',
    700: '#008287', // Style Guide
    800: '#01666A', // Style Guide
    900: '#0a5457',
    950: '#003035'
  },
  cerulean: {
    50: '#ECF8FF', // Style guide
    100: '#C3E5F9',
    200: '#8CCDF3',
    300: '#50B3EC',
    400: '#1898E2',
    500: '#1272A9', // Style Guide
    600: '#0F547B', // Style Guide
    700: '#0B4465',
    800: '#072E45',
    900: '#031620',
    950: '#020C12'
  },
  indigo: {
    50: '#F2F2FF', // Style Guide
    100: '#D7D8EF',
    200: '#B3B4E0',
    300: '#8B8CD0',
    400: '#6365BF',
    500: '#4547A9',
    600: '#363885', // Style Guide
    700: '#363885', // Style Guide
    800: '#1C1D45',
    900: '#0D0E21',
    950: '#070812'
  },
  mulberry: {
    50: '#F8E3FF', // Style Guide
    100: '#E6D5EC',
    200: '#CFAEDB',
    300: '#B784C8',
    400: '#9E5AB5',
    500: '#7F4293', // Style Guide
    600: '#5F336E', // Style Guide
    700: '#4C2858',
    800: '#341B3C',
    900: '#180D1C',
    950: '#0C060E'
  },
  magenta: {
    50: '#FFEFF7', // Style Guide
    100: '#F4D7E6',
    200: '#EAB3CF',
    300: '#DF8BB5',
    400: '#D4639C',
    500: '#C93D82', // Style Guide
    600: '#A5326B', // Style Guide
    700: '#7C224F',
    800: '#541736',
    900: '#280B1A',
    950: '#14060D'
  },
  sienna: {
    50: '#FFE7D7', // Style Guide
    100: '#FEDBC3',
    200: '#FCB688',
    300: '#FB924C',
    400: '#F96D10',
    500: '#C65205', // Style Guide
    600: '#B04B08', // Style Guide
    700: '#773203',
    800: '#502102',
    900: '#281101',
    950: '#140800'
  }
};

export const semanticColor = (colorName: string) => ({
  50: `{${colorName}.50}`,
  100: `{${colorName}.100}`,
  200: `{${colorName}.200}`,
  300: `{${colorName}.300}`,
  400: `{${colorName}.400}`,
  500: `{${colorName}.500}`,
  600: `{${colorName}.600}`,
  700: `{${colorName}.700}`,
  800: `{${colorName}.800}`,
  900: `{${colorName}.900}`,
  950: `{${colorName}.950}`
});

export const invertColor = (colorName: string) => ({
  50: `{${colorName}.950}`,
  100: `{${colorName}.900}`,
  200: `{${colorName}.800}`,
  300: `{${colorName}.700}`,
  400: `{${colorName}.600}`,
  500: `{${colorName}.500}`,
  600: `{${colorName}.400}`,
  700: `{${colorName}.300}`,
  800: `{${colorName}.200}`,
  900: `{${colorName}.100}`,
  950: `{${colorName}.50}`
});

export const overrideColor = (colorName: string) => ({
  primary: {
    color: `{${colorName}.900}`
  },
  secondary: {
    color: `{${colorName}.600}`
  }
});

// These are "utility" colors
export const sdGreen = {
  100: '#E4EEE6',
  700: '#2B7A3A',
  800: '#22622E'
};

export const sdRed = {
  100: '#FAE0E1',
  600: '#DD3137',
  700: '#C82C32'
};

export const sdYellow = {
  200: '#FDE68A',
  300: '#F9C642',
  500: '#F7B816'
};

export const accentColor = (color: string, light = '50', medium = '500', dark = '700') => {
  return {
    lighter: `{customColors.${color}.${light}}`,
    medium: `{customColors.${color}.${medium}}`,
    darker: `{customColors.${color}.${dark}}`
  };
};
